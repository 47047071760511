import React, { useEffect, useState } from "react";
import "./UploadDoc.css";
import FirstStep from "../Step1/FirstStep";
import Secondstep from "../Step2/Secondstep";
import Thirdstep from "../Step3/Thirdstep";
import axios from "../../../Api/api";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { UserProvider } from "../../../Context/UserContext";
import Stepper from "../Stepper/Stepper";
import { useUser } from "../../../Context/UserContext";
import UserLisence from "../../UserLisence/UserLisence";
import Loader from "../../Loader/Loader";
import Signatories from "../Signatories/Signatories";

const UploadDoc = () => {
  const { t } = useTranslation();

  const { user, userAccess } = useUser("");

  const [isLoading, setIsLoading] = useState(true);
  const [activeStep, setActiveStep] = useState(1);
  const [completedSteps, setCompletedSteps] = useState([]);
  const navigate = useNavigate();

  const token = sessionStorage.getItem("token");

  useEffect(() => {
    retrieveSavedStep();
  }, [userAccess]);

  async function retrieveSavedStep() {
    const storedRegisterSignID = localStorage.getItem("registerSignID");
    try {
      const response = await axios.get(`/v1.0/api/GetSignWorkFlowActivity?RegisterSignID=${storedRegisterSignID}`, {
        headers: {
          Authorization: "Bearer " + token,
        },
      });

      const currentStep = response.data.response.signWorkFlowActivityRef;

      let newActiveStep;

      switch (currentStep) {
        case "1":
          newActiveStep = 1;
          break;
        case "2":
          newActiveStep = 2;
          break;
        case "3":
          newActiveStep = 3;
          break;
        case "4":
          newActiveStep = 4;
          break;
        default:
          newActiveStep = 1;
          break;
      }
      setActiveStep(newActiveStep);

      if (newActiveStep === 2) {
        setCompletedSteps([1]);
      } else if (newActiveStep === 3) {
        setCompletedSteps([1, 2]);
      }
      else if (newActiveStep === 4) {
        setCompletedSteps([1, 2, 3]);
      }

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      if (error?.response?.data?.statusCode == 2 || error?.response?.data?.statusCode == 4) {
        sessionStorage.removeItem("token");
        localStorage.clear();
        setIsLoading(false);
        navigate("/");
        toast.info(t("uploadDocScreen.sessionExpire"));
      }
    }
  }

  return (
    <UserProvider>
      <div className='uploadDoc-container'>
        {(user.AccountType === "B" && user.RoleID === 3) || userAccess.remainingDays <= 0 ? (
          <>
            <UserLisence />
          </>
        ) : (
          <>
            {isLoading ? (
              <div className='loader-overlay'>
                <Loader />
              </div>
            ) : (
              <div>
                <Stepper activeStep={activeStep} t={t} completedSteps={completedSteps} />

                <div className='formSteps'>
                  {activeStep === 1 && <FirstStep setActiveStep={setActiveStep} setCompletedSteps={setCompletedSteps} />}
                  {activeStep == 2 && <Signatories setActiveStep={setActiveStep} setCompletedSteps={setCompletedSteps} />}
                  {activeStep === 3 && <Secondstep setActiveStep={setActiveStep} setCompletedSteps={setCompletedSteps} />}
                  {activeStep === 4 && <Thirdstep setActiveStep={setActiveStep} setCompletedSteps={setCompletedSteps} />}
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </UserProvider>
  );
};

export default UploadDoc;
