import React, { useState, useEffect } from "react";
import { Nav } from "react-bootstrap";
import "./DashboardCard.css";
import { Link, useLocation } from "react-router-dom";
import axios from "../../Api/api";
import declineIcon from "../../assets/images/decline.png";
import completedIcon from "../../assets/images/completed.png";
import { useTranslation } from "react-i18next";
import inactiveIcon from "../../assets/images/inactive.png";
import Button from "react-bootstrap/Button";
import pendingWithMeIcon from "../../assets/images/More Circle.svg";
import macAihomeicon from "../../assets/images/macAihomeicon.svg";
import BookmarkIcon from "../../assets/images/BookmarkIcon.svg";
import { FiRefreshCw } from "react-icons/fi";
import { useUser } from "../../Context/UserContext";
import Loader from "../Loader/Loader";
import { useTour } from "../../Context/useTour";
import Tour from "reactour";

const DashboardCard = () => {
  const [counts, setCounts] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [activeCard, setActiveCard] = useState(null);
  const { t } = useTranslation();
  const location = useLocation();
  const { user, userAccess } = useUser("");
  const { isTourOpen, tourSteps, closeTour } = useTour();

  const token = sessionStorage.getItem("token");

  useEffect(() => {
    const fetchCounts = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get("/v1.0/api/GetCounts", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.data.successStatus) {
          setCounts(response.data.response);
        } else {
          console.error("API request failed:", response.data.message);
        }
      } catch (error) {
        console.error("Error fetching API:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchCounts();
  }, [token, setCounts, userAccess.remainingDays]);

  useEffect(() => {
    const pathToCardMap = {
      "/PendingWithMe": "PendingWithMe",
      "/PendingWithOthers": "PendingWithOthers",
      "/CompleteStatus": "CompleteStatus",
      "/Drafts": "Drafts",
      "/SharedwithMe": "SharedwithMe",
      "/Sharedwithothers": "Sharedwithothers",
      "/Declined": "Declined",
      "/InActive": "InActive",
      "/RecallDocs": "RecallDocs",
    };

    setActiveCard(pathToCardMap[location.pathname] || null);
  }, [location]);

  const handleCardClick = (cardName) => {
    setActiveCard(cardName === activeCard ? null : cardName);
  };

  return (
    <>
      <div className='my-3'>
        {isLoading && (
          <div className='loader-overlay'>
            <Loader />
          </div>
        )}
        <div className='d-flex gap-3'>
          <Nav className='dashboard-statuses'>
            <>
              <Nav.Link as={Link} to='/PendingWithMe' className={`pending-card card ${activeCard === "PendingWithMe" ? "active" : ""}`} onClick={() => handleCardClick("PendingWithMe")}>
                <div className='cardDiv'>
                  <div className='CardIconsDiv' style={{ background: "#F9BA0533" }}>
                    <img src={pendingWithMeIcon} alt='IconLoading' />
                  </div>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <p className='m-0 fw-bold'>{t("menuButtons.pendingWithMe")}</p>
                    <p className='m-0 conditional' style={{ fontSize: "12px" }}>
                      {t("menuButtons.allDocPwithme")}
                    </p>
                  </div>
                </div>

                <div style={{ display: "flex", alignItems: "center", gap: "1rem", width: "100%" }}>
                  <div style={{ flex: "1", height: "8px", borderRadius: "4px", backgroundColor: "#FFF5CC", overflow: "hidden" }}>
                    {/* Progress Bar */}
                    <div style={{ width: `${counts.pendingWithMeCount}%`, height: "100%", backgroundColor: "#F9BA05", borderRadius: "4px" }}></div>
                  </div>
                  <span className='fw-bold'>{counts.pendingWithMeCount}</span>
                </div>
              </Nav.Link>

              <Nav.Link as={Link} to='/PendingWithOthers' className={`pending-card card ${activeCard === "PendingWithOthers" ? "active" : ""}`} onClick={() => handleCardClick("PendingWithOthers")}>
                <div className='cardDiv'>
                  <div className='CardIconsDiv' style={{ background: "#F9BA0533" }}>
                    <img src={pendingWithMeIcon} alt='IconLoading' />
                  </div>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <p className='m-0 fw-bold'>{t("menuButtons.pendingWithOthers")}</p>
                    <p className='m-0 conditional' style={{ fontSize: "12px" }}>
                      {t("menuButtons.allDocPwithother")}
                    </p>
                  </div>
                </div>

                <div style={{ display: "flex", alignItems: "center", gap: "1rem", width: "100%" }}>
                  <div style={{ flex: "1", height: "8px", borderRadius: "4px", backgroundColor: "#FFF5CC", overflow: "hidden" }}>
                    {/* Progress Bar */}
                    <div style={{ width: `${counts.pendingWithOthersCount}%`, height: "100%", backgroundColor: "#F9BA05", borderRadius: "4px" }}></div>
                  </div>
                  <span className='fw-bold'>{counts.pendingWithOthersCount}</span>
                </div>
              </Nav.Link>

              <Nav.Link as={Link} to='/CompleteStatus' className={`completed-card card ${activeCard === "CompleteStatus" ? "active" : ""}`} onClick={() => handleCardClick("CompleteStatus")}>
                <div className='cardDiv'>
                  <div className='CardIconsDiv' style={{ background: "#ccf5da" }}>
                    <img alt='iconLoading' src={completedIcon} />
                  </div>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <p className='m-0 fw-bold'>{t("menuButtons.completed")}</p>
                    <p className='m-0 conditional' style={{ fontSize: "12px" }}>
                      {t("menuButtons.allDoCompleted")}
                    </p>
                  </div>
                </div>

                <div style={{ display: "flex", alignItems: "center", gap: "1rem", width: "100%" }}>
                  <div style={{ flex: "1", height: "8px", borderRadius: "4px", backgroundColor: "#ccf5da", overflow: "hidden" }}>
                    {/* Progress Bar */}
                    <div style={{ width: `${counts.completedCount}%`, height: "100%", backgroundColor: "#19d157", borderRadius: "4px" }}></div>
                  </div>
                  <span className='fw-bold'>{counts.completedCount}</span>
                </div>
              </Nav.Link>

              <Nav.Link as={Link} to='/Drafts' className={`draft-card card ${activeCard === "Drafts" ? "active" : ""}`} onClick={() => handleCardClick("Drafts")}>
                <div className='cardDiv'>
                  <div className='CardIconsDiv' style={{ background: "#DAD7FE" }}>
                    <img alt='iconLoading' src={BookmarkIcon} />
                  </div>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <p className='m-0 fw-bold'>{t("menuButtons.draft")}</p>
                    <p className='m-0 conditional' style={{ fontSize: "12px" }}>
                      {t("menuButtons.allDocDraft")}
                    </p>
                  </div>
                </div>

                <div style={{ display: "flex", alignItems: "center", gap: "1rem", width: "100%" }}>
                  <div style={{ flex: "1", height: "8px", borderRadius: "4px", backgroundColor: "#DAD7FE", overflow: "hidden" }}>
                    {/* Progress Bar */}
                    <div style={{ width: `${counts.draftCount}%`, height: "100%", backgroundColor: "#4339f2", borderRadius: "4px" }}></div>
                  </div>
                  <span className='fw-bold'>{counts.draftCount}</span>
                </div>
              </Nav.Link>
              {userAccess.recalled === true ? (
                <>
                  <Nav.Link as={Link} to='/RecallDocs' className={`recall-card card ${activeCard === "RecallDocs" ? "active" : ""}`} onClick={() => handleCardClick("RecallDocs")}>
                    <div className='cardDiv'>
                      <div className='CardIconsDiv' style={{ background: "#b3ebf2" }}>
                        <FiRefreshCw size={30} style={{ color: "#47b7c5" }} />
                      </div>
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <p className='m-0 fw-bold'>{t("menuButtons.recall")}</p>
                        <p className='m-0 conditional' style={{ fontSize: "12px" }}>
                          {t("menuButtons.allDocRecall")}
                        </p>
                      </div>
                    </div>

                    <div style={{ display: "flex", alignItems: "center", gap: "1rem", width: "100%" }}>
                      <div style={{ flex: "1", height: "8px", borderRadius: "4px", backgroundColor: "#b3ebf2", overflow: "hidden" }}>
                        {/* Progress Bar */}
                        <div style={{ width: `${counts.recalledCount}%`, height: "100%", backgroundColor: "#47b7c5", borderRadius: "4px" }}></div>
                      </div>
                      <span className='fw-bold'>{counts.recalledCount}</span>
                    </div>
                  </Nav.Link>
                </>
              ) : (
                <>
                  <Nav.Link as={Link} to='/Declined' className={`decline-card card ${activeCard === "Declined" ? "active" : ""}`} onClick={() => handleCardClick("RecallDocs")}>
                    <div className='cardDiv'>
                      <div className='CardIconsDiv' style={{ background: "#fde7e7" }}>
                        <img src={declineIcon} alt='DeclineImage' />
                      </div>
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <p className='m-0 fw-bold'>{t("menuButtons.declined")}</p>
                        <p className='m-0 conditional' style={{ fontSize: "12px" }}>
                          {t("menuButtons.allDocDeclined")}
                        </p>
                      </div>
                    </div>

                    <div style={{ display: "flex", alignItems: "center", gap: "1rem", width: "100%" }}>
                      <div style={{ flex: "1", height: "8px", borderRadius: "4px", backgroundColor: "#f3b3b3", overflow: "hidden" }}>
                        {/* Progress Bar */}
                        <div style={{ width: `${counts.declineCount}%`, height: "100%", backgroundColor: "#e32626", borderRadius: "4px" }}></div>
                      </div>
                      <span className='fw-bold'>{counts.declineCount}</span>
                    </div>
                  </Nav.Link>
                </>
              )}

              <Nav.Link as={Link} to='/InActive' className={`inactive-card card ${activeCard === "InActive" ? "active" : ""}`} onClick={() => handleCardClick("InActive")}>
                <div className='cardDiv'>
                  <div className='CardIconsDiv' style={{ background: "#bec4c8" }}>
                    <img alt='iconLoading' src={inactiveIcon} />
                  </div>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <p className='m-0 fw-bold'>{t("menuButtons.inactive")}</p>
                    <p className='m-0 conditional' style={{ fontSize: "12px" }}>
                      {t("menuButtons.allDocInactive")}
                    </p>
                  </div>
                </div>

                <div style={{ display: "flex", alignItems: "center", gap: "1rem", width: "100%" }}>
                  <div style={{ flex: "1", height: "8px", borderRadius: "4px", backgroundColor: "#d6dee4", overflow: "hidden" }}>
                    {/* Progress Bar */}
                    <div style={{ width: `${counts.inActiveCount}%`, height: "100%", backgroundColor: "#bec4c8", borderRadius: "4px" }}></div>
                  </div>
                  <span className='fw-bold'>{counts.inActiveCount}</span>
                </div>
              </Nav.Link>
            </>
          </Nav>

          <div>
            <div className='macAIHome mac-ai-section'>
              <div className='content'>
                <div className='contentImage'>
                  <img src={macAihomeicon} alt='AIiconLoading' style={{ height: "40px", width: "40px" }} />
                  <p style={{ fontSize: "20px" }}>{t("home.macAi")}</p>
                </div>
                <p>
                  {t("home.needHelp")} <br /> {t("home.yourDocument")}
                </p>
                {(user.AccountType === "B" && user.RoleID === 3) || (user.AccountType === "I" && user.RoleID === 1) || userAccess.remainingDays <= 0 ? null : (
                  <Button as={Link} to='/mac-ai' variant='none' className='MacAiButton'>
                    {t("home.generate")}
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Tour steps={tourSteps} isOpen={isTourOpen} onRequestClose={closeTour} rounded={5} accentColor='#0979a9' />
    </>
  );
};

export default DashboardCard;
