import React, { useEffect, useState } from "react";
import axios from "../../Api/api";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import ReCAPTCHA from "react-google-recaptcha";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import "bootstrap/dist/css/bootstrap.min.css";
import "./style.css";
import { Link, useNavigate } from "react-router-dom";
import { Row, Col, Container, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import imSignerLogo from "../../assets/images/IAMSignerWebLogo.png";
import { Form, FormGroup, Button } from "react-bootstrap";
import { IoArrowBack } from "react-icons/io5";
import SignUpCarousel from "./SignUpCarousel";
import LangSelector from "../../Components/Language/LangSelector";
import successAnimation from "../../assets/images/sucessAnimation.gif";
import Loader from "../../Components/Loader/Loader";

const Signup = () => {
  const { t } = useTranslation();

  const {
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    register,
    setError,
  } = useForm();

  const [showPassword, setShowPassword] = useState(false);
  const [accountType, setAccountType] = useState("I");
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [selectedCountryCode, setSelectedCountryCode] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [verified, setVerified] = useState(false);
  const [countryCodes, setCountryCodes] = useState([]);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const navigate = useNavigate();
  const Site_key_recaptcha = process.env.REACT_APP_SITE_KEY_RECAPTCHA;

  const refreshToken = process.env.REACT_APP_STATIC_TOKEN;

  useEffect(() => {
    const countryCode = async () => {
      try {
        const response = await axios.get("/Account/GetCountryCodes", {
          headers: {
            Authorization: `Bearer ${refreshToken}`,
          },
        });
        if (response.data.statusCode === "1") {
          const countryCodeList = response.data.response;
          setCountryCodes(countryCodeList);
        }
      } catch (error) {
        console.error(error);
      }
    };

    countryCode();
  }, []);

  const togglePasswordVisibility = (setter) => {
    setter((prev) => !prev);
  };

  const onSubmit = async (data) => {
    if (!verified) {
      toast.error(t("logScreens.reCaptcha"));
      return;
    }

    const { Password, ConfirmPassword } = data;

    if (Password !== ConfirmPassword) {
      setError("ConfirmPassword", {
        type: "manual",
        message: t("logScreens.passMatch"),
      });
      return;
    }

    data.accountType = accountType;

    try {
      setIsLoading(true);
      const response = await axios.post("/Account/CreateAccount", data, {
        headers: {
          Authorization: `Bearer ${refreshToken}`,
        },
      });
      if (response.data.statusCode === "1") {
        toast.success(t("logScreens.userRegistered"));
        reset();
        setShowSuccessModal(true);
        setTimeout(() => {
          //handle Navigate
          setShowSuccessModal(false);
          navigate("/");
        }, 3000);
      } else if (response.data.statusCode === "10") {
        toast.error(t("logScreens.userExists"));
      } else {
        toast.error(t("logScreens.registrationFailed"));
      }
      setIsLoading(false);
    } catch (error) {
      if (error.response && error.response.status === 400) {
        const serverErrors = error.response.data.errors;
        Object.keys(serverErrors).forEach((field) => {
          setError(field, { type: "manual", message: serverErrors[field].message });
        });
      } else {
        toast.error(t("logScreens.networkErr"));
        setIsLoading(false);
        console.error(error);
      }
    }
  };

  const onChange = (value) => {
    setVerified(true);
  };

  const handleCloseModal = () => {
    setShowSuccessModal(false);
  };

  useEffect(() => {
    const dir = document.documentElement.dir;
    if (dir === "rtl") {
      // Apply RTL specific styles or classes
      document.querySelector(".input-group").classList.add("rtl-input-group");
    } else {
      // Apply LTR specific styles or classes
      document.querySelector(".input-group").classList.remove("rtl-input-group");
    }
  }, [document.documentElement.dir]);


  return (
    <Container fluid className='login-container'>
      {isLoading && (
        <div className='loader-overlay'>
          <Loader />
        </div>
      )}

      <Row>
        <Col sm={12} md={6} className='login-col d-flex flex-column'>
          <div className='SignUp-form'>
            <div className='headingIMSigner'>
              <div className='d-flex gap-2 align-items-center'>
                <Link to='/'>
                  <IoArrowBack size={22} className={document.documentElement.dir === "rtl" ? "rtl-icon" : ""} style={{ color: "#0F1315", cursor: "pointer" }} />
                </Link>
                <img alt='logo' style={{ height: "45px", width: "140px" }} src={imSignerLogo} />
              </div>
              <LangSelector style={{ padding: "0px" }} />
            </div>

            <h4 className='form-heading fw-bold'>{t("logScreens.accountCreate")}</h4>
            <Form onSubmit={handleSubmit(onSubmit)}>
              {/* Account Type */}

              <div className='accctypeBtn'>
                <Button className={`SignAccountTypeBtn ${accountType === "I" ? "" : "outline"}`} onClick={() => setAccountType("I")}>
                  {t("logScreens.individual")}
                </Button>
                <Button className={`SignAccountTypeBtn ${accountType === "B" ? "" : "outline"}`} onClick={() => setAccountType("B")}>
                  {t("logScreens.company")}
                </Button>
              </div>

              <div style={{ display: "flex", justifyContent: "space-between" }}>
                {accountType === "B" && (
                  <FormGroup className={`mb-2 ${errors.CompanyName ? "has-error" : ""}`} style={{ width: "calc(45%)" }}>
                    <Form.Label className='form-label'>
                      {t("logScreens.companyName")}
                      <span style={{ color: "red" }}>*</span>
                    </Form.Label>
                    <Form.Control
                      type='text'
                      placeholder={t("logScreens.companyName")}
                      {...register("CompanyName", { required: t("logScreens.companyNameRequired") })}
                      className={`form-control ${errors.CompanyName ? "error-border" : ""}`}
                    />
                    {errors.CompanyName && <div className='error'>{errors.CompanyName.message}</div>}
                  </FormGroup>
                )}

                {/* Name */}
                <FormGroup className={`mb-2 ${errors.Name ? "has-error" : ""}`} style={{ width: `${accountType === "I" ? "100%" : "calc(45%)"}` }}>
                  <Form.Label className='form-label'>
                    {t("logScreens.name")}
                    <span style={{ color: "red" }}>*</span>
                  </Form.Label>
                  <Form.Control
                    type='text'
                    placeholder={t("logScreens.name")}
                    {...register("Name", { required: t("logScreens.requiredName") })}
                    className={`form-control ${errors.Name ? "error-border" : ""}`}
                  />
                  {errors.Name && <div className='error'>{errors.Name.message}</div>}
                </FormGroup>
              </div>

              {/* Email */}
              <FormGroup className={`mb-2 ${errors.EmailID ? "has-error" : ""}`}>
                <Form.Label className='form-label'>
                  <span> {t("logScreens.emailAdd")}</span>
                  <span style={{ color: "red" }}>*</span>
                </Form.Label>
                <Form.Control
                  type='text'
                  placeholder={t("logScreens.email")}
                  {...register("EmailID", {
                    required: t("logScreens.emailRequired"),
                    pattern: {
                      value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                      message: t("logScreens.emailAddress"),
                    },
                  })}
                  className={`form-control ${errors.EmailID ? "error-border" : ""}`}
                />
                {errors.EmailID && <div className='error'>{errors.EmailID.message}</div>}
              </FormGroup>

              <div className='mb-2'>
                <Form.Label className='form-label'>
                  {t("logScreens.mobileNumber")}
                  <span style={{ color: "red" }}>*</span>
                </Form.Label>
                <div className='input-group'>
                  <Form.Select
                    as='select'
                    {...register("CountryCode", { required: t("logScreens.countryCodeRequired") })}
                    value={selectedCountryCode}
                    onChange={(e) => setSelectedCountryCode(e.target.value)}
                    className={`form-control ${errors.CountryCode ? "error-border" : ""}`}
                    style={{ width: "30%" }}
                  >
                    <option value='' disabled>
                      {t("logScreens.Codes")}
                    </option>
                    {countryCodes.map((country) => (
                      <option key={country.lookupID} value={country.lookupID}>
                        {country.name}
                      </option>
                    ))}
                  </Form.Select>
                  <Form.Control
                    type='text'
                    placeholder={t("logScreens.mobileNumber")}
                    {...register("MobileNumber", {
                      required: t("logScreens.mobileRequired"),
                      pattern: {
                        value: /^[0-9]+$/,
                        message: t("logScreens.ValidmobileNumber"),
                      },
                    })}
                    className={`form-control ${errors.MobileNumber ? "error-border" : ""}`}
                    style={{ width: "calc(70% - 40px)" }}
                  />
                </div>
                {errors.CountryCode && <div className='error'>{errors.CountryCode.message}</div>}
                {errors.MobileNumber && <div className='error'>{errors.MobileNumber.message}</div>}
              </div>

              <div style={{ display: "flex", justifyContent: "space-between" }}>
                {/*password  */}
                <FormGroup className={`form-group ${errors.Password ? "has-error" : ""}`} style={{ width: "calc(45%)" }}>
                  <Form.Label className='form-label'>
                    {t("logScreens.password")}
                    <span style={{ color: "red" }}>*</span>
                  </Form.Label>
                  <div className='position-relative'>
                    <Form.Control
                      className={`form-control ${showPassword ? "password-show" : ""} ${errors.Password ? "error-border" : ""}`}
                      type={showPassword ? "text" : "password"}
                      placeholder={t("logScreens.password")}
                      {...register("Password", {
                        required: t("logScreens.passwordRequired"),
                        minLength: {
                          value: 8,
                          message: t("logScreens.passLengthRequired"),
                        },
                        pattern: {
                          value: /^(?=.*[A-Z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]+$/,
                          message: t("logScreens.validPassword"),
                        },
                      })}
                    />
                    <button
                      type='button'
                      className='btn btn-outline-secondary custom-border position-absolute end-0 top-50 translate-middle-y'
                      onClick={() => togglePasswordVisibility(setShowPassword)}
                    >
                      {showPassword ? <FaEyeSlash /> : <FaEye />}
                    </button>
                  </div>
                  {errors.Password && <div className='error'>{errors.Password.message}</div>}
                </FormGroup>

                {/* Confirm Password */}
                <FormGroup className={`form-group ${errors.ConfirmPassword ? "has-error" : ""}`} style={{ width: "calc(45%)" }}>
                  <Form.Label className='form-label'>
                    {t("logScreens.confirmPassword")}
                    <span style={{ color: "red" }}>*</span>
                  </Form.Label>
                  <div className='position-relative'>
                    <Form.Control
                      className={`form-control ${showConfirmPassword ? "password-show" : ""} ${errors.ConfirmPassword ? "error-border" : ""}`}
                      type={showConfirmPassword ? "text" : "password"}
                      placeholder={t("logScreens.confirmPassword")}
                      {...register("ConfirmPassword", { required: t("logScreens.confrimPassword") })}
                    />
                    <button
                      type='button'
                      className='btn btn-outline-secondary custom-border position-absolute end-0 top-50 translate-middle-y'
                      onClick={() => togglePasswordVisibility(setShowConfirmPassword)}
                    >
                      {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                    </button>
                  </div>
                  {errors.ConfirmPassword && <div className='error'>{errors.ConfirmPassword.message}</div>}
                </FormGroup>
              </div>

              <ReCAPTCHA sitekey={Site_key_recaptcha} onChange={onChange} />

              {/* Submit Button */}

              <Button className='btn-singUp mt-2' type='submit' disabled={!verified || isLoading} style={{ backgroundColor: isLoading || !verified ? "#889CAA" : "" }}>
                {t("logScreens.signUp")}
              </Button>
            </Form>
          </div>
        </Col>
        <Col md={6} className='slider-col d-none d-md-flex flex-column align-items-center'>
          <div className='slider'>
            <SignUpCarousel />
          </div>
        </Col>
      </Row>

      <Modal show={showSuccessModal} onHide={handleCloseModal} centered dialogClassName='custom-modal-width'>
        <Modal.Body className='p-3'>
          <div className='thankyou-message-modal'>
            <div className='message-box d-flex flex-column justify-content-center align-items-center'>
              <img src={successAnimation} alt='iconLoading' />
              <h4 style={{ marginTop: "1rem", color: "#24B032" }}>Congratulations</h4>
              <p className='m-0'>Your account has created successfully!</p>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </Container>
  );
};

export default Signup;
