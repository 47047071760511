import React, { useEffect, useState } from "react";
import axios from "../../Api/api";
import { useNavigate } from "react-router";
import { Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import "../../Pages/Templates/templates.css";
import noDocImg from "../../assets/images/NoDocuments (1).png";
import { useTranslation } from "react-i18next";
import { Button } from "react-bootstrap";
import { useUser } from "../../Context/UserContext";
import Loader from "../Loader/Loader";

const DashboardTemplate = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { user, userAccess } = useUser("");
  const [searchResults, setSearchResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);

  const token = sessionStorage.getItem("token");

  const fetchTemplatesData = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get("/v1.0/api/GetAllTemplates", {
        headers: {
          Authorization: `Bearer ${token} `,
        },
      });
      if (response.data) {
        setSearchResults(response.data.response || []);

        setIsLoading(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchTemplatesData();
  }, [token]);

  const handleViewAllTemplate = () => {
    localStorage.removeItem("templateID");
    navigate("/templatesPage");
  };

  const handleCellClick = (event) => {
    const cell = event.target;
    cell.classList.toggle("scrollable");
  };

  const columns = [
    { label: t("tablesLabel.name"), key: "templateName" },
    { label: t("tablesLabel.status"), key: "templateName" },
  ];

  return (
    <>
      <Grid>
        <Grid item xs={12}>
          {/* Loader overlay */}
          {isLoading && (
            <div className='loader-overlay'>
              <Loader />
            </div>
          )}

          <div className='py-3 p-3 pendingtable'>
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "10px" }}>
              <label className='fs-16 '>{t("tablesLabel.latestTemplates")}</label>

              <Button onClick={handleViewAllTemplate} variant='none' className='viewAllltemBtn'>
                {t("tablesLabel.View")}
              </Button>
            </div>

            {/* Table */}
            <TableContainer component={Paper} style={{ height: "37vh", width: "240px" }} className='DashboardTemplate'>
              <Table style={{ border: "none" }}>
                {/* Table Headers */}
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell key={column.label} style={{ backgroundColor: "#F0F3F4" }} className='TableHeaderCell'>
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>

                {/* Table Body */}
                <TableBody>
                  {searchResults.length === 0 && (
                    <TableRow>
                      <td colSpan='3' className='p-3 text-center'>
                        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                          <img alt='imageLoading' src={noDocImg} style={{ height: "100px", width: "120px" }} />
                          {t("dashboardCardStatuses.notDocsFound")}
                        </div>
                      </td>
                    </TableRow>
                  )}
                  {searchResults.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
                    <TableRow key={index}>
                      <TableCell className='TableCell truncate-cell-template' onClick={(e) => handleCellClick(e)}>
                        {row.templateName}
                      </TableCell>
                      <TableCell className='TableCell'>
                        <label
                          style={{
                            padding: "2px 12px",
                            borderRadius: "5px",
                            backgroundColor: row.isActive ? "#00cc4533" : "#eeeeee",
                            color: "black",
                          }}
                        >
                          {row.isActive ? "Active" : "Inactive"}
                        </label>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default DashboardTemplate;
