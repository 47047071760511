import React, { useState } from "react";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import Offcanvas from "react-bootstrap/Offcanvas";
import { CiFilter } from "react-icons/ci";
import { useTranslation } from "react-i18next";
import "./TemplateFilter.css";

function TemplatesSearchFilters({ templateName, setTemplateName, handleSearch, clearSearch, startDate, endDate, setStartDate, setEndDate, docStatus, setDocStatus, Scope, setScope }) {
  const { t } = useTranslation();

  const [isDisabled, setIsDisabled] = useState(true);

  const handleInputChange = (e) => {
    const { name, value, type } = e.target;
    if (value.trim() === "") {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }

    if (type === "radio") {
      switch (name) {
        case "Scope":
          setScope(value);
          break;
        case "docStatus":
          setDocStatus(value);
          break;
        default:
          break;
      }
    } else {
      if (value.trim() === "") {
        setIsDisabled(true);
      } else {
        setIsDisabled(false);
      }

      switch (name) {
        case "templateName":
          setTemplateName(value);
          break;
        case "startDate":
          setStartDate(value);
          break;
        case "endDate":
          setEndDate(value);
          break;
        default:
          break;
      }
    }
  };

  return (
    <Navbar expand={false}>
      <Container fluid>
        <Navbar.Toggle className='filterOpenBtn' aria-controls={`offcanvasNavbar`}>
          <CiFilter />
        </Navbar.Toggle>
        <Navbar.Offcanvas id={`offcanvasNavbar-expand-${false}`} aria-labelledby={`offcanvasNavbarLabel-expand-${false}`} placement={document.documentElement.dir === "rtl" ? "start" : "end"}>
          <Offcanvas.Header closeButton>
            <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${false}`} className='fw-bold'>
              {t("templateUploadFlow.Filter")}
            </Offcanvas.Title>
          </Offcanvas.Header>
          <hr style={{ margin: " auto 0", borderColor: "black" }} />
          <Offcanvas.Body>
            <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
              <div>
                <label className='mb-2'> {t("templateUploadFlow.TemplateName")}</label>
                <input
                  name='templateName'
                  type='text'
                  placeholder={t("editTemplateNameModal.enterTemplateName")}
                  className={`input-field ${templateName ? "active" : ""}`}
                  value={templateName}
                  onChange={handleInputChange}
                />
              </div>

              <div>
                <label className='mb-2'> {t("tablesLabel.scope")}</label>
                <div style={{ display: "flex", gap: "1rem" }}>
                  <div className='radio-container'>
                    <input id='individual' name='Scope' type='radio' className='input-radio' checked={Scope === "I"} value='I' onChange={handleInputChange} />
                    <label htmlFor='individual' className='radio-label'>
                      {t("templateUploadFlow.Individual")}
                    </label>
                  </div>
                  <div className='radio-container'>
                    <input id='company' name='Scope' type='radio' className='input-radio' checked={Scope === "B"} value='B' onChange={handleInputChange} />
                    <label htmlFor='company' className='radio-label'>
                      {t("templateUploadFlow.Company")}
                    </label>
                  </div>
                </div>
              </div>

              {/* <div>
                <label className='mb-2'>Status</label>
                <div style={{ display: "flex", gap: "1rem" }}>
                  <div className='radio-container'>
                    <input id='active' name='docStatus' type='radio' className='input-radio' checked={docStatus === "Active"} value='Active' onChange={handleInputChange} />
                    <label htmlFor='active' className='radio-label'>
                      Active
                    </label>
                  </div>
                  <div className='radio-container'>
                    <input id='inactive' name='docStatus' type='radio' className='input-radio' checked={docStatus === "Inactive"} value='Inactive' onChange={handleInputChange} />
                    <label htmlFor='inactive' className='radio-label'>
                      Inactive
                    </label>
                  </div>
                </div>
              </div> */}

              <div>
                <label className='mb-2'> {t("templateUploadFlow.StartDate")}</label>
                <input name='startDate' type='date' className={`input-field ${startDate ? "active" : ""}`} value={startDate} onChange={handleInputChange} />
              </div>

              <div>
                <label className='mb-2'> {t("templateUploadFlow.EndDate")}</label>
                <input name='endDate' type='date' className={`input-field ${endDate ? "active" : ""}`} value={endDate} onChange={handleInputChange} />
              </div>

              <div className='newComment d-flex align-items-center justify-content-between w-100 p-3 py-4'>
                <button className='resetBtn' onClick={clearSearch}>
                  {t("templateUploadFlow.Reset")}
                </button>
                <button className='applyBtn' onClick={handleSearch} disabled={isDisabled}>
                  {t("templateUploadFlow.Apply")}
                </button>
              </div>
            </div>
          </Offcanvas.Body>
        </Navbar.Offcanvas>
      </Container>
    </Navbar>
  );
}

export default TemplatesSearchFilters;
