import React from "react";
import { Modal, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const TemplatesConfirmationModal = ({ show, handleClose, handleConfirm, title, button }) => {
  const { t } = useTranslation();
  return (
    <Modal show={show} backdrop="static" keyboard={false} onHide={handleClose} dialogClassName='custom-modal-width'>
      <Modal.Header >
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body className='px-3 py-2'>{t("templateConfirmationModal.proceed")}</Modal.Body>
      <Modal.Footer>
        <Button variant='none' className='modalCancelButton' onClick={handleClose}>
          {t("templateConfirmationModal.cancel")}
        </Button>
        <Button variant='none' className='applyBtn' onClick={handleConfirm}>
          {t("templateConfirmationModal.yes")}, {button}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default TemplatesConfirmationModal;
