import React, { useEffect, useState } from "react";
import axios from "../../Api/api";
import { FaBars } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import { Nav, Dropdown, Modal } from "react-bootstrap";
import LogoImg from "../../assets/images/imSingerMenu.png";
import declineIcon from "../../assets/images/decline.svg";
import completedIcon from "../../assets/images/completed.svg";
import sidePending from "../../assets/images/sidePending.svg";
import inactiveIcon from "../../assets/images/inactive.svg";
import shareIcon from "../../assets/images/share.svg";
import homeIcon from "../../assets/images/Home.svg";
import templateIcon from "../../assets/images/Document.svg";
import chartIcon from "../../assets/images/Chart.svg";
import documentIcon from "../../assets/images/recentDocs.svg";
import bookmarkIcon from "../../assets/images/Bookmark.svg";
import integrationIcon from "../../assets/images/dataflow-03.svg";
import opennAi from "../../assets/images/MacAI.svg";
import paperPLus from "../../assets/images/PaperPlus2.svg";
import folderIcon from "../../assets/images/SidebarFolder.svg";
import { useUser } from "../../Context/UserContext";
import { FiRefreshCcw } from "react-icons/fi";
import { LuClipboardEdit } from "react-icons/lu";
import { useTranslation } from "react-i18next";
import BulkSend from "../../assets/images/document-copy.svg";
import BulkSendDocs from "../../assets/images/bulkDocs.svg";
import "./sidebarStyle.css";
import ExpireImage from "../../assets/images/ExpireImage.png";
import { useTour } from "../../Context/useTour";
import Tour from "reactour";
import { PiSignatureLight } from "react-icons/pi";

const Sidebar = ({ children }) => {
  const [isOpen, setIsOpen] = useState(window.innerWidth > 576);
  const { accountId, user, userAccess } = useUser();
  const [color, setColor] = useState("");
  const token = sessionStorage.getItem("token");
  const { t } = useTranslation();
  const [viewModal, setViewModal] = useState(false);
  const { isTourOpen, tourSteps, closeTour } = useTour();

  const fetchCoBrandingData = async () => {
    try {
      if (!accountId) {
        return;
      }
      const response = await axios.get(`/v1.0/api/GetCoBranding?AccountID=${accountId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.data.statusCode === "1") {
        setColor(response.data.response.colorTheme || "");
      }
    } catch (error) {
      console.error("Error fetching co-branding data:", error);
    }
  };

  useEffect(() => {
    fetchCoBrandingData();
  }, [token, accountId]);

  const startButton = () => {
    localStorage.clear();
    sessionStorage.removeItem("signatoriesID");
    sessionStorage.removeItem("parsedData");
    sessionStorage.removeItem("fileName");
    sessionStorage.removeItem("base64CSV");
  };

  const viewAccount = () => {
    localStorage.clear();
    setViewModal(true);
  };
  const handleTemplate = () => {
    localStorage.clear();
  };
  const closeViewModal = () => {
    setViewModal(false);
  };

  const toggle = () => {
    setIsOpen(!isOpen);
  };
  const sidebarBackgroundColor = color || "#0979a9";
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 576) {
        setIsOpen(false);
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const direction = document.dir || "ltr"; // Default to 'ltr' if dir is not set

  return (
    <div>
      <div className='Parentcontainer '>
        <div style={{ width: isOpen ? "230px" : "50px", background: sidebarBackgroundColor }} className='sidebar'>
          <div className='top_section'>
            <div style={{ display: isOpen ? "block" : "none" }}>
              <img src={LogoImg} alt='imageLoading' style={{ height: "30px", width: "100px" }} />
            </div>
            <div
              style={{
                marginLeft: direction === "ltr" ? (isOpen ? "50px" : "0px") : "0px",
                marginRight: direction === "rtl" ? (isOpen ? "50px" : "0px") : "0px",
              }}
              className='bars'
            >
              <FaBars onClick={toggle} style={{ cursor: "pointer" }} />
            </div>
          </div>
          {/* Upload Documents */}
          {(user.AccountType === "B" && user.RoleID === 3) || userAccess.remainingDays <= 0 ? (
            <>
              <NavLink className='UploadMenuLink' onClick={viewAccount}>
                <div className='UploadDocMenu'>
                  <div>
                    <img src={paperPLus} alt='imageLoading' />
                  </div>
                  <div className='link_text' style={{ display: isOpen ? "block" : "none", color: "#0979a9" }}>
                    {t("Sidebar.uploadDoc")}
                  </div>
                </div>
              </NavLink>
            </>
          ) : (
            <>
              <NavLink to='/uploadDoc' className='UploadMenuLink' onClick={startButton}>
                <div className='UploadDocMenu '>
                  <div>
                    <img src={paperPLus} alt='imageLoading' />
                  </div>
                  <div className='link_text' style={{ display: isOpen ? "block" : "none", color: "#0979a9" }}>
                    {t("Sidebar.uploadDoc")}
                  </div>
                </div>
              </NavLink>
            </>
          )}

          <NavLink to='/home' className='menuLinks'>
            <div className='Menuicons'>
              <img src={homeIcon} alt='imageLoading' />
            </div>
            <div className='link_text' style={{ display: isOpen ? "block" : "none" }}>
              {t("Sidebar.Dashboard")}
            </div>
          </NavLink>

          {/* Documents */}
          <NavLink to='/recentdocuments' className='menuLinks recentdocuments'>
            <div className='Menuicons'>
              <img src={documentIcon} alt='imageLoading' />
            </div>
            <div className='link_text' style={{ display: isOpen ? "block" : "none", fontSize: "14px" }}>
              {t("Sidebar.Documents")}
            </div>
          </NavLink>

          {/* self sign flow */}

          {(user.AccountType === "B" && user.RoleID === 3) || userAccess.remainingDays <= 0 ? (
            <></>
          ) : (
            <>
              {userAccess.isbulkCSVflow === true ? (
                <NavLink to='/self-sign' className='menuLinks recentdocuments' onClick={startButton}>
                  <div className='Menuicons'>
                    <PiSignatureLight size={27} />
                  </div>
                  <div className='link_text' style={{ display: isOpen ? "block" : "none", fontSize: "14px" }}>
                    {t("Sidebar.SelfSign")}
                  </div>
                </NavLink>
              ) : null}
            </>
          )}

          {/* Bulk */}

          {(user.AccountType === "B" && user.RoleID === 3) || userAccess.remainingDays <= 0 ? (
            <></>
          ) : (
            <>
              {userAccess.isbulkCSVflow === true ? (
                <NavLink to='/upload-bulk' className='menuLinks bulk-section' onClick={startButton}>
                  <div className='Menuicons '>
                    <img src={BulkSend} alt='imageLoading' />
                  </div>
                  <div className='link_text' style={{ display: isOpen ? "block" : "none", fontSize: "14px" }}>
                    {t("Sidebar.BulkSend")}
                  </div>
                </NavLink>
              ) : null}
            </>
          )}

          {(user.AccountType === "B" && user.RoleID === 3) || userAccess.remainingDays <= 0 ? (
            <></>
          ) : (
            <>
              {userAccess.isbulkCSVflow === true ? (
                <NavLink to='/bulk-documents' className='menuLinks bulk-doc-section' onClick={startButton}>
                  <div className='Menuicons'>
                    <img src={BulkSendDocs} alt='imageLoading' />
                  </div>
                  <div className='link_text' style={{ display: isOpen ? "block" : "none", fontSize: "14px" }}>
                    {t("Sidebar.BulkDocuments")}
                  </div>
                </NavLink>
              ) : null}
            </>
          )}

          {/* Templates */}

          {(user.AccountType === "B" && user.RoleID === 3) || userAccess.remainingDays <= 0 ? (
            <></>
          ) : (
            <>
              {userAccess.templates === true ? (
                <>
                  <NavLink to='/templatesPage' onClick={handleTemplate} className='menuLinks template-section'>
                    <div className='Menuicons'>
                      <img src={templateIcon} alt='imageLoading' />
                    </div>
                    <div className='link_text' style={{ display: isOpen ? "block" : "none", fontSize: "14px" }}>
                      {t("Sidebar.Templates")}
                    </div>
                  </NavLink>
                </>
              ) : (
                <></>
              )}

              {userAccess.folders === true ? (
                <>
                  <NavLink to='/all-folders' className='menuLinks folder-section'>
                    <div className='Menuicons'>
                      <img src={folderIcon} alt='imageLoading' />
                    </div>
                    <div className='link_text' style={{ display: isOpen ? "block" : "none", fontSize: "14px" }}>
                      {t("Sidebar.Folders")}
                    </div>
                  </NavLink>
                </>
              ) : (
                <></>
              )}
            </>
          )}

          {user.AccountType === "B" && user.RoleID === 3 ? (
            <>
              {isOpen ? (
                <Dropdown as={Nav.Link}>
                  <Dropdown.Toggle as={Nav.Link} className='menuLinks doc-status-section'>
                    <img src={chartIcon} alt='imageLoading' />
                    <span style={{ display: isOpen ? "block" : "none", fontSize: "14px" }}>{t("Sidebar.DocumentStatus")}</span>
                  </Dropdown.Toggle>

                  <Dropdown.Menu style={{ background: sidebarBackgroundColor }} className='temp1'>
                    <NavLink to='/PendingDocs' className='menuLinks'>
                      <div className='Menuicons'>
                        <img src={sidePending} alt='iconLoading' />
                      </div>
                      <div className='link_text'>{t("Sidebar.Pending")}</div>
                    </NavLink>

                    {/* Completed */}
                    <NavLink to='/CompleteDocs' className='menuLinks'>
                      <div className='Menuicons'>
                        <img src={completedIcon} alt='iconLoading' style={{ color: "white" }} />
                      </div>
                      <div className='link_text'>{t("Sidebar.Completed")}</div>
                    </NavLink>

                    {userAccess.recalled === true ? (
                      <>
                        <NavLink to='/RecallDocuments' className='menuLinks'>
                          <div className='Menuicons'>
                            <FiRefreshCcw size={21} />
                          </div>
                          <div className='link_text'>{t("Sidebar.Recall")}</div>
                        </NavLink>
                      </>
                    ) : (
                      <></>
                    )}

                    {/* Inactive */}
                    <NavLink to='/InactiveDocs' className='menuLinks'>
                      <div className='Menuicons'>
                        <img src={inactiveIcon} alt='imageLoading' />
                      </div>
                      <div className='link_text'>{t("Sidebar.Inactive")}</div>
                    </NavLink>

                    {/* Declined */}
                    <NavLink to='/DeclineDocs' className='DeclinemenuLinks'>
                      <div className='Menuicons'>
                        <img src={declineIcon} alt='imageLoading' />
                      </div>
                      <div className='link_text'>{t("Sidebar.Declined")}</div>
                    </NavLink>
                  </Dropdown.Menu>
                </Dropdown>
              ) : (
                <Dropdown as={Nav.Link}>
                  <Dropdown.Toggle as={Nav.Link} className='menuLinks dropdown-togle doc-status-section'>
                    <img src={chartIcon} alt='imageLoading' />
                    <span style={{ display: isOpen ? "block" : "none", fontSize: "14px" }}>{t("Sidebar.DocumentStatus")}</span>
                  </Dropdown.Toggle>

                  <Dropdown.Menu style={{ backgroundColor: sidebarBackgroundColor }} className='temp1'>
                    {/* Draft */}

                    <NavLink to='/PendingDocs' className='menuLinks'>
                      <div className='Menuicons'>
                        <img src={sidePending} alt='iconLoading' />
                      </div>
                      <div className='link_text'>{t("Sidebar.Pending")}</div>
                    </NavLink>

                    {/* Completed */}
                    <NavLink to='/CompleteDocs' className='menuLinks'>
                      <div className='Menuicons'>
                        <img src={completedIcon} alt='imageLoading' />
                      </div>
                      <div className='link_text'>{t("Sidebar.Completed")}</div>
                    </NavLink>

                    <NavLink to='/RecallDocuments' className='menuLinks'>
                      <div className='Menuicons'>
                        <FiRefreshCcw size={21} />
                      </div>
                      <div className='link_text'>{t("Sidebar.Recall")}</div>
                    </NavLink>

                    {/* Inactive */}
                    <NavLink to='/InactiveDocs' className='menuLinks'>
                      <div className='Menuicons'>
                        <img src={inactiveIcon} alt='imageLoading' />
                      </div>
                      <div className='link_text'>{t("Sidebar.Inactive")}</div>
                    </NavLink>

                    {/* Declined */}
                    <NavLink to='/DeclineDocs' className='DeclinemenuLinks'>
                      <div className='Menuicons'>
                        <img src={declineIcon} alt='imageLoading' />
                      </div>
                      <div className='link_text'>{t("Sidebar.Declined")}</div>
                    </NavLink>
                  </Dropdown.Menu>
                </Dropdown>
              )}
            </>
          ) : (
            <>
              {isOpen ? (
                <Dropdown as={Nav.Link}>
                  <Dropdown.Toggle as={Nav.Link} className='menuLinks doc-status-section'>
                    <img src={chartIcon} alt='imageLoading' />
                    <span style={{ display: isOpen ? "block" : "none", fontSize: "14px" }}>{t("Sidebar.DocumentStatus")}</span>
                  </Dropdown.Toggle>

                  <Dropdown.Menu style={{ background: sidebarBackgroundColor }} className='temp1'>
                    {/* Draft */}
                    <NavLink to='/DraftDocs' className='menuLinks'>
                      <div className='Menuicons'>
                        <img src={bookmarkIcon} alt='imageLoading' />
                      </div>
                      <div className='link_text'>{t("Sidebar.Draft")}</div>
                    </NavLink>

                    <NavLink to='/PendingDocs' className='menuLinks'>
                      <div className='Menuicons'>
                        <img src={sidePending} alt='iconLoading' />
                      </div>
                      <div className='link_text'>{t("Sidebar.Pending")}</div>
                    </NavLink>

                    {/* Completed */}
                    <NavLink to='/CompleteDocs' className='menuLinks'>
                      <div className='Menuicons'>
                        <img src={completedIcon} alt='iconLoading' style={{ color: "white" }} />
                      </div>
                      <div className='link_text'>{t("Sidebar.Completed")}</div>
                    </NavLink>

                    {userAccess.recalled === true ? (
                      <>
                        <NavLink to='/RecallDocuments' className='menuLinks'>
                          <div className='Menuicons'>
                            <FiRefreshCcw size={21} />
                          </div>
                          <div className='link_text'>{t("Sidebar.Recall")}</div>
                        </NavLink>
                      </>
                    ) : (
                      <></>
                    )}

                    {userAccess.docShare === true ? (
                      <>
                        <NavLink to='/ShareDocs' className='menuLinks'>
                          <div className='Menuicons'>
                            <img src={shareIcon} alt='imageLoading' />
                          </div>
                          <div className='link_text'>{t("Sidebar.Share")}</div>
                        </NavLink>
                      </>
                    ) : (
                      <></>
                    )}

                    {/* Inactive */}
                    <NavLink to='/InactiveDocs' className='menuLinks'>
                      <div className='Menuicons'>
                        <img src={inactiveIcon} alt='imageLoading' />
                      </div>
                      <div className='link_text'>{t("Sidebar.Inactive")}</div>
                    </NavLink>

                    {/* Declined */}
                    <NavLink to='/DeclineDocs' className='DeclinemenuLinks'>
                      <div className='Menuicons'>
                        <img src={declineIcon} alt='imageLoading' />
                      </div>
                      <div className='link_text'>{t("Sidebar.Declined")}</div>
                    </NavLink>
                  </Dropdown.Menu>
                </Dropdown>
              ) : (
                <Dropdown as={Nav.Link}>
                  <Dropdown.Toggle as={Nav.Link} className='menuLinks dropdown-togle  '>
                    <img src={chartIcon} alt='imageLoading' />
                    <span style={{ display: isOpen ? "block" : "none", fontSize: "14px" }}>Document Status</span>
                  </Dropdown.Toggle>

                  <Dropdown.Menu style={{ backgroundColor: sidebarBackgroundColor }} className='temp1'>
                    {/* Draft */}
                    <NavLink to='/DraftDocs' className='menuLinks'>
                      <div className='Menuicons'>
                        <img src={bookmarkIcon} alt='imageLoading' />
                      </div>
                      <div className='link_text'>{t("Sidebar.Draft")}</div>
                    </NavLink>

                    <NavLink to='/PendingDocs' className='menuLinks'>
                      <div className='Menuicons'>
                        <img src={sidePending} alt='iconLoading' />
                      </div>
                      <div className='link_text'>{t("Sidebar.Pending")}</div>
                    </NavLink>

                    {/* Completed */}
                    <NavLink to='/CompleteDocs' className='menuLinks'>
                      <div className='Menuicons'>
                        <img src={completedIcon} alt='imageLoading' />
                      </div>
                      <div className='link_text'>{t("Sidebar.Completed")}</div>
                    </NavLink>

                    {userAccess.docShare === true ? (
                      <>
                        <NavLink to='/ShareDocs' className='menuLinks'>
                          <div className='Menuicons'>
                            <img src={shareIcon} alt='imageLoading' />
                          </div>
                          <div className='link_text'>{t("Sidebar.Share")}</div>
                        </NavLink>
                      </>
                    ) : (
                      <></>
                    )}

                    <NavLink to='/RecallDocuments' className='menuLinks'>
                      <div className='Menuicons'>
                        <FiRefreshCcw size={21} />
                      </div>
                      <div className='link_text'>{t("Sidebar.Recall")}</div>
                    </NavLink>

                    {/* Inactive */}
                    <NavLink to='/InactiveDocs' className='menuLinks'>
                      <div className='Menuicons'>
                        <img src={inactiveIcon} alt='imageLoading' />
                      </div>
                      <div className='link_text'>{t("Sidebar.Inactive")}</div>
                    </NavLink>

                    {/* Declined */}
                    <NavLink to='/DeclineDocs' className='DeclinemenuLinks'>
                      <div className='Menuicons'>
                        <img src={declineIcon} alt='imageLoading' />
                      </div>
                      <div className='link_text'>{t("Sidebar.Declined")}</div>
                    </NavLink>
                  </Dropdown.Menu>
                </Dropdown>
              )}
            </>
          )}

          {user.AccountType === "B" && user.RoleID === 1 ? (
            <>
              {userAccess.remainingDays <= 0 ? (
                <></>
              ) : (
                <>
                  <NavLink to='/auth-integration' className='menuLinks auth-section'>
                    <div className='Menuicons'>
                      <img src={integrationIcon} alt='imageLoading' />
                    </div>
                    <div className='link_text' style={{ display: isOpen ? "block" : "none", fontSize: "14px" }}>
                      {t("Sidebar.Integrations")}
                    </div>
                  </NavLink>

                  {userAccess.macAI === true ? (
                    <>
                      <NavLink to='/mac-ai' className='menuLinks MacAI-section'>
                        <div className='Menuicons'>
                          <img src={opennAi} alt='iconLoading' />
                        </div>
                        <div className='link_text' style={{ display: isOpen ? "block" : "none", fontSize: "14px" }}>
                          {t("Sidebar.MacAI")}
                        </div>
                      </NavLink>
                    </>
                  ) : (
                    <></>
                  )}

                  <NavLink to='/manually-generate-docs' className='menuLinks manually-section'>
                    <div className='Menuicons'>
                      <LuClipboardEdit size={22} />
                    </div>

                    <div className='link_text' style={{ display: isOpen ? "block" : "none", fontSize: "14px" }}>
                      {t("Sidebar.CraftDocument")}
                    </div>
                  </NavLink>
                </>
              )}
            </>
          ) : (
            <></>
          )}
        </div>
        <main className='mainbody'>{children}</main>
      </div>
      <Modal show={viewModal} onHide={closeViewModal} centered dialogClassName='custom-modal-width'>
        <Modal.Body style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", padding: "20px", height: "250px" }}>
          <img src={ExpireImage} alt='Expire ' style={{ height: "100px", widows: "100px" }} />
          <p style={{ fontSize: "20px", fontWeight: "bold", marginTop: "1rem" }}>{t("home.Expire")}</p>
          <p>{t("home.OnlyView")}</p>
        </Modal.Body>
      </Modal>
      <Tour steps={tourSteps} isOpen={isTourOpen} onRequestClose={closeTour} rounded={5} accentColor='#0979a9' />
    </div>
  );
};

export default Sidebar;
