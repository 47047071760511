import React, { useState, useEffect, useRef } from "react";
import axios from "../../Api/api";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./style.css";
import Loader from "../../Components/Loader/Loader";

const OtpInput = ({ length = 4, show, handleClose, email, password, onLogin }) => {
  const [otp, setOtp] = useState(new Array(length).fill(""));
  const inputRefs = useRef([]);
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [disableButton, setDisableButton] = useState(true);
  const [timer, setTimer] = useState(120);
  const [showResendButton, setShowResendButton] = useState(false);

  useEffect(() => {
    if (show && inputRefs.current[0]) {
      inputRefs.current[0].focus();
    }
  }, [show]);

  useEffect(() => {
    let interval;

    if (timer > 0 && show) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else if (timer <= 0) {
      clearInterval(interval);
      // Show the "Resend OTP" button when the timer expires
      setShowResendButton(true);
    }

    return () => clearInterval(interval);
  }, [timer, show]);

  // Watch the OTP input and handle only the "Verify" button logic
  useEffect(() => {
    const allFieldsFilled = otp.every((digit) => digit !== "");
    // The "Verify" button should be disabled if any field is empty or if the app is loading
    setDisableButton(!allFieldsFilled || isLoading);
  }, [otp, isLoading]);

  // Reset timer and button state when modal closes
  const handleModalClose = () => {
    resetOtpFields();
    setTimer(120); // Reset the timer when the modal closes
    setShowResendButton(false); // Hide the "Resend OTP" button when closing
    handleClose();
  };

  const handleChange = (index, e) => {
    const value = e.target.value;
    if (isNaN(value)) return;

    const newOtp = [...otp];
    newOtp[index] = value.substring(value.length - 1);
    setOtp(newOtp);

    if (value && index < length - 1 && inputRefs.current[index + 1]) {
      inputRefs.current[index + 1].focus();
    }
  };

  const handleKeyDown = (index, e) => {
    if (e.key === "Backspace" && !otp[index] && index > 0 && inputRefs.current[index - 1]) {
      inputRefs.current[index - 1].focus();
    } else if (e.key === "Enter" && index === length - 1) {
      handleSubmit(e);
    }
  };

  const resetOtpFields = () => {
    setOtp(new Array(length).fill(""));
    if (inputRefs.current[0]) {
      inputRefs.current[0].focus();
    }
  };

  const refreshToken = process.env.REACT_APP_STATIC_TOKEN;
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const enteredOTP = otp.join("");
      const response = await axios.post(
        "/Account/VerifyOTP",
        {
          UserName: email,
          OTPValue: enteredOTP,
        },
        {
          headers: {
            Authorization: `Bearer ${refreshToken}`,
          },
        }
      );

      if (response.data.statusCode === "1") {
        const { token } = response.data.response;
        onLogin(token);

        axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        toast.success(t("logScreens.loginSuccess"));
        resetOtpFields();
        navigate("/home");
      } else if (response.data.statusCode === "10") {
        toast.error(t("logScreens.otpexpires"));
      } else {
        toast.error(t("logScreens.wrongOTP"));
      }
    } catch (error) {
      console.error("==>", error);
      toast.error(t("logScreens.failedverifyOTP"));
    } finally {
      setIsLoading(false);
      setDisableButton(true);
      resetOtpFields();
    }
  };

  const handleResendOTP = async () => {
    resetOtpFields();
    setTimer(120); // Restart the timer
    setDisableButton(true); // Disable the resend button while the timer runs
    setShowResendButton(false); // Hide the "Resend OTP" button while the timer is active
    setIsLoading(true);

    try {
      const response = await axios.post(
        "/Account/Login",
        { Email: email, Password: password },
        {
          headers: {
            Authorization: `Bearer ${refreshToken}`,
          },
        }
      );

      if (response.data.statusCode === "1") {
        toast.success(t("logScreens.otpResent"));
        setTimer(120);
        setDisableButton(true);
      } else {
        toast.error(t("logScreens.failedOTP"));
      }
    } catch (error) {
      toast.error(t("logScreens.failedOTP"));
    } finally {
      setIsLoading(false);
    }
  };

  const handlePaste = (index, e) => {
    e.preventDefault();

    const pasteData = e.clipboardData.getData("text/plain");
    const pasteValues = pasteData.match(/\d/g) || [];
    const newOtp = [...otp];

    const startIndex = Math.max(0, index - pasteValues.length + 1);

    pasteValues.forEach((value, i) => {
      if (startIndex + i < length) {
        newOtp[startIndex + i] = value;
      }
    });

    setOtp(newOtp);

    const nextIndex = Math.min(startIndex + pasteValues.length, length - 1);
    inputRefs.current[nextIndex].focus();
  };

  return (
    <>
      {isLoading && (
        <div className='loader-overlay'>
          <Loader />
        </div>
      )}

      <Modal show={show} onHide={handleModalClose} centered backdrop='static' keyboard={false} dialogClassName='custom-modal-width'>
        <Modal.Header closeButton>
          <Modal.Title>{t("logScreens.otpDesc")}</Modal.Title>
        </Modal.Header>
        <Modal.Body className='px-3'>
          <div className='otpContainer'>
            <label className='heading'>
              {t("logScreens.emailSent")} {email}
              <br />
              {t("logScreens.codeReceived")}
            </label>
            <div className='d-flex justify-content-center'>
              {otp.map((value, index) => {
                return (
                  <input
                    key={index}
                    inputmode='numeric'
                    style={{
                      textAlign: "center",
                      margin: "10px 10px",
                      height: "40px",
                      width: "50px",
                      fontSize: "16px",
                      border: `2px solid ${value ? "#0c9ad7" : "#ccc"}`,
                    }}
                    type='text'
                    ref={(input) => (inputRefs.current[index] = input)}
                    value={value}
                    onChange={(e) => handleChange(index, e)}
                    onKeyDown={(e) => handleKeyDown(index, e)}
                    onPaste={(e) => handlePaste(index, e)}
                  />
                );
              })}
            </div>
          </div>
          <div className='codeNotRecevied'>
            <p>{t("logScreens.didntReceiveCode")}</p>
            {showResendButton ? (
              <button
                type='button'
                onClick={handleResendOTP}
                style={{
                  backgroundColor: "white",
                  border: "none",
                  color: "var(--Blue-700, #0C9AD7)",
                  fontSize: "16px",
                  fontWeight: 700,
                  textDecorationLine: "underline",
                }}
              >
                {t("logScreens.sendOTPAgain")}
              </button>
            ) : (
              <button
                type='button'
                disabled
                style={{
                  backgroundColor: "white",
                  border: "none",
                  color: "var(--Blue-700, #0C9AD7)",
                  fontSize: "16px",
                  fontWeight: 700,
                  textDecorationLine: "underline",
                }}
              >
                {`${t("logScreens.resendOtp")} ${timer} ${t("logScreens.seconds")}`}
              </button>
            )}
          </div>

          <div className='btnDiv'>
            <button type='submit' disabled={disableButton} onClick={handleSubmit} className='btn-otp'>
              {t("logScreens.Verify")}
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default OtpInput;
