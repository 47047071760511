import React from 'react'
import { Button, Modal } from 'react-bootstrap';
import { useTranslation } from "react-i18next";

const ConfirmationModal = ({ show, onHide, onClick }) => {
    const { t } = useTranslation();
    return (
        <Modal
            show={show}
            onHide={onHide}
            dialogClassName="custom-modal-width"
        >
            <Modal.Header closeButton>
                <Modal.Title>{t("signaturePage.confirmCompleted")}</Modal.Title>
            </Modal.Header>
            <Modal.Body className='px-3 py-2'>{t("signaturePage.proceed")}</Modal.Body>
            <Modal.Footer>
                <Button onClick={onHide} variant='none' className=' modalCancelButton'>
                    {t("signaturePage.no")}
                </Button>
                <Button onClick={onClick} variant='none' className='applyBtn'>
                    {t("signaturePage.yes")}
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default ConfirmationModal