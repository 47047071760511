import React, { useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import axios from "../../Api/api";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useUser } from "../../Context/UserContext";

const ShareFolderModal = ({ show, handleClose, folderId, GetFolders, GetShareWithmeFolders }) => {
  const { t } = useTranslation();
  const [emails, setEmails] = useState([]);
  const [isShareDisabled, setIsShareDisabled] = useState(true);
  const [teamEmails, setTeamEmails] = useState([]);
  const { user } = useUser();

  useEffect(() => {
    if (show) fetchTeamMembers();
  }, [show]);

  useEffect(() => {
    setIsShareDisabled(emails.length === 0);
  }, [emails]);

  const fetchTeamMembers = async () => {
    const token = sessionStorage.getItem("token");
    try {
      const response = await axios.get(`/v1.0/api/GetTeam?AccountID=${user.AccountID}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setTeamEmails(response.data.response.map((member) => member.emailID));
    } catch (error) {
      console.log("Error fetching team members", error);
    }
  };

  const handleEmailSelect = (e) => {
    const selectedEmail = e.target.value;
    if (!emails.includes(selectedEmail)) {
      setEmails([...emails, selectedEmail]);
    }
  };

  const handleEmailRemove = (emailToRemove) => {
    setEmails(emails.filter((email) => email !== emailToRemove));
  };

  const handleShareClick = async () => {
    const token = sessionStorage.getItem("token");
    try {
      const response = await axios.post(
        "/v1.0/api/ShareFolder",
        {
          FolderID: folderId,
          Emails: emails,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.data.statusCode === "1") {
        toast.success(t("Folder.shareSuccess"));
        GetFolders();
        GetShareWithmeFolders();
        handleCloseModal();
      } else {
        toast.error(t("Folder.folderErrorShare"));
      }
    } catch (error) {
      console.log(t("Folder.folderErrorShare"));
    }
  };

  const handleCloseModal = () => {
    setEmails([]);
    setIsShareDisabled(true);
    handleClose();
  };

  return (
    <Modal show={show} onHide={handleCloseModal} backdrop='static' keyboard={false} dialogClassName='custom-modal-width'>
      <Modal.Header closeButton>
        <Modal.Title>{t("Folder.shareFolderUsers")}</Modal.Title>
      </Modal.Header>
      <Modal.Body className='p-3'>
        <Form>
          <Form.Group className='mb-3'>
            <Form.Label>{t("Folder.selectEmails")}</Form.Label>
            <Form.Select as='select' onChange={handleEmailSelect} value=''>
              <option value='' disabled>
                {t("Folder.selectEmailPlaceholder")}
              </option>
              {teamEmails.map((email, index) => (
                <option key={index} value={email}>
                  {email}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        </Form>
        <div className='d-flex flex-wrap gap-2 mt-3'>
          {emails.map((email, index) => (
            <div
              key={index}
              style={{
                display: "inline-flex",
                alignItems: "center",
                padding: "5px 10px",
                backgroundColor: "#e0e0e0",
                borderRadius: "16px",
                marginRight: "5px",
              }}
            >
              <span>{email}</span>
              <Button
                variant='link'
                size='sm'
                className='itembutton'
                style={{ margin: "0 8px", color: "gray", textDecoration: "none", border: "1px solid gray"}}
                onClick={() => handleEmailRemove(email)}
              >
                &times;
              </Button>
            </div>
          ))}
        </div>
      </Modal.Body>
      <Modal.Footer className='d-flex justify-content-end gap-5 '>
        <Button variant='none' className='modalCancelButton' onClick={handleCloseModal}>
          {t("Folder.Cancel")}
        </Button>
        <Button className='applyBtn' variant='none' onClick={handleShareClick} disabled={isShareDisabled}>
          {t("Folder.share")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ShareFolderModal;
