import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import  "./AuditTrail.css";

const AuditTrailModal = ({ show, handleClose, auditTrailURL }) => {
  const { t } = useTranslation();

  useEffect(() => {
    //console.log(targetRef);
  });

  return (
    <Modal show={show} backdrop='static' keyboard={false} onHide={handleClose} className='overflow-hidden'>
      <Modal.Header closeButton className='btn'>
        <Modal.Title>{t("auditTrailModal.auditTrail")}</Modal.Title>
      </Modal.Header>
      <Modal.Body className='modal-body-white'>
        <iframe title={t("auditTrailModal.auditTrail")} src={auditTrailURL} width='100%' height='500px' className='bg-white ' />
      </Modal.Body>
    </Modal>
  );
};

export default AuditTrailModal;
