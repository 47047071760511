import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import "./modal.css";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import axios from "../../Api/api";
import Loader from "../Loader/Loader";
import { useUser } from "../../Context/UserContext";

const ShareModal = ({ show, handleClose, masterDocID, registerSignID }) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [teamEmails, setTeamEmails] = useState([]);
  const { user } = useUser();
  const [emailSubject, setEmailSubject] = useState("");
  const [emailBody, setEmailBody] = useState("");
  const [error, setError] = useState();
  const [selectedCheckboxes, setSelectedCheckboxes] = useState({
    Document: false,
    AuditTrail: false,
    SupportingDocs: false,
  });
  const [isDisabled, setIsDisabled] = useState(true);

  const token = sessionStorage.getItem("token");

  useEffect(() => {
    if (email && emailSubject && emailBody) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [email, emailSubject, emailBody]);

  useEffect(() => {
    if (show) {
      fetchTeamMembers();
      resetFields(); 
    }
  }, [show]);

  const fetchTeamMembers = async () => {
    try {
      const response = await axios.get(`/v1.0/api/GetTeam?AccountID=${user.AccountID}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setTeamEmails(response.data.response.map((member) => member.emailID)); 
    } catch (error) {
      console.log("Error fetching team members", error);
    }
  };

  const handleCheckboxChange = (checkbox) => {
    setSelectedCheckboxes((prevSelected) => ({
      ...prevSelected,
      [checkbox]: !prevSelected[checkbox],
    }));
  };

  const handleShare = async () => {
    const requestBody = {
      Email: email,
      Subject: emailSubject,
      Body: emailBody,
      MasterDocID: masterDocID,
      RegisterSignID: registerSignID,
      IsDocShared: selectedCheckboxes.Document,
      IsAuditTrailDocShared: selectedCheckboxes.AuditTrail,
      IsSupportingDocShared: selectedCheckboxes.SupportingDocs,
    };

    setIsLoading(true);
    try {
      const response = await axios.post("/v1.0/api/CreateDocShared", requestBody, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.data.statusCode === "1") {
        toast.success(t("dashboardCardStatuses.shareFileSucessToast"));
        resetFields();
        handleClose();
      }
      setIsLoading(false);
    } catch (error) {
      handleError(error);
      setIsLoading(false);
    }
  };

  const handleSubmit = () => {
    if (!email || !emailSubject || !emailBody) {
      setError(t("shareModal.fieldRequired"));
      return;
    } else if (!selectedCheckboxes.Document && !selectedCheckboxes.AuditTrail && !selectedCheckboxes.SupportingDocs) {
      toast.error("Select at least one option");
    } else {
      handleShare();
    }
  };

  const resetFields = () => {
    setEmail("");
    setEmailSubject("");
    setEmailBody("");
    setSelectedCheckboxes({
      Document: false,
      AuditTrail: false,
      SupportingDocs: false,
    });
    setError(null);
  };

  const handleError = (error) => {
    if (error.response?.statusCode === "4") {
      toast.error(t("dashboardCardStatuses.unathorizedErrSharedFile"));
    } else if (error.response?.data?.statusCode === "3") {
      toast.error(t("shareModal.alreadyShared"));
    } else {
      toast.error(t("shareModal.generalError"));
    }
    resetFields();
  };

  const handleCloseModal = () => {
    resetFields();
    handleClose();
  };

  return (
    <>
      {isLoading && (
        <div className='loader-overlay'>
          <Loader />
        </div>
      )}
      <Modal show={show} backdrop='static' keyboard={false} onHide={handleCloseModal} dialogClassName='custom-modal-width'>
        <Modal.Header>
          <Modal.Title>{t("shareModal.shareDoc")}</Modal.Title>
        </Modal.Header>
        <Modal.Body className='px-3 py-2'>
          <strong>{t("shareModal.chooseDoc")}</strong>

          <div className='form-check mt-2'>
            <input type='checkbox' className='form-check-input' id='shareDocument' checked={selectedCheckboxes.Document} onChange={() => handleCheckboxChange("Document")} />
            <label className='form-check-label' htmlFor='shareDocument'>
              {t("viewModal.orgDoc")}
            </label>
          </div>

          <div className='form-check'>
            <input type='checkbox' className='form-check-input' id='shareAuditTrail' checked={selectedCheckboxes.AuditTrail} onChange={() => handleCheckboxChange("AuditTrail")} />
            <label className='form-check-label' htmlFor='shareAuditTrail'>
              {t("viewModal.auditTrail")}
            </label>
          </div>

          <div className='form-check mb-2'>
            <input type='checkbox' className='form-check-input' id='shareSupportingDocs' checked={selectedCheckboxes.SupportingDocs} onChange={() => handleCheckboxChange("SupportingDocs")} />
            <label className='form-check-label' htmlFor='shareSupportingDocs'>
              {t("viewModal.supportingDoc")}
            </label>
          </div>

          <Form.Group controlId='formBasicEmail'>
            <Form.Label>{t("shareModal.email")}</Form.Label>
            <Form.Select as='select' value={email} onChange={(e) => setEmail(e.target.value)} required>
              <option value='' disabled>
                {t("Folder.selectEmailPlaceholder")}
              </option>
              {teamEmails.map((teamEmail, index) => (
                <option key={index} value={teamEmail}>
                  {teamEmail}
                </option>
              ))}
            </Form.Select>
            {error && <span className='text-danger'>{error}</span>}
          </Form.Group>

          <Form.Group className='mt-2' controlId='formBasicSubject'>
            <Form.Label>{t("shareModal.subject")}</Form.Label>
            <Form.Control type='text' placeholder='e.g. Subject' value={emailSubject} onChange={(e) => setEmailSubject(e.target.value)} required />
            {error && <span className='text-danger'>{t("shareModal.fieldRequired")}</span>}
          </Form.Group>

          <Form.Group className='mt-2' controlId='formBasicBody'>
            <Form.Label>{t("shareModal.body")}</Form.Label>
            <Form.Control as='textarea' placeholder='enter your message here' value={emailBody} onChange={(e) => setEmailBody(e.target.value)} rows={3} required />
            {error && <span className='text-danger'>{t("shareModal.fieldRequired")}</span>}
          </Form.Group>

          <div className='mt-4 d-flex justify-content-between gap-5'>
            <Button variant='none' className='modalCancelButton' onClick={handleCloseModal}>
              {t("shareModal.cancel")}
            </Button>
            <Button className='applyBtn' variant='none' disabled={isDisabled} onClick={handleSubmit}>
              {t("shareModal.shareFile")}
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ShareModal;
