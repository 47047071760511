import React from "react";
import { Outlet } from "react-router-dom";
import { useLocation } from "react-router-dom";
import DashboardCard from "../DashboardStatuses/DashboardCard";
import Sidebar from "../Navbar/Sidebar";
import Header from "../Navbar/Header";

const Layout = () => {
  const location = useLocation();

  const showDashboardCard = ["/PendingWithMe", "/PendingWithOthers", "/SharedwithMe", "/Sharedwithothers", "/Drafts", "/CompleteStatus", "/InActive", "/Declined", "/RecallDocs"].some((path) =>
    location.pathname.includes(path)
  );

  const hideSidebar =
    location.pathname.includes("/uploadDoc") ||
    location.pathname.includes("/createTemplates") ||
    location.pathname.includes("/editTemplates") ||
    location.pathname.includes("/upload-bulk") ||
    location.pathname.includes("/self-sign");

  return (
    <>
      {hideSidebar && !showDashboardCard ? (
        <div className='mainbody' style={{height:"100vh"}}>
          <Header />
          <Outlet />
        </div>
      ) : (
        <Sidebar>
          <Header />
          {showDashboardCard && (
            <div className='container'>
              <DashboardCard />
            </div>
          )}
          <Outlet />
        </Sidebar>
      )}
    </>
  );
};

export default Layout;
