import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import axios from "../Api/api";
import Loader from "./Loader/Loader";
import { useParams } from "react-router-dom";

const DownloadModal = ({ show, handleClose, dataRow }) => {
  const { docName: docNameFromParams } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedCheckboxes, setSelectedCheckboxes] = useState({
    Document: false,
    AuditTrail: false,
    SupportingDocs: false,
  });
  const { t } = useTranslation();
  const token = sessionStorage.getItem("token");

  const handleCheckboxChange = (checkbox) => {
    setSelectedCheckboxes((prevSelected) => ({
      ...prevSelected,
      [checkbox]: !prevSelected[checkbox],
    }));
  };

  useEffect(() => {
    setSelectedCheckboxes({
      Document: false,
      AuditTrail: false,
      SupportingDocs: false,
    });
  }, [show]);

  const isDownloadButtonDisabled = () => {
    return !selectedCheckboxes.Document && !selectedCheckboxes.AuditTrail && !selectedCheckboxes.SupportingDocs;
  };

  const DownloadSupportingDocs = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`/v1.0/api/DownloadSupportingDoc?RegisterSignID=${dataRow.registerSignID}`, {
        headers: {
          Authorization: "Bearer " + token,
        },
        responseType: "arraybuffer",
      });
      if (response.status === 200) {
        const blob = new Blob([response.data], { type: "application/zip" });
        const downloadLink = document.createElement("a");
        downloadLink.href = URL.createObjectURL(blob);
        downloadLink.download = `supportingDocs.zip`;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
        toast.success(t("dashboardCardStatuses.fileDownload"));
        setIsLoading(false);
      }
    } catch (error) {
      if (error && error.response && error.response.status === 404) {
        toast.info(t("dashboardCardStatuses.Nosupporting"));
      }
      setIsLoading(false);
    }
  };

const handleDownload = async () => {
  if (isDownloadButtonDisabled()) {
    toast.error(t("dashboardCardStatuses.selectOne"));
    return;
  }

  const documentName = dataRow.docName || docNameFromParams; // Use docName from dataRow or fallback to docNameFromParams

  if (selectedCheckboxes.Document) {
    setIsLoading(true);
    try {
      const response = await axios.get("/v1.0/api/DownloadDoc", {
        headers: {
          SignAuth: dataRow.signedDocKey,
        },
      });
      const fileUrl = response.data.response.document;
      const downloadedBlob = await downloadURL(fileUrl);
      const downloadLink = document.createElement("a");
      downloadLink.href = URL.createObjectURL(downloadedBlob);
      downloadLink.download = documentName || "document.pdf"; // Use documentName or fallback to a default name
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
      toast.success(t("dashboardCardStatuses.fileDownload"));
    } catch (error) {
      toast.error(t("dashboardCardStatuses.fileNotDownload"));
    } finally {
      setIsLoading(false);
    }
  }

  if (selectedCheckboxes.AuditTrail) {
    setIsLoading(true);
    try {
      const response = await axios.get("/v1.0/api/DownloadDoc", {
        headers: {
          SignAuth: dataRow.signedDocKey,
        },
      });
      const auditTrailUrl = response.data.response.auditTrail;

      // Check if auditTrail is null or undefined
      if (!auditTrailUrl) {
        toast.info(t("dashboardCardStatuses.noAuditTrail")); // Display an info toast
      } else {
        const downloadedBlob = await downloadURL(auditTrailUrl);
        const downloadLink = document.createElement("a");
        downloadLink.href = URL.createObjectURL(downloadedBlob);
        downloadLink.download = `AuditTrail.pdf`;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
        toast.success(t("dashboardCardStatuses.fileDownload"));
      }
    } catch (error) {
      toast.error(t("dashboardCardStatuses.fileNotDownload"));
    } finally {
      setIsLoading(false);
    }
  }

  if (selectedCheckboxes.SupportingDocs) {
    await DownloadSupportingDocs();
  }

  handleClose();
};


  async function downloadURL(url) {
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`${t("dashboardCardStatuses.httpErr")} ${response.status}`);
      }
      const blob = await response.blob();
      return blob;
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <>
      {isLoading && (
        <div className='loader-overlay'>
          <Loader />
        </div>
      )}
      <Modal show={show} onHide={handleClose} backdrop='static' keyboard={false} dialogClassName='custom-modal-width'>
        <Modal.Header>
          <h4>{t("viewModal.downloadDoc")}</h4>
        </Modal.Header>
        <Modal.Body className='p-3'>
          <strong>{t("viewModal.selectDoc")}</strong>
          <div className='form-check mt-3'>
            <input type='checkbox' className='form-check-input' id='downloadDocument' checked={selectedCheckboxes.Document} onChange={() => handleCheckboxChange("Document")} />
            <label className='form-check-label' htmlFor='downloadDocument'>
              {t("viewModal.orgDoc")}
            </label>
          </div>
          <div className='form-check'>
            <input type='checkbox' className='form-check-input' id='downloadAuditTrail' checked={selectedCheckboxes.AuditTrail} onChange={() => handleCheckboxChange("AuditTrail")} />
            <label className='form-check-label' htmlFor='AuditTrail'>
              {t("viewModal.auditTrail")}
            </label>
          </div>
          <div className='form-check'>
            <input type='checkbox' className='form-check-input' id='downloadSupportingDocs' checked={selectedCheckboxes.SupportingDocs} onChange={() => handleCheckboxChange("SupportingDocs")} />
            <label className='form-check-label' htmlFor='downloadSupportingDocs'>
              {t("viewModal.supportingDoc")}
            </label>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className='modalCancelButton ' onClick={handleClose} style={{ height: "40px", width: "45%" }}>
            {t("viewModal.close")}
          </button>
          <button className='applyBtn' onClick={handleDownload} disabled={isDownloadButtonDisabled()}>
            {t("viewModal.download")}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DownloadModal;
