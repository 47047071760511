import React from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const ViewModal = ({ show, handleClose, pdfUrl }) => {
  const { t } = useTranslation();

  return (
    <Modal show={show} keyboard={false} backdrop='static' onHide={handleClose}>
      <Modal.Header closeButton>
        <h4>{t("viewModal.viewDocument")}</h4>
      </Modal.Header>
      <Modal.Body>
        <embed src={pdfUrl} type='application/pdf' width='100%' height='500px' />
      </Modal.Body>
      <Modal.Footer>
        <button className='applyBtn w-25' onClick={handleClose}>
          {t("viewModal.close")}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default ViewModal;
