import React, { useEffect, useState } from "react";
import axios from "../../Api/api";
import Offcanvas from "react-bootstrap/Offcanvas";
import { Button, Form, Dropdown } from "react-bootstrap";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import noDocImg from "../../assets/images/Folder_empty.svg";
import FodlerIcon from "../../assets/images/Folderblack.svg";
import deleteIcon from "../../assets/images/Delete.svg";
import updateIcon from "../../assets/images/Edit.svg";
import documentIcon from "../../assets/images/FolderAdd.svg";
import FolderDocs from "../../assets/images/folder fill.svg";
import CustomToggle from "../CustomDots/CustomToggle";
import ViewFiles from "../../assets/images/Show.svg";
import AddCompletedDocuments from "./AddCompletedDocuments";
import { useUser } from "../../Context/UserContext";
import UserLisence from "../UserLisence/UserLisence";
import { useTranslation } from "react-i18next";
import Loader from "../Loader/Loader";
import { TbFolders } from "react-icons/tb";
import { BsFolderSymlink } from "react-icons/bs";
import { TbShareOff } from "react-icons/tb";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Pagination } from "@mui/material";
import FolderFilter from "./FolderFilter";
import ShareFolderModal from "./ShareFolderModal";
import ShareDetailsModal from "./ShareDetailsModal";
import shareIcon from "../../assets/images/export.png";
import { RxShare1 } from "react-icons/rx";

function Folder() {
  const [showFolder, setShowFolder] = useState(false);
  const { user, userAccess } = useUser("");
  const [showTotalFolder, setTotalShowFolder] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({ FolderName: "", FolderType: "Files", Description: "" });
  const [formDataSubFolder, setFormDataSubFolder] = useState({ FolderID: "", FolderName: "", FolderType: "Files", Description: "" });
  const [showAddDocument, setShowAddDocument] = useState(false);
  const [showUpdateOffcanvas, setShowUpdateOffcanvas] = useState(false);
  const [updateFormData, setUpdateFormData] = useState({ FolderID: "", FolderName: "", FolderType: "", Description: "" });
  const [selectedFolderId, setSelectedFolderId] = useState("");
  const [showDeleteConfirmatrionFolderModal, setShowDeleteConfirmatrionFolderModal] = useState(false);
  const [showDeleteConfirmatrionFolderID, setShowDeleteConfirmatrionFolderID] = useState(false);
  const [showFolderCanvas, setShowFolderCanvas] = useState(false);
  const [showFolderId, setshowFolderId] = useState("");
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [docName, setDocName] = useState("");
  const [folderName, setFolderName] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [showShareModal, setShowShareModal] = useState(false);
  const [showShareDetailsModal, setShowShareDetailsModal] = useState(false);
  const [shareWithData, setShareWithData] = useState([]);
  const [showUnshareModal, setShowUnshareModal] = useState(false);
  const [unshareEmails, setUnshareEmails] = useState([]);
  const [selectedEmail, setSelectedEmail] = useState("");
  const [shareFolderData, setShareFolderData] = useState([]);
  const [activeTab, setActiveTab] = useState("allFolders");

  const handleAllfoler = () => {
    setActiveTab("allFolders");
  };

  const handleShareFolders = () => {
    setActiveTab("shareFolders");
  };

  const handleShareDetailsClick = (folderId) => {
    const folderData = searchResults.find((folder) => folder.folderID === folderId);
    setShareWithData(folderData?.sharedWith || []);
    setShowShareDetailsModal(true);
  };

  const handleShareClick = (folderId) => {
    setSelectedFolderId(folderId);
    setShowShareModal(true);
  };

  const handleCloseShareModal = () => {
    setShowShareModal(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const token = sessionStorage.getItem("token");

  const GetFoldersandDocs = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get("/v1.0/api/GetFolder", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.data.statusCode === "1") {
        const GetFoldersData = response.data.response;
        setTotalShowFolder(GetFoldersData);
        setSearchResults(GetFoldersData);
      }
    } catch (error) {
      console.log("Error", error);
    } finally {
      setIsLoading(false);
    }
  };
  const GetShareWithmeFolders = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get("/v1.0/api/GetFoldersSharedWithMe", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.data.statusCode === "1") {
        const GetFoldersData = response.data.response;
        setShareFolderData(GetFoldersData);
      }
    } catch (error) {
      console.log("Error", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    GetFoldersandDocs();
    GetShareWithmeFolders();
  }, []);

  const navigateToViewFiles = (folderId, folderName) => {
    const folderPath = `/folders/${folderName}/${folderId}`;
    navigate(folderPath);
  };

  const handleViewDocuments = (folderId, folderName) => {
    setSelectedFolderId(folderId);
    navigate(`/folders/${folderName}/${folderId}`);
  };

  const handleAddDocumentClick = (folderID) => {
    setSelectedFolderId(folderID);
    setShowAddDocument(true);
  };

  const handleAddDocumentClose = () => {
    GetFoldersandDocs();
    GetShareWithmeFolders();
    setShowAddDocument(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      const response = await axios.post("/v1.0/api/CreateFolder", formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.data.statusCode === "1") {
        toast.success(t("Folder.createFolderSuccess"));
        setFormData({ FolderName: "", Description: "" });
        setShowFolder(false);
        GetFoldersandDocs();
      }
    } catch (error) {
      console.error("Error creating folder:", error);
      toast.error(t("Folder.errCreateFolder"));
    } finally {
      setIsLoading(false);
    }
  };

  const handleCloseOffcanvas = () => {
    setShowFolder(false);
  };

  const handleCancel = () => {
    setShowFolder(false);
  };

  const handleUpdateFormSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      const response = await axios.put("/v1.0/api/UpdateFolder", updateFormData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.data.statusCode === "1") {
        toast.success(t("Folder.folderUpdateSuccess"));
        GetFoldersandDocs();
        GetShareWithmeFolders();
        setShowUpdateOffcanvas(false);
      }
    } catch (error) {
      console.error("Error updating folder:", error);
      toast.error(t("Folder.errUpdateFolder"));
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdateInputChange = (e) => {
    const { name, value } = e.target;
    setUpdateFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleCloseUpdate = () => {
    setShowUpdateOffcanvas(false);
  };

  const handleUpdateClick = (row) => {
    const { folderID, folderName, description, folderType } = row;
    setUpdateFormData({ FolderID: folderID, FolderName: folderName, Description: description, FolderType: folderType });
    setShowUpdateOffcanvas(true);
  };

  const HandleDeleteFolderOnly = async () => {
    const data = {
      FolderID: showDeleteConfirmatrionFolderID,
    };
    try {
      setIsLoading(true);
      const response = await axios.delete("/v1.0/api/DeleteFolder", {
        data,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.data.statusCode === "1") {
        toast.success(t("Folder.deleteSuccess"));
        setShowDeleteConfirmatrionFolderModal(false);
        GetFoldersandDocs();
        GetShareWithmeFolders();
      }
    } catch (error) {
      console.error("Error deleting folder:", error);
      toast.error(t("Folder.errDeleteFolder"));
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteFolderModal = (folderID) => {
    setShowDeleteConfirmatrionFolderID(folderID);
    setShowDeleteConfirmatrionFolderModal(true);
  };

  const handleSubFolderCanvas = (folderID) => {
    setshowFolderId(folderID);
    setShowFolderCanvas(true);
  };

  const handleClosesSubFolder = () => {
    setShowFolderCanvas(false);
  };

  const handleCreateSubFolder = async (e) => {
    e.preventDefault();
    try {
      const SubFolderData = {
        ...formDataSubFolder,
        FolderID: showFolderId,
      };
      setIsLoading(true);
      const response = await axios.post("/v1.0/api/CreateSubFolder", SubFolderData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.data.statusCode === "1") {
        toast.success(t("Folder.createFolderSuccess"));
        setFormDataSubFolder({ FolderName: "", Description: "" });
        setShowFolderCanvas(false);
        GetFoldersandDocs();
      }
    } catch (error) {
      console.error("Error creating folder:", error);
      toast.error(t("Folder.errCreateFolder"));
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubFolderInputChange = (e) => {
    const { name, value } = e.target;
    setFormDataSubFolder((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const columns = [
    { label: t("home.Folder"), key: "folderName" },
    { label: t("tablesLabel.date"), key: "createdOn" },
    { label: t("coBranding.description"), key: "description" },
    { label: t("tablesLabel.ShareWith"), key: "ShareWith" },
    { label: t("tablesLabel.createdby"), key: "createdByName" },
    { label: "", key: "actions" },
  ];
  const SharedColumns = [
    { label: t("home.Folder"), key: "folderName" },
    { label: t("tablesLabel.date"), key: "createdOn" },
    { label: t("coBranding.description"), key: "description" },
    { label: t("tablesLabel.sharedBy"), key: "sharedBy" },
    { label: "", key: "actions" },
  ];

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    let hours = date.getHours();
    const minutes = date.getMinutes();

    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12;

    const formattedMinutes = (minutes < 10 ? "0" : "") + minutes;

    return `${day}/${month}/${year} - ${hours}:${formattedMinutes} ${ampm}`;
  };

  const handleSearch = async () => {
    setIsLoading(true);
    try {
      let response;
      if (fromDate || toDate || folderName || docName) {
        response = await axios.get("/v1.0/api/GetFolder", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            FolderName: folderName,
            ToDate: toDate,
            FromDate: fromDate,
            DocName: docName,
          },
        });
      }
      setSearchResults(response.data.response || []);
      setIsLoading(false);
    } catch (error) {
      console.error(t("dashboardCardStatuses.errorfetchingUserData"));
      setIsLoading(false);
    }
  };

  const clearSearch = () => {
    setFromDate("");
    setToDate("");
    setDocName("");
    setFolderName("");
    handleSearch();
    GetFoldersandDocs();
  };

  // Function to open the Unshare modal and set emails
  const handleUnshareClick = (row) => {
    const emails = row.sharedWith.split(",").map((email) => email.trim());
    setUnshareEmails(emails);
    setSelectedEmail("");
    setSelectedFolderId(row.folderID);
    setShowUnshareModal(true);
  };

  // Function to handle checkbox change
  const handleEmailSelect = (email) => {
    setSelectedEmail(email);
  };

  const handleUnshareConfirm = async () => {
    try {
      const token = sessionStorage.getItem("token");
      const response = await axios.delete("/v1.0/api/UnshareFolder", {
        headers: { Authorization: `Bearer ${token}` },
        data: { FolderID: selectedFolderId, SharedWith: selectedEmail },
      });

      if (response.data.statusCode === "1") {
        toast.success(t("Folder.folderUnshared"));
        setShowUnshareModal(false);
        GetFoldersandDocs();
        GetShareWithmeFolders();
      } else {
        toast.error(t("Folder.folderErrorShare"));
      }
    } catch (error) {
      console.error("Error unsharing folder:", error);
    }
  };

  const handleCellClick = (event) => {
    const cell = event.target;
    cell.classList.toggle("scrollable");
  };

  return (
    <>
      {(user.AccountType === "B" && user.RoleID === 3) || userAccess.remainingDays <= 0 ? (
        <>
          <UserLisence />
        </>
      ) : (
        <>
          {userAccess.folders === true ? (
            <>
              <div className='container mt-3'>
                {isLoading && (
                  <div className='loader-overlay'>
                    <Loader />
                  </div>
                )}

                <button className='templateButton' onClick={() => setShowFolder(true)}>
                  {t("Folder.createFolder")}
                </button>

                <div className='userProfile-form container mt-3'>
                  <div className='d-flex align-items-center justify-content-between'>
                    <div className='d-flex gap-3'>
                      {searchResults.length === 0 ? (
                        <>
                          <img src={FodlerIcon} alt='folderIcon' style={{ height: "30px", width: "30px" }} />
                        </>
                      ) : (
                        <>
                          <img src={FolderDocs} alt='folderIcon' style={{ height: "30px", width: "30px" }} />
                        </>
                      )}

                      <label style={{ fontSize: "20px", fontWeight: "600" }}>{t("Folder.Allfolders")}</label>
                    </div>

                    <div className='search-filters-container'>
                      <FolderFilter
                        fromDate={fromDate}
                        toDate={toDate}
                        folderName={folderName}
                        docName={docName}
                        setFromDate={setFromDate}
                        setToDate={setToDate}
                        setFolderName={setFolderName}
                        setDocName={setDocName}
                        handleSearch={handleSearch}
                        clearSearch={clearSearch}
                      />
                    </div>
                  </div>

                  <div className='d-flex gap-3 '>
                    <Button className={`SignButton ${activeTab === "allFolders" ? "active" : ""}`} onClick={handleAllfoler}>
                      {t("Folder.Allfolders")}
                    </Button>
                    <Button className={`SignButton ${activeTab === "shareFolders" ? "active" : ""}`} onClick={handleShareFolders}>
                      {t("dashboardCardStatuses.sharedWithMe")}
                    </Button>
                  </div>

                  <hr />

                  {activeTab === "allFolders" && (
                    <>
                      <TableContainer component={Paper} style={{ height: "50vh" }}>
                        <Table stickyHeader style={{ border: "none" }}>
                          <TableHead className='customTableHead'>
                            <TableRow>
                              {columns.map((column) => (
                                <TableCell className='TableHeaderCell' style={{ backgroundColor: "#e7ebee" }} key={column.key}>
                                  {column.label}
                                </TableCell>
                              ))}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {searchResults.length === 0 && (
                              <TableRow>
                                <td colSpan='6' className='p-5 text-center'>
                                  <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                    <img alt='imageLoading' src={noDocImg} style={{ height: "120px", width: "180px" }} />
                                    {t("dashboardCardStatuses.notFolerFound")}
                                  </div>
                                </td>
                              </TableRow>
                            )}

                            {searchResults.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
                              <TableRow key={index}>
                                <TableCell style={{ cursor: "pointer" }} className='TableCell' onClick={() => navigateToViewFiles(row.folderID, row.folderName)}>
                                  <div className='d-flex align-items-center gap-2'>
                                    {row.numberOfSubFolders > 0 || row.numberOfFiles > 0 ? (
                                      <>
                                        <img src={FolderDocs} alt='folderIcon' style={{ height: "25px", width: "25px" }} />
                                      </>
                                    ) : (
                                      <>
                                        <img src={FodlerIcon} alt='folderIcon' style={{ height: "25px", width: "25px" }} />
                                      </>
                                    )}
                                    {row.folderName}
                                  </div>
                                </TableCell>
                                <TableCell className='TableCell'>{row.createdOn ? formatDate(row.createdOn) : ""}</TableCell>
                                <TableCell className='TableCell truncate-cell' onClick={(e) => handleCellClick(e)}>
                                  {row.description}
                                </TableCell>
                                <TableCell className='TableCell runcate-cell' onClick={(e) => handleCellClick(e)}>
                                  {row.sharedWith ? (
                                    row.sharedWith.split(",").length === 1 ? (
                                      <label>{row.sharedWith}</label>
                                    ) : (
                                      <label style={{ cursor: "pointer", padding: "5px", borderRadius: "8px", backgroundColor: "#eeeeee" }} onClick={() => handleShareDetailsClick(row.folderID)}>
                                        <RxShare1 size={22} />
                                      </label>
                                    )
                                  ) : (
                                    <></>
                                  )}
                                </TableCell>

                                <TableCell className='TableCell truncate-cell' onClick={(e) => handleCellClick(e)}>
                                  {row.createdByName}
                                </TableCell>

                                <TableCell className='py-0'>
                                  <Dropdown>
                                    <Dropdown.Toggle as={CustomToggle} />
                                    <Dropdown.Menu>
                                      <Dropdown.Item className='d-flex gap-2 align-items-center' onClick={() => handleUpdateClick(row)}>
                                        <img src={updateIcon} alt='editImage' />
                                        {t("Folder.update")}
                                      </Dropdown.Item>
                                      <Dropdown.Item className='d-flex gap-2 align-items-center' onClick={() => handleShareClick(row.folderID)}>
                                        <img src={shareIcon} alt='folderIcon' />
                                        {t("Folder.Sharefolder")}
                                      </Dropdown.Item>
                                      {row.isShared ? (
                                        <>
                                          <Dropdown.Item className='d-flex gap-2 align-items-center' onClick={() => handleUnshareClick(row)}>
                                            <TbShareOff size={21} color='#2d3132' />
                                            {t("Folder.UnShareFolder")}
                                          </Dropdown.Item>
                                        </>
                                      ) : null}

                                      <Dropdown.Item className='d-flex gap-2 align-items-center' onClick={() => handleViewDocuments(row.folderID, row.folderName)}>
                                        <img src={ViewFiles} alt='documentImage' />
                                        {t("Folder.view")}
                                      </Dropdown.Item>
                                      <Dropdown.Item className='d-flex gap-2 align-items-center' onClick={() => handleAddDocumentClick(row.folderID)}>
                                        <img src={documentIcon} alt='documentImage' />
                                        {t("Folder.add")}
                                      </Dropdown.Item>
                                      <Dropdown.Item className='d-flex gap-2 align-items-center' onClick={() => handleDeleteFolderModal(row.folderID)}>
                                        <img src={deleteIcon} alt='deleteImage' />
                                        {t("Folder.delete")}
                                      </Dropdown.Item>
                                      <Dropdown.Item className='d-flex gap-2 align-items-center' onClick={() => handleSubFolderCanvas(row.folderID)}>
                                        <TbFolders size={22} />
                                        {t("Folder.CreateFolder")}
                                      </Dropdown.Item>
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>

                      <div style={{ display: "flex", justifyContent: "space-between", marginTop: "1rem" }}>
                        <div className='showHideNoDocs'>
                          {page * rowsPerPage + 1} - {Math.min((page + 1) * rowsPerPage, showTotalFolder.length)} {t("Folder.FolderOf")} {showTotalFolder.length} {t("home.Folder")}
                        </div>
                        <Pagination
                          count={Math.ceil(showTotalFolder.length / rowsPerPage)}
                          variant='outlined'
                          shape='rounded'
                          page={page + 1}
                          onChange={(event, newPage) => handleChangePage(event, newPage - 1)}
                          sx={{ "& .Mui-selected": { background: "#0C9AD7 !important", color: "white" } }}
                        />
                      </div>
                    </>
                  )}

                  {activeTab === "shareFolders" && (
                    <>
                      <TableContainer component={Paper} style={{ height: "50vh" }}>
                        <Table stickyHeader style={{ border: "none" }}>
                          <TableHead className='customTableHead'>
                            <TableRow>
                              {SharedColumns.map((column) => (
                                <TableCell className='TableHeaderCell' style={{ backgroundColor: "#e7ebee" }} key={column.key}>
                                  {column.label}
                                </TableCell>
                              ))}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {shareFolderData.length === 0 && (
                              <TableRow>
                                <td colSpan='6' className='p-5 text-center'>
                                  <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                    <img alt='imageLoading' src={noDocImg} style={{ height: "120px", width: "180px" }} />
                                    {t("dashboardCardStatuses.notFolerFound")}
                                  </div>
                                </td>
                              </TableRow>
                            )}

                            {shareFolderData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
                              <TableRow key={index}>
                                <TableCell style={{ cursor: "pointer" }} className='TableCell' onClick={() => navigateToViewFiles(row.folderID, row.folderName)}>
                                  <div className='d-flex align-items-center gap-2'>
                                    {row.numberOfSubFolders > 0 || row.numberOfFiles > 0 ? (
                                      <>
                                        <img src={FolderDocs} alt='folderIcon' style={{ height: "25px", width: "25px" }} />
                                      </>
                                    ) : (
                                      <>
                                        <img src={FodlerIcon} alt='folderIcon' style={{ height: "25px", width: "25px" }} />
                                      </>
                                    )}
                                    {row.folderName}
                                  </div>
                                </TableCell>
                                <TableCell className='TableCell'>{row.sharedOn ? formatDate(row.sharedOn) : ""}</TableCell>
                                <TableCell className='TableCell truncate-cell' onClick={(e) => handleCellClick(e)}>
                                  {row.description}
                                </TableCell>
                                <TableCell className='TableCell runcate-cell' onClick={(e) => handleCellClick(e)}>
                                  {row.sharedBy ? (
                                    row.sharedBy.split(",").length === 1 ? (
                                      <label>{row.sharedBy}</label>
                                    ) : (
                                      <label style={{ cursor: "pointer", padding: "5px", borderRadius: "8px", backgroundColor: "#eeeeee" }} onClick={() => handleShareDetailsClick(row.folderID)}>
                                        <RxShare1 size={22} />
                                      </label>
                                    )
                                  ) : (
                                    <></>
                                  )}
                                </TableCell>

                                <TableCell className='py-0'>
                                  <Dropdown>
                                    <Dropdown.Toggle as={CustomToggle} />
                                    <Dropdown.Menu>
                                      <Dropdown.Item className='d-flex gap-2 align-items-center' onClick={() => handleUpdateClick(row)}>
                                        <img src={updateIcon} alt='editImage' />
                                        {t("Folder.update")}
                                      </Dropdown.Item>
                                      {/* <Dropdown.Item className='d-flex gap-2 align-items-center' onClick={() => handleShareClick(row.folderID)}>
                                        <img src={shareIcon} alt='folderIcon' />
                                        {t("Folder.Sharefolder")}
                                      </Dropdown.Item> */}

                                      <Dropdown.Item className='d-flex gap-2 align-items-center' onClick={() => handleViewDocuments(row.folderID, row.folderName)}>
                                        <img src={ViewFiles} alt='documentImage' />
                                        {t("Folder.view")}
                                      </Dropdown.Item>
                                      <Dropdown.Item className='d-flex gap-2 align-items-center' onClick={() => handleAddDocumentClick(row.folderID)}>
                                        <img src={documentIcon} alt='documentImage' />
                                        {t("Folder.add")}
                                      </Dropdown.Item>
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>

                      <div style={{ display: "flex", justifyContent: "space-between", marginTop: "1rem" }}>
                        <div className='showHideNoDocs'>
                          {page * rowsPerPage + 1} - {Math.min((page + 1) * rowsPerPage, shareFolderData.length)} {t("Folder.FolderOf")} {showTotalFolder.length} {t("home.Folder")}
                        </div>
                        <Pagination
                          count={Math.ceil(shareFolderData.length / rowsPerPage)}
                          variant='outlined'
                          shape='rounded'
                          page={page + 1}
                          onChange={(event, newPage) => handleChangePage(event, newPage - 1)}
                          sx={{ "& .Mui-selected": { background: "#0C9AD7 !important", color: "white" } }}
                        />
                      </div>
                    </>
                  )}
                </div>

                <Modal show={showUnshareModal} onHide={() => setShowUnshareModal(false)} backdrop='static' keyboard={false} dialogClassName='custom-modal-width'>
                  <Modal.Header closeButton>
                    <Modal.Title> {t("Folder.UnShareFolder")}</Modal.Title>
                  </Modal.Header>
                  <Modal.Body className='p-3'>
                    <p>{t("Folder.selectEmail")}</p>
                    <Form>
                      {unshareEmails.map((email, index) => (
                        <Form.Check type='checkbox' key={index} label={email} name='unshareEmail' checked={selectedEmail === email} onChange={() => handleEmailSelect(email)} />
                      ))}
                    </Form>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant='none' className='modalCancelButton' onClick={() => setShowUnshareModal(false)}>
                      {t("Folder.Cancel")}
                    </Button>
                    <Button className='applyBtn' variant='none' onClick={handleUnshareConfirm} disabled={!selectedEmail}>
                      {t("Folder.Confirm")}
                    </Button>
                  </Modal.Footer>
                </Modal>

                <Modal backdrop='static' keyboard={false} show={showDeleteConfirmatrionFolderModal} onHide={() => setShowDeleteConfirmatrionFolderModal(false)} dialogClassName='custom-modal-width'>
                  <Modal.Header>
                    <Modal.Title> {t("Folder.deleteConfirmation")}</Modal.Title>
                  </Modal.Header>
                  <Modal.Body className='p-3'>{t("Folder.sureDeleteFolder")}</Modal.Body>
                  <Modal.Footer className='d-flex justify-content-end gap-5 '>
                    <Button variant='none' className='modalCancelButton' onClick={() => setShowDeleteConfirmatrionFolderModal(false)}>
                      {t("Folder.Cancel")}
                    </Button>
                    <Button className='applyBtn' variant='none' onClick={HandleDeleteFolderOnly}>
                      {t("Folder.Confirm")}
                    </Button>
                  </Modal.Footer>
                </Modal>

                <Offcanvas show={showFolder} onHide={handleCloseOffcanvas} placement={document.documentElement.dir === "rtl" ? "start" : "end"} scroll={true}>
                  <Offcanvas.Header closeButton>
                    <Offcanvas.Title>{t("Folder.createFolder")}</Offcanvas.Title>
                  </Offcanvas.Header>
                  <Offcanvas.Body>
                    <Form onSubmit={handleSubmit}>
                      <Form.Group className='mb-3' controlId='folderName'>
                        <Form.Label> {t("Folder.folderName")}</Form.Label>
                        <Form.Control type='text' name='FolderName' value={formData.FolderName} onChange={handleInputChange} placeholder={t("Folder.enterfodlerName")} />
                      </Form.Group>
                      <Form.Group className='mb-3' controlId='description'>
                        <Form.Label>{t("Folder.Description")}</Form.Label>
                        <Form.Control as='textarea' name='Description' value={formData.Description} onChange={handleInputChange} rows={3} placeholder={t("Folder.enterDescription")} />
                      </Form.Group>
                      <div className='newComment d-flex align-items-center justify-content-between w-100 p-3 py-4'>
                        <Button variant='none' className='resetBtn' onClick={handleCancel}>
                          {t("Folder.Cancel")}
                        </Button>
                        <Button type='submit' variant='none' disabled={!formData.FolderName.trim()} className='applyBtn'>
                          {t("Folder.Create")}
                        </Button>
                      </div>
                    </Form>
                  </Offcanvas.Body>
                </Offcanvas>

                <Offcanvas show={showUpdateOffcanvas} onHide={handleCloseUpdate} placement={document.documentElement.dir === "rtl" ? "start" : "end"} scroll={true}>
                  <Offcanvas.Header closeButton>
                    <Offcanvas.Title>{t("Folder.updateFolder")}</Offcanvas.Title>
                  </Offcanvas.Header>
                  <hr />
                  <Offcanvas.Body>
                    <Form onSubmit={handleUpdateFormSubmit}>
                      <Form.Group className='mb-3' controlId='updateFolderName'>
                        <Form.Label> {t("Folder.folderName")}</Form.Label>
                        <Form.Control type='text' name='FolderName' value={updateFormData.FolderName} onChange={handleUpdateInputChange} placeholder={t("Folder.enterfodlerName")} />
                      </Form.Group>
                      <Form.Group className='mb-3' controlId='updateDescription'>
                        <Form.Label>{t("Folder.Description")}</Form.Label>
                        <Form.Control as='textarea' name='Description' value={updateFormData.Description} onChange={handleUpdateInputChange} rows={3} placeholder={t("Folder.enterDescription")} />
                      </Form.Group>
                      <div className='newComment d-flex align-items-center justify-content-between w-100 p-3 py-4'>
                        <Button variant='none' className='resetBtn' onClick={handleCloseUpdate}>
                          {t("Folder.Cancel")}
                        </Button>
                        <Button type='submit' variant='none' className='applyBtn'>
                          {t("Folder.Create")}
                        </Button>
                      </div>
                    </Form>
                  </Offcanvas.Body>
                </Offcanvas>

                <Offcanvas show={showFolderCanvas} onHide={handleClosesSubFolder} placement={document.documentElement.dir === "rtl" ? "start" : "end"} scroll={true}>
                  <Offcanvas.Header closeButton>
                    <Offcanvas.Title>{t("Folder.CreateFolder")}</Offcanvas.Title>
                  </Offcanvas.Header>
                  <Offcanvas.Body>
                    <Form onSubmit={handleCreateSubFolder}>
                      <Form.Group className='mb-3' controlId='folderName'>
                        <Form.Label> {t("Folder.folderName")}</Form.Label>
                        <Form.Control type='text' name='FolderName' value={formDataSubFolder.FolderName} onChange={handleSubFolderInputChange} placeholder={t("Folder.enterfodlerName")} />
                      </Form.Group>
                      <Form.Group className='mb-3' controlId='description'>
                        <Form.Label>{t("Folder.Description")}</Form.Label>
                        <Form.Control
                          as='textarea'
                          name='Description'
                          value={formDataSubFolder.Description}
                          onChange={handleSubFolderInputChange}
                          rows={3}
                          placeholder={t("Folder.enterDescription")}
                        />
                      </Form.Group>
                      <div className='newComment d-flex align-items-center justify-content-between w-100 p-3 py-4'>
                        <Button variant='none' className='resetBtn' onClick={handleClosesSubFolder}>
                          {t("Folder.Cancel")}
                        </Button>
                        <Button type='submit' variant='none' disabled={!formDataSubFolder.FolderName.trim()} className='applyBtn'>
                          {t("Folder.Create")}
                        </Button>
                      </div>
                    </Form>
                  </Offcanvas.Body>
                </Offcanvas>

                <ShareDetailsModal show={showShareDetailsModal} handleClose={() => setShowShareDetailsModal(false)} shareWith={shareWithData} />
                <AddCompletedDocuments show={showAddDocument} handleClose={handleAddDocumentClose} folderId={selectedFolderId} />
                <ShareFolderModal GetFolders={GetFoldersandDocs} GetShareWithmeFolders={GetShareWithmeFolders} show={showShareModal} handleClose={handleCloseShareModal} folderId={selectedFolderId} />
              </div>
            </>
          ) : (
            <></>
          )}
        </>
      )}
    </>
  );
}

export default Folder;
