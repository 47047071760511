import React from "react";
import { useTranslation } from "react-i18next";
import Carousel from "react-bootstrap/Carousel";
import "./signUpCarousel.css";
import signup from "../../assets/images/signUp.svg";
import SignImg from "../../assets/images/Group 11869.png";
import hundImg from "../../assets/images/100.png";

const SignUpCarousel = () => {
  const { t } = useTranslation();
  return (
    <Carousel data-bs-theme='dark' controls={false}>
      <Carousel.Item interval={5000}>
        <div className='carousel-content-signUp'>
          <div>
            <label className='fw-bolder mt-5 mb-2 carsol-desc'>{t("carousel.fillData")}</label>
            <br />
            <label className='carousel-label mb-2'>{t("carousel.joinImSigner")}</label>
            <label className='carousel-paragraph mb-2'>{t("carousel.experienceDigitally")}</label>
          </div>

          <div className='d-flex jusftify-content-start align-items-center'>
            <div style={{ display: "flex", flexDirection: "column", gap: "5rem" }}>
              <img src={hundImg} alt='' style={{ height: "50px", width: "50px" }} />
              <img src={SignImg} alt='' style={{ height: "40px", width: "40px" }} />
            </div>
            <img src={signup} alt='' className='carousel-image' />
          </div>
        </div>
      </Carousel.Item>
    </Carousel>
  );
};

export default SignUpCarousel;
