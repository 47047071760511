import React from "react";
import ReactDOM from "react-dom/client";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { TouchBackend } from "react-dnd-touch-backend/dist";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./Components/OneDrive/authConfig"; 

// -------------------
// Touch and Mouse events configuration for Drag and Drop
function isTouchDevice() {
  return "ontouchstart" in window || navigator.maxTouchPoints;
}

const backendOptions = {
  enableMouseEvents: true, // Enable mouse events so the backend works with both touch and mouse
  delayTouchStart: 200, // Delay in ms before recognizing the start of a touch as a drag
};
const backend = isTouchDevice() ? TouchBackend : HTML5Backend;

// Initialize MSAL (Microsoft Authentication Library) instance
const msalInstance = new PublicClientApplication(msalConfig);

// -------------------
// Render the root component wrapped with both MsalProvider (for OneDrive) and GoogleOAuthProvider
const GOOGLE_LOGIN_CLIENTID = process.env.REACT_APP_GOOGLE_LOGIN_CLIENTID;
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <MsalProvider instance={msalInstance}>
      <GoogleOAuthProvider clientId={GOOGLE_LOGIN_CLIENTID}>
        <DndProvider backend={backend}>
          <App /> 
        </DndProvider>
      </GoogleOAuthProvider>
    </MsalProvider>
);

reportWebVitals();
