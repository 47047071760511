import React, { useEffect } from "react";
import "./DeclinedMessage.css";
import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import declineIcon from "../../assets/images/signedDocDeclined.svg";

const DeclinedMessage = ({ message }) => {
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      pageNavigation();
    }, 5000);
  }, []);

  const pageNavigation = () => {
    if (sessionStorage.getItem("token")) {
      navigate("/home");
    } else {
      navigate("/");
    }
  };

  return (
    <div className='declined-message-container'>
      <div className='message-box d-flex flex-column justify-content-center align-items-center'>
        <img src={declineIcon} alt='iconLoading' />
        <h4 style={{ marginTop: "1rem", color: "#F14242" }}>Declined</h4>
        <p>{message}</p>

        <Button variant='none' onClick={pageNavigation} style={{ backgroundColor: "#F14242", outline: "none", color: "white" }}>
          Back to Home
        </Button>
      </div>
    </div>
  );
};

export default DeclinedMessage;
