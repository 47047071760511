import React from 'react'
import UploadDoc from "./UploadDoc/UploadDoc";



const Templates = () => {

    return (
        <UploadDoc />
    )
}

export default Templates
