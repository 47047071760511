import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Stack, Pagination } from "@mui/material";
import noDocImg from "../../assets/images/NoDocuments (1).png";
import downlaodIcon from "../../assets/images/document-download.png";
import showIcon from "../../assets/images/Show.png";
import EditIcon from "../../assets/images/Edit Square.svg";
import UploadDocIcon from "../../assets/images/paperPlus.svg";
import axios from "../../Api/api";
import { toast } from "react-toastify";
import { Button, Modal, Dropdown } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import CustomToggle from "../../Components/CustomDots/CustomToggle";
import { useUser } from "../../Context/UserContext";
import { IoChevronBackOutline } from "react-icons/io5";
import { PiSignatureDuotone } from "react-icons/pi";
import Loader from "../../Components/Loader/Loader";


function ManualDosList({ uploadedFiles, setUploadedFiles, masterDocIDs, setMasterDocIDs, fileToggleStates, setFileToggleStates, registerSignID, setRegisterSignID, email }) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [textData, setTextData] = useState([]);
  const [pdfModal, setPdfModal] = useState(false);
  const [pdfUrl, setPdfUrl] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(30);
  const [page, setPage] = useState(0);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [base64Data, setBase64Data] = useState(null);
  const [selectedFileName, setSelectedFileName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [uploadErrorMessage, setUploadErrorMessage] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [isSerial, setIsSerial] = useState(true);
  const [description, setDescription] = useState("");
  const [referenceNumber, setReferenceNumber] = useState("");
  const { user, userAccess } = useUser();

  const goBack = () => navigate(-1);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const token = sessionStorage.getItem("token");

  const AIGeneratedPDFID = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get("/v1.0/api/GetNonAITextDocument", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.data.statusCode == "1") {
        const responseData = response.data.response;
        setTextData(responseData || []);
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    AIGeneratedPDFID();
  }, []);

  const columns = [
    { label: t("tablesLabel.name"), key: "name" },
    { label: t("tablesLabel.details"), key: "extraDetails" },
    { label: "", key: "actions" },
  ];

  const handleCellClick = (event) => {
    const cell = event.target;
    cell.classList.toggle("scrollable");
  };

  const handleView = (generatedDocumentPath) => {
    setPdfUrl(generatedDocumentPath);
    setPdfModal(true);
  };

  const closePdfModal = () => {
    setPdfUrl("");
    setPdfModal(false);
  };

  const handleEditClick = (row) => {
    navigate("/manually-generate-docs", { state: { row } });
  };

  const handleFileDownload = async (generatedDocumentPath, name) => {
    try {
      const fileName = name || getFileNameFromUrl(generatedDocumentPath);
      const downloadedBlob = await downloadURL(generatedDocumentPath);
      const url = URL.createObjectURL(downloadedBlob);

      const downloadLink = document.createElement("a");
      downloadLink.href = url;
      downloadLink.download = fileName;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    } catch (error) {
      console.error("Error:", error);
      toast.error("Error downloading file. Please try again.");
    }
  };

  async function downloadURL(url) {
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`${"Error"} ${response.status}`);
      }
      const blob = await response.blob();
      return blob;
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }

  const handleUploadClick = async (generatedDocumentPath, name, documentType) => {
    // const docDetails = `${name}.${documentType}`;
    const docDetails = `${name}.pdf`;

    setIsLoading(true);
    try {
      const baseResponse = await fetch(generatedDocumentPath);
      if (!baseResponse.ok) {
        throw new Error("Failed to fetch the document");
      }
      const blob = await baseResponse.blob();
      const base64 = await blobToBase64(blob);
      // console.log("base64", base64);

      const fileNameExt = getFileNameFromUrl(generatedDocumentPath);
      const fileExtension = fileNameExt.split(".").pop();

      const body = {
        ReferenceNumber: referenceNumber,
        Description: description,
        CreatedBy: user.email,
        IsSerial: isSerial,
        isOnlySigner: false,
        registerSignID: registerSignID,
        PrimaryDocs: [
          {
            DocName: docDetails,
            DocExtension: fileExtension,
            DocFile: base64,
            IsPasswordProtected: false,
          },
        ],
      };

      const response = await axios.post("/v1.0/api/RegisterDocs", body, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.data.statusCode === "1") {
        localStorage.setItem("registerSignID", response.data.response.registerSignID);

        setUploadErrorMessage("");
        toast.success(t("uploadDocFlow.fileuploadSuccessfully"));
        setIsLoading(false);
        navigate("/uploadDoc");
      }
    } catch (error) {
      console.error("Error occurred:", error);
      setUploadErrorMessage(t("uploadDocFlow.errorUploadFile"));
      toast.error(`${t("uploadDocFlow.errorUploadFile")}: ${error.message}`);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  const blobToBase64 = (blob) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onerror = reject;
      reader.onload = () => {
        resolve(reader.result.split(",")[1]);
      };
      reader.readAsDataURL(blob);
    });

  function getFileNameFromUrl(url) {
    const parts = url.split("/");
    return parts[parts.length - 1];
  }

  return (
    <div className='container mt-3'>
      {isLoading && (
        <div className='loader-overlay'>
          <Loader />
        </div>
      )}
      <Modal show={pdfModal} onHide={closePdfModal} size='xl'>
        <Modal.Header closeButton>
          <Modal.Title>{t("macAi.viewFile")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <embed src={pdfUrl} type='application/pdf' width='100%' height='500px' />
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={closePdfModal}>
            {t("macAi.close")}
          </Button>
        </Modal.Footer>
      </Modal>
      {user.AccountType === "B" && user.RoleID === 1 && userAccess.remainingDays > 0 ? (
        <>
          <div className='userProfile-form container mb-2'>
            <div className='d-flex align-items-center gap-2 mb-4'>
              <IoChevronBackOutline className={document.documentElement.dir === "rtl" ? "rtl-icon" : ""} onClick={goBack} style={{ cursor: "pointer" }} />
              <label style={{ fontSize: "20px", fontWeight: "600" }}>{t("macAi.listCraft")}</label>
            </div>

            <TableContainer component={Paper} className='mt-3' style={{ height: "67vh" }}>
              <Table style={{ border: "none" }}>
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell key={column.label} style={{ backgroundColor: "#f0f3f4" }} className='TableHeaderCell'>
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {textData.length === 0 && (
                    <TableRow>
                      <td colSpan='6' className='p-5 text-center'>
                        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                          <img alt='imgaeloading' src={noDocImg} style={{ height: "120px", width: "180px" }} />
                          {t("dashboardCardStatuses.notDocsFound")}
                        </div>
                      </td>
                    </TableRow>
                  )}

                  {textData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
                    <TableRow key={index}>
                      <TableCell className='TableCell'>{row.name}</TableCell>
                      <TableCell className='TableCell truncate-cell' onClick={(e) => handleCellClick(e)}>
                        {row.extraDetails}
                      </TableCell>

                      <TableCell className='py-0'>
                        <Dropdown>
                          <Dropdown.Toggle as={CustomToggle} />

                          <Dropdown.Menu>
                            <Dropdown.Item className='d-flex gap-2 align-items-center' onClick={() => handleFileDownload(row.generatedDocumentPath, row.name)}>
                              <img src={downlaodIcon} alt='iconLoading' />
                              {t("dashboardCardStatuses.download")}
                            </Dropdown.Item>
                            <Dropdown.Item className='d-flex gap-2 align-items-center' onClick={() => handleView(row.generatedDocumentPath)}>
                              <img src={showIcon} alt='iconLoading' />
                              {t("dashboardCardStatuses.view")}
                            </Dropdown.Item>
                            <Dropdown.Item className='d-flex gap-2 align-items-center' onClick={() => handleEditClick(row)}>
                              <img src={EditIcon} alt='iconLoading' />
                              {t("dashboardCardStatuses.edit")}
                            </Dropdown.Item>

                            <Dropdown.Item className='d-flex gap-2 align-items-center' onClick={() => handleUploadClick(row.generatedDocumentPath, row.name, row.documentType)}>
                              <PiSignatureDuotone size={20} />
                              {t("dashboardCardStatuses.SignDoc")}
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <div style={{ display: "flex", justifyContent: "space-between", marginTop: "1rem" }}>
              <div className='showHideNoDocs'>
                {page * rowsPerPage + 1} - {Math.min((page + 1) * rowsPerPage, textData.length)} {t("tablesLabel.documentsof")} {textData.length} {t("tablesLabel.document")}
              </div>
              <Stack spacing={2}>
                <Pagination
                  count={Math.ceil(textData.length / rowsPerPage)}
                  variant='outlined'
                  shape='rounded'
                  page={page + 1}
                  onChange={(event, newPage) => handleChangePage(event, newPage - 1)}
                  sx={{ "& .Mui-selected": { background: "#0C9AD7 !important", color: "white" } }}
                />
              </Stack>
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
    </div>
  );
}

export default ManualDosList;
