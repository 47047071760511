import React, { useEffect, useState } from 'react';
import { Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TablePagination, MenuItem, Select, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import axios from "../../Api/api";
import TemplatesStatuses from "./TemplatesStatuses";
import { useNavigate } from 'react-router-dom';
import TemplatesSearchFilters from './TemplatesSearchFilter';
import { Dropdown } from 'react-bootstrap';
import { AiOutlineEdit } from 'react-icons/ai';
import { toast } from 'react-toastify';
import Loader from '../../Components/Loader/Loader';

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
        href='#'
        ref={ref}
        onClick={(e) => {
            e.preventDefault();
            onClick(e);
        }}
        style={{ textDecoration: "none" }}
    >
        {children}
        <span className='threedots' />
    </a>
));


const InactiveTemplates = () => {
    const { t } = useTranslation();
    const [data, setData] = useState([]);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [requestNo, setRequestNo] = useState("");
    const [templateName, setTemplateName] = useState("");
    const [sortedData, setSortedData] = useState([]);
    const [templateID, setTemplateID] = useState("");
    const [searchResults, setSearchResults] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(5);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showDeactivateModal, setShowDeactivateModal] = useState(false);
    const [selectedTemplateID, setSelectedTemplateID] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [originalTemplateName, setOriginalTemplateName] = useState("");

    const navigate = useNavigate();
    const token = sessionStorage.getItem("token");

    const fetchTemplatesData = async () => {
        setIsLoading(true);
        try {
            const response = await axios.get("/v1.0/api/GetInActiveTemplates", {
                headers: {
                    Authorization: `Bearer ${token} `,
                },
            });
            if (response.data) {
                const sortedData = response.data.response.sort((a, b) => new Date(b.createdOn) - new Date(a.createdOn));
                setSortedData(sortedData);
                //console.log("sorted", sortedData);

                setIsLoading(false);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchTemplatesData();
    }, [token]);

    const handleSearch = async () => {
        setIsLoading(true);
        try {
            let response;

            if (templateName) {
                response = await axios.get("/v1.0/api/GetInActiveTemplates", {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                    params: {
                        TemplateName: templateName,
                    },
                });

                if (response.data.response) {
                    //console.log("search", response.data.response);
                    setSortedData(response.data.response || []);
                }
            }

            setIsLoading(false);
        } catch (error) {
            console.error("Error fetching data:", error);
            setIsLoading(false);
        }
    };

    const clearSearch = () => {
        setStartDate("");
        setEndDate("");
        setRequestNo("");
        setTemplateName("");
        handleSearch();
        fetchTemplatesData();
    };

    const columns = [
      { label: t("tablesLabel.sr"), key: "index" },
      { label: t("tablesLabel.date"), key: "createdOn" },
      { label: t("tablesLabel.templateName"), key: "templateName" },
      { label: t("tablesLabel.requestNumber"), key: "companyPrefixNo" },
      { label: t("tablesLabel.scope"), key: "templateID" },
    ];

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        let hours = date.getHours();
        const minutes = date.getMinutes();
        const seconds = date.getSeconds();

        const ampm = hours >= 12 ? "PM" : "AM";
        hours = hours % 12 || 12; // Convert midnight (0) to 12 AM

        const formattedMinutes = (minutes < 10 ? "0" : "") + minutes;

        return `${day}/${month}/${year} ${hours}:${formattedMinutes} ${ampm}`;
    };


    const handleActivateTemplate = async (templateID) => {
        setIsLoading(true);
        try {
            const response = await axios.put(
                `/v1.0/api/ActivateTemplate?TemplateID=${templateID}`,
                { TemplateID: templateID },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            if (response.data && response.data.statusCode === "1") {
                toast.success("Template Activated Successfully");
                fetchTemplatesData();
            } else {
                toast.error("Failed to set InActive");
            }
        } catch (error) {
            toast.error("Error toggling document status:", error);
        } finally {
            setIsLoading(false);
        }
    }

    return (
      <div className='templates-container'>
        <TemplatesStatuses />
        <hr />

        <Grid container spacing={2} justifyContent='center'>
          <Grid item xs={12}>
            {/* Loader overlay */}
            {isLoading && (
              <div className='loader-overlay'>
                <Loader />
              </div>
            )}

            <div className='container py-2 h-100 '>
              <h4 className='mb-4'>Inactive Templates</h4>

              <TemplatesSearchFilters templateName={templateName} setTemplateName={setTemplateName} handleSearch={handleSearch} clearSearch={clearSearch} />

              {/* Table */}
              <TableContainer component={Paper} style={{ height: "320px" }}>
                <Table stickyHeader style={{ border: "1px solid #ccc" }}>
                  {/* Table Headers */}
                  <TableHead>
                    <TableRow>
                      {columns.map((column) => (
                        <TableCell key={column.label} style={{ backgroundColor: "#dee2e6" }} className='TableHeaderCell'>
                          {column.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>

                  {/* Table Body */}
                  <TableBody>
                    {sortedData.length === 0 && (
                      <TableRow>
                        <td colSpan='6' className='p-5 text-center'>
                          {t("dashboardCardStatuses.notDocsFound")}
                        </td>
                      </TableRow>
                    )}
                    {sortedData.map((row, index) => (
                      <TableRow key={index}>
                        {/* Table Cells */}
                        <TableCell className='TableCell'>{index + 1}</TableCell>
                        <TableCell className='TableCell'>{row.createdOn ? formatDate(row.createdOn) : ""}</TableCell>
                        {/* <TableCell style={{ padding: 0 }}>{row.registerSignID}</TableCell> */}
                        <TableCell className='TableCell'>{row.templateName}</TableCell>
                        <TableCell className='TableCell'>{row.companyPrefixNo}</TableCell>
                        <TableCell className='TableCell'>
                          <span style={{ background: "lightblue", padding: "5px 8px", borderRadius: "5px" }}>{row.scope === "I" ? "Individual" : "Business"}</span>
                        </TableCell>
                        <TableCell className='TableCell p-0'>
                          <Dropdown>
                            <Dropdown.Toggle as={CustomToggle} />

                            <Dropdown.Menu>
                              <Dropdown.Item className='d-flex gap-2 align-items-center' onClick={() => handleActivateTemplate(row.templateID)}>
                                <AiOutlineEdit size={18} style={{ cursor: "pointer" }} />
                                Activate template
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </TableCell>
                        <TableCell style={{ padding: 0 }}></TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>

              {/* Pagination */}
              <TablePagination
                rowsPerPageOptions={[5, 10]}
                component='div'
                count={data.length}
                rowsPerPage={pageSize}
                page={page}
                onPageChange={(event, newPage) => setPage(newPage)}
                onRowsPerPageChange={(event) => {
                  setPageSize(parseInt(event.target.value, 10));
                  setPage(0);
                }}
              />
            </div>
          </Grid>
        </Grid>
      </div>
    );
}

export default InactiveTemplates