import axios from "../Api/api";
import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { FaCheck } from "react-icons/fa6";
import { GrOrderedList } from "react-icons/gr";
import arrowIcon from "../assets/images/arrow.svg";
import arrowDownIcon from "../assets/images/down arrow.svg";
import { useTranslation } from "react-i18next";
import auditIcon from "../assets/images/task-square.png";
import Loader from "./Loader/Loader";

const SignOrder = ({ masterDocID }) => {
  const { t } = useTranslation();

  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [apiResponse, setApiResponse] = useState();
  const token = sessionStorage.getItem("token");

  const colorOptions = [
    {
      key: "originator",
      color: "#d3d3d3",
      name: t("signOrderDaigram.originator"),
    },
    {
      key: "Signed",
      color: "#74F4A0",
      name: t("signOrderDaigram.signed"),
    },
    {
      key: "Pending",
      color: "#FDDB7C",
      name: t("signOrderDaigram.pending"),
    },
    {
      key: "Not Started",
      color: "#B0FCFB",
      name: t("signOrderDaigram.notStarted"),
    },
    {
      key: "Declined",
      color: "#F67F7F",
      name: t("signOrderDaigram.declined"),
    },
    {
      key: "Viewed",
      color: "darkkhaki",
      name: t("signOrderDaigram.viewed"),
    },
    {
      key: "Recalled",
      color: "#b3ebf2",
      name: t("signOrderDaigram.recalled"),
    },
  ];

  const handleSignOrder = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`/v1.0/api/GetSignatureOrder?MasterDocID=${masterDocID}`, {
        headers: {
          Authorization: "Bearer " + token,
        },
      });
      if (response.data.statusCode === "1") {
        setIsLoading(false);
        setApiResponse(response);
        setShowModal(true);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const getColorForSignatory = (signStatus) => {
    const colorOption = colorOptions.find((option) => option.key === signStatus);
    return colorOption ? colorOption.color : "";
  };

  const uniqueSignatories = [];

  apiResponse &&
    apiResponse.data.response.forEach((signatory, index) => {
      if (index === 0 || (index > 0 && signatory.email !== apiResponse.data.response[index - 1].email)) {
        uniqueSignatories.push(signatory);
      }
    });

  return (
    <>
      {isLoading && (
        <div className='loader-overlay'>
          <Loader />
        </div>
      )}

      <div className='d-flex gap-2 align-items-center' onClick={() => handleSignOrder()}>
        <img src={auditIcon} alt='iconLoading' />
        <span>{t("signOrderDaigram.Signorder")}</span>
      </div>

      <Modal show={showModal} backdrop='static' keyboard={false} onHide={closeModal} centered>
        <Modal.Header>
          <Modal.Title>{t("signOrderDaigram.Signorder")}</Modal.Title>
        </Modal.Header>
        <Modal.Body className='signature-modal-body' style={{ maxHeight: "50vh", overflowY: "auto" }}>
          <div className='d-flex flex-wrap p-2'>
            {colorOptions.map((item) => (
              <div className='d-flex m-2' key={item.name}>
                <div
                  style={{
                    backgroundColor: item.color,
                    width: "20px",
                    height: "20px",
                    verticalAlign: "middle",
                    margin: "0 5px",
                    borderRadius: "50%",
                  }}
                ></div>
                <span>{item.name}</span>
              </div>
            ))}
          </div>
          <hr />
          <div className='row'>
            <div className='col fw-bold'>{t("signOrderDaigram.originator")}</div>
            <div className='col '></div>
            <div className='col fw-bold'>{t("signOrderDaigram.signatories")}</div>
            <div className='col'></div>
            <div className='col fw-bold'>{t("signOrderDaigram.status")}</div>
          </div>

          {apiResponse &&
            apiResponse.data &&
            uniqueSignatories.map((signatory, index) => (
              <div className='row pb-2 d-flex align-items-start' key={signatory.createdBy}>
                <div className='col d-flex align-items-center'>
                  {index === 0 && (
                    <div
                      style={{
                        backgroundColor: "#d3d3d3",
                        margin: "5px",
                        width: "max-content",
                        padding: "5px 10px",
                        borderRadius: "10px",
                      }}
                      title={signatory.createdBy}
                    >
                      {signatory.createdByName}
                    </div>
                  )}
                </div>
                <div className={`col d-flex align-items-center ${document.documentElement.dir === "rtl" ? "rtl-icon justify-content-center" : ""}`}>
                  {index === 0 ? <img src={arrowIcon} width={40} /> : null}
                </div>
                <div className='col d-flex flex-column'>
                  <div
                    style={{
                      backgroundColor: getColorForSignatory(signatory.signStatus),
                      margin: "5px",
                      width: "max-content",
                      padding: "5px 10px",
                      borderRadius: "10px",
                    }}
                    title={signatory.email}
                  >
                    {signatory.name}
                  </div>
                  {index < uniqueSignatories.length - 1 ? (
                    <>
                      <img src={arrowDownIcon} alt='Down Arrow' width={30} height={30} className='mx-4' />
                    </>
                  ) : null}
                </div>
                <div className='col d-flex align-items-center p-0'></div>
                <div className='col d-flex align-items-center'>
                  <div
                    style={{
                      backgroundColor: getColorForSignatory(signatory.signStatus),
                      margin: "5px",
                      width: "max-content",
                      padding: "5px 10px",
                      borderRadius: "10px",
                    }}
                    title={signatory.signStatus}
                    key={signatory.createdBy}
                  >
                    {signatory.signStatus}
                  </div>
                </div>
              </div>
            ))}
        </Modal.Body>

        <Modal.Footer className='modalFooter'>
          <Button variant='none' className='applyBtn text-white w-25' onClick={closeModal}>
            {t("signOrderDaigram.close")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default SignOrder;
