import React, { useState, useEffect } from "react";
import axios from "../../Api/api";
import { Link, useNavigate } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { IoArrowBack } from "react-icons/io5";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import imSignerLogo from "../../assets/images/IAMSignerWebLogo.png";
import "./style.css";
import ForgotCarousel from "./forgotCursol";
import LangSelector from "../../Components/Language/LangSelector";
import { Modal } from "react-bootstrap";
import thankyou from "../../assets/images/thankyouIcon.svg";
import Loader from "../../Components/Loader/Loader";

const ForgotPassword = ({ message }) => {
  const { t } = useTranslation();
  const [verified, setVerified] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const Site_key_recaptcha = process.env.REACT_APP_SITE_KEY_RECAPTCHA;

  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const refreshToken = process.env.REACT_APP_STATIC_TOKEN;

  const onSubmit = async (data) => {
    setIsLoading(true);
    if (!verified) {
      toast.error(t("logScreens.reCaptcha"));
      setIsLoading(false);
      return;
    }

    try {
      const response = await axios.post("/Account/ForgotPassword", { EmailID: data.email }, { headers: { Authorization: `Bearer ${refreshToken}` } });

      if (response.data.statusCode === "1") {
        setShowSuccessModal(true);
        setTimeout(() => {
          setShowSuccessModal(false);
          navigate("/");
        }, 10000);
      } else if (response.data.statusCode === "7") {
        toast.info(response.data.message);
      } else {
        toast.error(t("logScreens.forgetPassErrorToast"));
      }
    } catch (error) {
      toast.error(t("logScreens.forgetPassErrorToast"));
      console.error("API Error:", error);
      setIsLoading(false);
    } finally {
      reset();
      setIsLoading(false);
    }
  };

  const onChange = (value) => {
    setVerified(true);
  };

  const handleCloseModal = () => {
    setShowSuccessModal(false);
  };

  return (
    <>
      <Container fluid className='login-container'>
        {isLoading && (
          <div className='loader-overlay'>
            <Loader />
          </div>
        )}

        <Row>
          <Col sm={12} md={6} className='login-col d-flex flex-column  '>
            <div className='login-form'>
              <div className='headingIMSigner'>
                <div className='d-flex gap-2 align-items-center'>
                  <Link to='/'>
                    <IoArrowBack size={22} style={{ color: "#0F1315", cursor: "pointer" }} />
                  </Link>{" "}
                  <img alt='logo' style={{ height: "45px", width: "140px" }} src={imSignerLogo} />
                </div>
                <LangSelector style={{ padding: "0px" }} />
              </div>

              <div className='mb-3'>
                <label style={{ fontSize: "20px", fontWeight: "700" }}>{t("logScreens.forgetPass")}</label>
              </div>

              <Form onSubmit={handleSubmit(onSubmit)}>
                <Form.Group className='mb-3'>
                  <Form.Label className='mb-4'>{t("logScreens.forgetPassDecs")}</Form.Label>
                  <Form.Control
                    type='email'
                    {...register("email", {
                      required: t("logScreens.emailRequired"),
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                        message: t("logScreens.invalidEmail"),
                      },
                    })}
                    className={`form-control ${errors.email ? "is-invalid" : ""}`}
                    disabled={isLoading}
                    placeholder={t("logScreens.enterEmailLog")}
                  />
                  {errors.email && <div className='invalid-feedback'>{errors.email.message}</div>}
                </Form.Group>

                <ReCAPTCHA className='g-recaptcha' sitekey={Site_key_recaptcha} onChange={onChange} />

                <Button type='submit' disabled={!verified || isLoading} style={{ backgroundColor: isLoading || !verified ? "#889CAA" : "" }} className='forgot-button'>
                  {t("logScreens.submit")}
                </Button>
              </Form>
            </div>
          </Col>
          <Col md={6} className='slider-col d-none d-md-flex flex-column align-items-center'>
            <ForgotCarousel />
          </Col>
        </Row>
      </Container>

      <Modal show={showSuccessModal} onHide={handleCloseModal} centered dialogClassName='custom-modal-width'>
        <Modal.Body className='p-3'>
          <div className='thankyou-message-modal'>
            <div className='message-box d-flex flex-column justify-content-center align-items-center'>
              <img src={thankyou} alt='iconLoading' />
              <h4 style={{ marginTop: "1rem", color: "#24B032" }}>{t("logScreens.sent")}</h4>
              <p className='m-0'>{t("logScreens.passwordResetLink")}</p>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ForgotPassword;
