import React, { useState, useEffect } from "react";
import axios from "../../Api/api";
import "./AuditTrail.css";
import logo from "../../assets/images/Web.svg";
import { Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import noDocImg from "../../assets/images/NoDocuments (1).png";
import { useTranslation } from "react-i18next";
import Loader from "../../Components/Loader/Loader";

export default function AuditTrailSignaturePage({ url }) {
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState();
  const [signatoryData, setSignatoryData] = useState({});
  const [receivers, setReceivers] = useState([]);

  const currentUrl = window.location.href;
  const parts = currentUrl.split("/");
  const idIndex = parts.indexOf("id");

  useEffect(() => {
    if (idIndex !== -1 && idIndex < parts.length - 1) {
      const pathAfterId = parts.slice(idIndex + 1).join("/");
      getAuditTrail(pathAfterId);
      getAuditCompletedStatus(pathAfterId);
    }
  }, []);

  const getAuditTrail = async (pathAfterId) => {
    setIsLoading(true);
    try {
      const response = await axios.get(url, {
        headers: {
          SignAuth: pathAfterId,
        },
      });
      setData(response.data.response);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const getAuditCompletedStatus = async (pathAfterId) => {
    setIsLoading(true);
    try {
      const response = await axios.get("/v1.0/api/GetSignAuditStatusVerification", {
        headers: {
          SignAuth: pathAfterId,
        },
      });
      const signatoryResponse = response.data.response;
      setSignatoryData({
        senderName: signatoryResponse.senderName,
        senderEmail: signatoryResponse.senderEmail,
        docStatus: signatoryResponse.docStatus,
        createdOn: signatoryResponse.createdOn,
      });
      setReceivers(signatoryResponse.receivers);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    let hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12;
    const formattedMinutes = (minutes < 10 ? "0" : "") + minutes;
    return `${day}/${month}/${year} ${hours}:${formattedMinutes} ${ampm}`;
  };

  return (
    <Grid className='audit-trail-container'>
      <div className='header'>
        <img src={logo} alt='logo' />
        <h4>{t("auditTrailScreen.auditTrail")}</h4>
      </div>
      {isLoading && (
        <div className='loader-overlay'>
          <Loader />
        </div>
      )}

      <div className='signers-info'>
        <div>
          <span style={{ fontSize: "18px" }} className='fw-bold'>
            {t("auditTrailModal.senderInfo")}
          </span>
        </div>
        <TableContainer component={Paper}>
          <Table style={{ border: "none" }}>
            <TableHead style={{ backgroundColor: "#f0f3f4" }}>
              <TableRow>
                <TableCell style={{ fontFamily: "Times New Roman" }}>{t("tablesLabel.date")}</TableCell>
                <TableCell style={{ fontFamily: "Times New Roman" }}>{t("tablesLabel.name")}</TableCell>
                <TableCell style={{ fontFamily: "Times New Roman" }}>{t("tablesLabel.email")}</TableCell>
                <TableCell style={{ fontFamily: "Times New Roman" }}>{t("tablesLabel.status")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell style={{ fontFamily: "Times New Roman" }}>{signatoryData.createdOn ? formatDate(signatoryData.createdOn) : ""}</TableCell>
                <TableCell style={{ fontFamily: "Times New Roman" }}>{signatoryData.senderName}</TableCell>
                <TableCell style={{ fontFamily: "Times New Roman" }}>{signatoryData.senderEmail}</TableCell>
                <TableCell style={{ fontFamily: "Times New Roman" }}>{signatoryData.docStatus}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <div className='signers-info'>
        <div>
          <span style={{ fontSize: "18px" }} className='fw-bold'>
            {t("auditTrailModal.receiverInfo")}
          </span>
        </div>
        <TableContainer component={Paper}>
          <Table style={{ border: "none" }}>
            <TableHead style={{ backgroundColor: "#f0f3f4" }}>
              <TableRow>
                <TableCell style={{ fontFamily: "Times New Roman" }}>{t("tablesLabel.name")}</TableCell>
                <TableCell style={{ fontFamily: "Times New Roman" }}>{t("tablesLabel.email")}</TableCell>
                <TableCell style={{ fontFamily: "Times New Roman" }}>{t("tablesLabel.status")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {receivers.length === 0 && (
                <TableRow>
                  <TableCell colSpan='3' className='no-docs'>
                    <img alt='' src={noDocImg} />
                    {t("dashboardCardStatuses.notDocsFound")}
                  </TableCell>
                </TableRow>
              )}
              {receivers.map((receiver, index) => (
                <TableRow key={index}>
                  <TableCell style={{ fontFamily: "Times New Roman" }}>{receiver.receiverName}</TableCell>
                  <TableCell style={{ fontFamily: "Times New Roman" }}>{receiver.receiverEmail}</TableCell>
                  <TableCell style={{ fontFamily: "Times New Roman" }}>{receiver.signStatus}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <div className='journal-info'>
        <div>
          <span style={{ fontSize: "18px" }} className='fw-bold'>
            {t("auditTrailModal.usersActivity")}
          </span>
        </div>
        <TableContainer component={Paper} className='table-container'>
          <Table style={{ border: "none" }}>
            <TableHead style={{ backgroundColor: "#f0f3f4" }}>
              <TableRow>
                <TableCell style={{ fontFamily: "Times New Roman" }}>{t("tablesLabel.date")}</TableCell>
                <TableCell style={{ fontFamily: "Times New Roman" }}>{t("tablesLabel.actions")}</TableCell>
                <TableCell style={{ fontFamily: "Times New Roman" }}>{t("tablesLabel.details")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.length === 0 && (
                <TableRow>
                  <TableCell colSpan='3' className='no-docs'>
                    <img alt='' src={noDocImg} />
                    {t("dashboardCardStatuses.notDocsFound")}
                  </TableCell>
                </TableRow>
              )}
              {data.map((row, index) => (
                <TableRow key={index}>
                  <TableCell style={{ fontFamily: "Times New Roman" }}>{row.createdOn ? formatDate(row.createdOn) : ""}</TableCell>
                  <TableCell style={{ fontFamily: "Times New Roman" }}>{row.status}</TableCell>
                  <TableCell style={{ fontFamily: "Times New Roman" }}>{row.message}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </Grid>
  );
}
