import React, { useState } from "react";
import "./Login.css";
import { Container, Row, Col, Form, Button, Nav, InputGroup } from "react-bootstrap";
import LoginCarousel from "./loginCarousel/LoginCarousel";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "../../Api/api";
import ReCAPTCHA from "react-google-recaptcha";
import { MdRemoveRedEye } from "react-icons/md";
import { FaEyeSlash } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import imSignerLogo from "../../assets/images/IAMSignerWebLogo.png";
import Otpinput from "../VerifyOtp/VerifyOtpPage";
import LangSelector from "../../Components/Language/LangSelector";
import Loader from "../../Components/Loader/Loader";
import GoogleLogin from "../../Components/Google Login/GoogleLogin";

const Login = ({ onLogin }) => {
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [isLoginButtonDisabled, setIsLoginButtonDisabled] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [verified, setVerified] = useState(false);
  const [showOtpModal, setShowOtpModal] = useState(false);

  const Site_key_recaptcha = process.env.REACT_APP_SITE_KEY_RECAPTCHA;

  // function to handle login system
  const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  const handleEmailChange = (e) => {
    const newEmail = e.target.value;
    setEmail(newEmail);

    if (!emailPattern.test(newEmail)) {
      setEmailError(t("logScreens.invalidEmail"));
    } else {
      setEmailError("");
    }
  };

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);

    if (e.key === "Enter") {
      handleLogin(e);
    }
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleLogin = async (e) => {
    e.preventDefault();

    setIsLoginButtonDisabled(true);
    setIsLoading(true);

    setEmailError("");
    setPasswordError("");

    if (!verified) {
      toast.error(t("logScreens.reCaptcha"));
      setIsLoginButtonDisabled(false);
      setIsLoading(false);
      return;
    }

    if (!email || !password) {
      if (!email || email.length === 0) setEmailError(t("logScreens.emailRequired"));
      if (!password) setPasswordError(t("logScreens.passwordRequired"));

      setIsLoginButtonDisabled(false);
      setIsLoading(false);

      return;
    }

    if (!emailPattern.test(email)) {
      setEmailError(t("logScreens.invalidEmail"));
      setIsLoginButtonDisabled(false);
      setIsLoading(false);

      return;
    } else {
      setEmailError("");
    }

    const refreshToken = process.env.REACT_APP_STATIC_TOKEN;
    try {
      const response = await axios.post(
        "/Account/Login",
        {
          Email: email,
          Password: password,
        },
        {
          headers: {
            Authorization: `Bearer ${refreshToken}`,
          },
        }
      );

      if (response.data.statusCode === "1") {
        setShowOtpModal(true, {
          state: {
            email,
            password,
          },
        });
        toast.success(t("logScreens.otpsend"));
      }
    } catch (error) {
      console.log("eror", error);
      if (error.response.data.message === "Invalid credentials.") {
        toast.error("Inavlaid password!");
      } else if (error.response.data.message === "User does not exist.") {
        toast.info("User does not exist.");
      } else if (error.response.data.message === "User is deactivated.") {
        toast.info("User is deactivated.");
      } else if (error.response.data.successStatus === false) {
        toast.info("There is unexpected issue occurred, please try again later.");
      }
    } finally {
      setIsLoginButtonDisabled(false);
      setIsLoading(false);
    }
  };

  const onChange = (value) => {
    setVerified(true);
  };

  const handleOtpModalClose = () => {
    setShowOtpModal(false);
  };

  return (
    <>
      <Container fluid className='login-container'>
        <Row>
          <Col sm={12} md={6} className='login-col d-flex flex-column  '>
            <div className='login-form '>
              <div className='headingIMSigner'>
                <Link to='https://iamsigner.com'>
                  <img alt='logo' style={{ height: "45px", width: "140px" }} src={imSignerLogo} />
                </Link>
                <LangSelector style={{ padding: "0px" }} />
              </div>
              {isLoading && (
                <div className='loader-overlay'>
                  <Loader />
                </div>
              )}

              <h4 className='form-heading fw-bold'>{t("logScreens.WelcomeImSigner")}</h4>
              <h4 className='form-heading-desc'>{t("logScreens.imSignerDesc")}</h4>
              <Form className='mt-2'>
                <Form.Group controlId='formBasicEmail'>
                  <Form.Label className='form-label'>{t("logScreens.email")}</Form.Label>
                  <InputGroup className='input-group d-flex justify-content-between'>
                    <Form.Control
                      type='email'
                      autoComplete='off'
                      placeholder={t("logScreens.enterEmailLog")}
                      value={email}
                      required
                      onChange={handleEmailChange}
                      className={`form-control text-secondary ${emailError ? "is-invalid" : ""}`}
                    />
                  </InputGroup>
                  <Form.Control.Feedback type='invalid'>{emailError}</Form.Control.Feedback>
                </Form.Group>

                <Form.Group className='mt-2' controlId='formBasicPassword'>
                  <Form.Label>{t("logScreens.password")}</Form.Label>
                  <div className='password-input'>
                    <Form.Control
                      type={showPassword ? "text" : "password"}
                      value={password}
                      placeholder={t("logScreens.enterPassword")}
                      onKeyDown={handlePasswordChange}
                      onChange={handlePasswordChange}
                      className={`custom-password-input form-control text-secondary ${passwordError ? "is-invalid" : ""} ${showPassword ? "" : "passwordSize"}`}
                    />
                    <span className='toggle-password' onClick={handleTogglePasswordVisibility}>
                      {showPassword ? <FaEyeSlash /> : <MdRemoveRedEye />}
                    </span>
                  </div>
                  {passwordError && <Form.Text className='text-danger'>{passwordError}</Form.Text>}
                </Form.Group>

                <div className='links d-flex justify-content-between mt-2 mb-3'>
                  <Nav.Link as={Link} to='/forgotpassword' className='link-item fw-bold p-0' style={{ fontSize: "14px" }}>
                    {t("logScreens.forgetPassword")}
                  </Nav.Link>
                </div>
                <ReCAPTCHA className='g-recaptcha ' sitekey={Site_key_recaptcha} onChange={onChange} />

                <Button onClick={handleLogin} className='login-button' style={{ backgroundColor: isLoginButtonDisabled || !verified ? "#889CAA" : "" }} disabled={isLoginButtonDisabled || !verified}>
                  {t("logScreens.logIn")}
                </Button>
                {/* Available soon */}
                {/* <GoogleLogin /> */}

                <div style={{ marginTop: "30px" }} className='d-flex align-items-center justify-content-start'>
                  {t("logScreens.dontHaveAcc")}
                  <Nav.Link as={Link} to='/signup' className='link-item fw-bold px-2'>
                    {t("logScreens.signUp")}
                  </Nav.Link>
                </div>
              </Form>
            </div>
          </Col>
          <Col md={6} className='slider-col d-none d-md-flex flex-column align-items-center'>
            <div className='slider'>
              <LoginCarousel />
            </div>
          </Col>
        </Row>
      </Container>
      <Otpinput show={showOtpModal} handleClose={handleOtpModalClose} email={email} password={password} onLogin={onLogin} />
    </>
  );
};

export default Login;
