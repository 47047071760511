import React, { useState, useEffect } from "react";
import axios from "../../Api/api";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { Modal, Button, Form, Accordion, ModalFooter } from "react-bootstrap";
import "./style.css";
import Dropdown from "react-bootstrap/Dropdown";
import { Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { useTranslation } from "react-i18next";
import CreateTeamForm from "./CreateTeamForm";
import usersIcon from "../../assets/images/users.svg";
import noDocImg from "../../assets/images/NoDocuments (1).png";
import editIcon from "../../assets/images/EditSquare.png";
import CoBrandingDetails from "../Cobranding/Cobranding";
import { useUser } from "../../Context/UserContext";
import Offcanvas from "react-bootstrap/Offcanvas";
import CustomToggle from "../CustomDots/CustomToggle";
import activeIcon from "../../assets/images/Active.svg";
import InactiveIcon from "../../assets/images/InactiveIcon.svg";
import DaysToExpire from "../Cobranding/DaysToExpire";
import UserLisence from "../UserLisence/UserLisence";
import Loader from "../Loader/Loader";

function CreateTeam() {
  const { t } = useTranslation();
  const [roles, setRoles] = useState([]);
  const [selectedEmail, setSelectedEmail] = useState("");
  const [selectedAppUserId, setSelectedAppUserId] = useState("");
  const [selectedIsActive, setSelectedIsActive] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [showDeactivateModal, setShowDeactivateModal] = useState(false);
  const [showActiveModal, setShowActiveModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const { user, userAccess } = useUser();
  const token = sessionStorage.getItem("token");

  const [updateUserData, setUpdateUserData] = useState({
    UserName: "",
    FullName: "",
    EmailID: "",
    MobileNumber: "",
    RoleID: "",
  });

  const handleUpdate = (email) => {
    const selectedUser = data.find((user) => user.emailID === email);
    setUpdateUserData({
      UserName: selectedUser.userName,
      FullName: selectedUser.fullName,
      EmailID: selectedUser.emailID,
      MobileNumber: selectedUser.mobileNumber,
      RoleID: selectedUser.roleID,
    });
    reset();
    setShowUpdateModal(true);
  };

  const handleCloseUpdateModal = () => {
    reset();
    setShowUpdateModal(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "UserName") {
      setUpdateUserData({
        ...updateUserData,
        UserName: value,
        EmailID: value,
      });
    } else if (name === "RoleID") {
      setUpdateUserData({
        ...updateUserData,
        RoleID: value,
      });
    } else {
      setUpdateUserData({
        ...updateUserData,
        [name]: value,
      });
    }
  };
  const {
    formState: { errors },
    register,
    reset,
  } = useForm();

  //update user api
  const updateUser = async () => {
    const { FullName, MobileNumber, RoleID, UserName, EmailID } = updateUserData;
    try {
      setIsLoading(true);
      const response = await axios.put(
        "/v1.0/api/UpdateAppUser",
        {
          FullName,
          MobileNumber,
          RoleID,
          UserName,
          EmailID,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.data.statusCode === "1") {
        toast.success(t("createTeam.userUpdateSuccess"));
        setShowUpdateModal(false);
        reset();
        fetchTeamMembers();
      } else {
        toast.error(t("createTeam.failedToUpdateUser"));
      }
    } catch (error) {
      toast.error(t("createTeam.errUpdateUser"));
    } finally {
      setIsLoading(false);
    }
  };

  const openDeactivateModal = () => {
    setShowDeactivateModal(true);
  };

  const closeDeactivateModal = () => {
    setShowDeactivateModal(false);
  };

  const openActiveModal = () => {
    setShowActiveModal(true);
  };

  const closeActiveModal = () => {
    setShowActiveModal(false);
  };

  //Get Team APi
  const fetchTeamMembers = async () => {
    try {
      if (!user.AccountID) {
        return;
      }
      setIsLoading(true);
      const response = await axios.get(`/v1.0/api/GetTeam?AccountID=${user.AccountID}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setData(response.data.response || []);
      setSearchResults(response.data.response || []);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  //Roles Api
  const fetchRoles = async () => {
    try {
      const response = await axios.get("v1.0/api/GetUserRoles", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.data.successStatus) {
        setRoles(response.data.response);
      } else {
        console.error(t("createTeam.errRoles"));
      }
    } catch (error) {
      console.error(t("createTeam.errRoles"));
    }
  };

  useEffect(() => {
    fetchTeamMembers();
    fetchRoles();
  }, [token, user.AccountID, userAccess.remainingDays]);

  const handleDeactivate = (email, isActive) => {
    setSelectedEmail(email);
    setSelectedAppUserId(user.AccountID);
    setSelectedIsActive(!isActive);
    openDeactivateModal();
  };

  const handleActiveAgain = (email, isActive) => {
    setSelectedEmail(email);
    setSelectedAppUserId(user.AccountID);
    setSelectedIsActive(!isActive);
    openActiveModal();
  };

  //User Deactivate
  const userDeactivate = async () => {
    const requestBody = {
      AccountID: selectedAppUserId,
      EmailID: selectedEmail,
      IsActive: selectedIsActive,
    };
    setIsLoading(true);
    try {
      const response = await axios.put("/v1.0/api/UpdateAppUserStatus", requestBody, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.data.statusCode === "12") {
        toast.info("User license is not valid or subscription has expired.");
      } else if (response.data.response === false) {
        toast.success(t("createTeam.userDeactivateSuccess"));
      } else if (response.data.response === true) {
        toast.success("User Activated successfully");
      } else if (response.data.statusCode == "3") {
        toast.info("User license is not valid or subscription has expired.");
      }
    } catch (error) {
      toast.error(t("createTeam.errDeactivate"));
      console.error(t("createTeam.errDeactivate"));
    } finally {
      setIsLoading(false);
      fetchTeamMembers();
      closeActiveModal();
      closeDeactivateModal();
    }
  };

  const columns = [
    { label: t("tablesLabel.name"), key: "fullName" },
    { label: t("tablesLabel.email"), key: "emailID" },
    { label: t("tablesLabel.role"), key: "roleNameEn" },
    { label: t("tablesLabel.status"), key: "isActive" },
    { label: "", key: "actions" },
  ];

  return (
    <div className='container mt-3'>
      {/* Update user overlay */}

      <Offcanvas show={showUpdateModal} onHide={handleCloseUpdateModal} placement={document.documentElement.dir === "rtl" ? "start" : "end"} scroll={true}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>{t("createTeam.updateUser")}</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Form>
            <Form.Group controlId='updateEmailID'>
              <Form.Label>{t("createTeam.email")}</Form.Label>
              <Form.Control type='email' {...register("EmailID", { required: true })} name='EmailID' value={updateUserData.EmailID} onChange={handleInputChange} disabled />
            </Form.Group>
            <Form.Group className='mt-2' controlId='updateUserName'>
              <Form.Label>{t("createTeam.fullName")}</Form.Label>
              <Form.Control
                type='text'
                placeholder={t("createTeam.enterfullName")}
                {...register("FullName", { required: true })}
                name='FullName'
                value={updateUserData.FullName}
                onChange={handleInputChange}
              />
              {errors.FullName && <Form.Text style={{ color: "red" }}>{t("createTeam.fieldRequired")}</Form.Text>}
            </Form.Group>
            <Form.Group className='mt-2' controlId='updateMobileNumber'>
              <Form.Label>{t("createTeam.mobileNumber")}</Form.Label>
              <Form.Control
                type='text'
                placeholder={t("createTeam.entermobileNumber")}
                {...register("MobileNumber", {
                  required: true,
                  pattern: /^\d{11}$/,
                })}
                name='MobileNumber'
                value={updateUserData.MobileNumber}
                onChange={handleInputChange}
              />
              {errors.MobileNumber?.type === "required" && <Form.Text style={{ color: "red" }}>{t("createTeam.fieldRequired")}</Form.Text>}
              {errors.MobileNumber?.type === "pattern" && <Form.Text style={{ color: "red" }}>{t("createTeam.ValidmobileNumber")}</Form.Text>}
            </Form.Group>

            <Form.Group className='mt-2' controlId='updateRoleID'>
              <Form.Label>{t("createTeam.role")}</Form.Label>
              <Form.Select as='select' {...register("RoleID", { required: true })} value={updateUserData.RoleID} onChange={handleInputChange}>
                <option value=''>{t("createTeam.choose")}</option>
                {roles.map((role) => (
                  <option key={role.id} value={role.id}>
                    {role.roleNameEn}
                  </option>
                ))}
              </Form.Select>
              {errors.RoleID && <Form.Text style={{ color: "red" }}>{t("createTeam.fieldRequired")}</Form.Text>}
            </Form.Group>

            <div className='newComment d-flex align-items-center justify-content-between w-100 p-3 py-4'>
              <Button variant='none' className='modalCancelButton ' onClick={handleCloseUpdateModal}>
                {t("createTeam.cancel")}
              </Button>
              <Button variant='none' onClick={updateUser} className='applyBtn '>
                {t("createTeam.updateUser")}
              </Button>
            </div>
          </Form>
        </Offcanvas.Body>
      </Offcanvas>

      {isLoading && (
        <div className='loader-overlay'>
          <Loader />
        </div>
      )}

      {/* deactivate User */}
      <Modal show={showDeactivateModal} backdrop='static' keyboard={false} onHide={closeDeactivateModal} dialogClassName='custom-modal-width'>
        <Modal.Header>
          <Modal.Title>{t("createTeam.deactivateUser")}</Modal.Title>
        </Modal.Header>
        <Modal.Body className='px-3 py-2'>
          <p>{t("createTeam.confirmDeactivate")}</p>
          <p style={{ backgroundColor: "#ccc", padding: "10px", borderRadius: "6px" }}>
            {t("createTeam.email")}: {selectedEmail}
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='none' className='modalCancelButton' onClick={closeDeactivateModal}>
            {t("createTeam.cancel")}
          </Button>
          <Button variant='none' onClick={userDeactivate} className='applyBtn'>
            {t("createTeam.confirm")}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showActiveModal} backdrop='static' keyboard={false} onHide={closeActiveModal} dialogClassName='custom-modal-width'>
        <Modal.Header>
          <Modal.Title>{t("createTeam.activateUser")}</Modal.Title>
        </Modal.Header>
        <Modal.Body className='px-3 py-2'>
          <p>{t("createTeam.confirmActivate")}</p>
          <p style={{ backgroundColor: "#ccc", padding: "10px", borderRadius: "6px" }}>
            {t("createTeam.email")}: {selectedEmail}
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='none' className='modalCancelButton' onClick={closeActiveModal}>
            {t("createTeam.cancel")}
          </Button>
          <Button variant='none' onClick={userDeactivate} className='applyBtn'>
            {t("createTeam.confirm")}
          </Button>
        </Modal.Footer>
      </Modal>

      {user.AccountType === "B" && user.RoleID === 2 && userAccess.remainingDays <= 0 ? (
        <>
          <UserLisence />
        </>
      ) : (
        <>
          {user.AccountType === "B" && user.RoleID === 1 && userAccess.remainingDays >= 0 ? (
            <div className='d-flex flex-column gap-3'>
              {/* cobranding Component*/}

              {userAccess.coBrand === true ? (
                <>
                  <CoBrandingDetails />
                </>
              ) : (
                <></>
              )}
              {userAccess.teams === true ? (
                <>
                  <Accordion defaultActiveKey='0'>
                    <Accordion.Item eventKey='2'>
                      <Accordion.Header>
                        <div className='d-flex align-items-center gap-3'>
                          <img src={usersIcon} alt='IconLoading' />
                          <label style={{ fontSize: "20px", fontWeight: "500" }}>{t("createTeam.createTeam")}</label>
                        </div>
                      </Accordion.Header>
                      <Accordion.Body>
                        <Grid>
                          <Grid item xs={12}>
                            <div>
                              <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                <h4 style={{ fontSize: "20", fontWeight: "400", margin: "" }}>{t("createTeam.MyTeam")}</h4>

                                {/* Create Team Form  if the user is admin then the team creation form will show to him*/}
                                <div>
                                  <CreateTeamForm fetchTeam={fetchTeamMembers} />
                                </div>
                              </div>

                              <TableContainer component={Paper} style={{ overflowX: "visible" }}>
                                <Table style={{ border: "none" }}>
                                  <TableHead className=''>
                                    <TableRow>
                                      {columns.map((column) => (
                                        <TableCell key={column.label} style={{ backgroundColor: "#F0F3F4" }} className='TableHeaderCell'>
                                          {column.label}
                                        </TableCell>
                                      ))}
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {searchResults.length === 0 && (
                                      <TableRow>
                                        <td colSpan='6' className='p-5 text-center'>
                                          <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                            <img alt='iconloading' src={noDocImg} style={{ height: "120px", width: "180px" }} />
                                            {t("tablesLabel.notTeamFound")}
                                          </div>
                                        </td>
                                      </TableRow>
                                    )}

                                    {searchResults.map((row, index) => (
                                      <TableRow key={index}>
                                        <TableCell className='TableCell'>{row.fullName}</TableCell>
                                        <TableCell className='TableCell'>{row.emailID}</TableCell>
                                        <TableCell className='TableCell'>{row.roleNameEn}</TableCell>

                                        <TableCell className='TableCell'>
                                          <label
                                            style={{
                                              padding: "2px 12px",
                                              borderRadius: "5px",
                                              backgroundColor: row.isActive ? "#00cc4533" : "#eeeeee",
                                              color: "black",
                                            }}
                                          >
                                            {row.isActive ? "Active" : "Inactive"}
                                          </label>
                                        </TableCell>
                                        <TableCell className='py-0'>
                                          <Dropdown>
                                            <Dropdown.Toggle as={CustomToggle} />
                                            <Dropdown.Menu>
                                              {row.isActive ? (
                                                <Dropdown.Item className='d-flex gap-2 align-items-center' onClick={() => handleDeactivate(row.emailID, row.isActive)}>
                                                  <img src={InactiveIcon} alt='iconLoading' />
                                                  {t("createTeam.deactivateUser")}
                                                </Dropdown.Item>
                                              ) : (
                                                <Dropdown.Item className='d-flex gap-2 align-items-center' onClick={() => handleActiveAgain(row.emailID, row.isActive)}>
                                                  <img src={activeIcon} alt='iconLoading' />
                                                  {t("createTeam.activateUser")}
                                                </Dropdown.Item>
                                              )}
                                              <Dropdown.Item className='d-flex gap-2 align-items-center' onClick={() => handleUpdate(row.emailID)}>
                                                <img src={editIcon} alt='icon Loading' />
                                                {t("createTeam.updateUser")}
                                              </Dropdown.Item>
                                            </Dropdown.Menu>
                                          </Dropdown>
                                        </TableCell>
                                      </TableRow>
                                    ))}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </div>
                          </Grid>
                        </Grid>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </>
              ) : (
                <></>
              )}
            </div>
          ) : (
            <></>
          )}
          <DaysToExpire />
        </>
      )}
    </div>
  );
}

export default CreateTeam;
