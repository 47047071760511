import React, { useState } from "react";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import Offcanvas from "react-bootstrap/Offcanvas";
import { CiFilter } from "react-icons/ci";
import "./style.css";
import { useTranslation } from "react-i18next";

function FolderFilter({ fromDate, toDate, folderName, docName, setFromDate, setToDate, setFolderName, setDocName, handleSearch, clearSearch }) {
  const { t } = useTranslation();

  const [isDisabled, setIsDisabled] = useState(true);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (value.trim() === "") {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }

    switch (name) {
      case "docName":
        setDocName(value);
        break;
      case "folderName":
        setFolderName(value);
        break;
      case "fromDate":
        setFromDate(value);
        break;
      case "toDate":
        setToDate(value);
        break;
      default:
        break;
    }
  };

  return (
    <Navbar expand={false}>
      <Container fluid>
        <Navbar.Toggle className='filterOpenBtn' aria-controls={`offcanvasNavbar`}>
          <CiFilter />
        </Navbar.Toggle>
        <Navbar.Offcanvas id={`offcanvasNavbar-expand-${false}`} aria-labelledby={`offcanvasNavbarLabel-expand-${false}`} placement={document.documentElement.dir === "rtl" ? "start" : "end"}>
          <Offcanvas.Header closeButton>
            <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${false}`} className='fw-bold'>
              {t("searchFilter.Filter")}
            </Offcanvas.Title>
          </Offcanvas.Header>
          <hr style={{ margin: " auto 0", borderColor: "black" }} />
          <Offcanvas.Body>
            <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
              <div>
                <label className='mb-2'>{t("searchFilter.docName")}</label>
                <input name='docName' type='text' className={`input-field ${docName ? "active" : ""}`} value={docName} onChange={handleInputChange} />
              </div>

              <div>
                <label className='mb-2'>{t("Folder.folderName")}</label>
                <input name='folderName' type='text' className={`input-field ${folderName ? "active" : ""}`} value={folderName} onChange={handleInputChange} />
              </div>

              <div>
                <label className='mb-2'>{t("searchFilter.startDate")}</label>
                <input name='fromDate' type='date' className={`input-field ${fromDate ? "active" : ""}`} value={fromDate} onChange={handleInputChange} />
              </div>
              <div>
                <label className='mb-2'>{t("searchFilter.endDate")}</label>
                <input name='toDate' type='date' className={`input-field ${toDate ? "active" : ""}`} value={toDate} onChange={handleInputChange} />
              </div>

              <div className='newComment d-flex align-items-center justify-content-between w-100 p-3 py-4'>
                <button className='resetBtn' onClick={clearSearch}>
                  {t("searchFilter.reset")}
                </button>
                <button className='applyBtn' onClick={handleSearch} disabled={isDisabled}>
                  {t("searchFilter.apply")}
                </button>
              </div>
            </div>
          </Offcanvas.Body>
        </Navbar.Offcanvas>
      </Container>
    </Navbar>
  );
}

export default FolderFilter;
