import React, { useState, useEffect } from "react";
import "./FirstStep.css";
import { BiErrorCircle } from "react-icons/bi";
import { Button, Col, Container, Form, Modal, Row, Dropdown, Accordion } from "react-bootstrap";
import axios from "../../../Api/api";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import ReferenceNumber from "./ReferenceNumber";
import Description from "./Description";
import Dropbox from "./Dropbox";
import UploadFromGoogleDrive from "./GoogleDrive";
import { useTranslation } from "react-i18next";
import { FcTemplate } from "react-icons/fc";
import { useUser } from "../../../Context/UserContext";
import pdfFileIcon from "../../../assets/images/pdfFile.svg";
import ViewFileIcon from "../../../assets/images/Show.png";
import DeleteIcon from "../../../assets/images/Delete.svg";
import DocInfoIcon from "../../../assets/images/DocumentInfo.svg";
import templateIcon from "../../../assets/images/applyTemplates.svg";
import removeTemplate from "../../../assets/images/removeTemplate.svg";
import noDocImg from "../../../assets/images/NoDocuments (1).png";
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import CustomToggle from "../../CustomDots/CustomToggle";
import ListOfDocuments from "../../../Integrations/OpenAI/ListOfDocuments";
import ManualDosList from "../../../Integrations/ManuallyGenerate/ManualDosList";
import Loader from "../../Loader/Loader";
import localIcon from "../../../assets/images/monitorCobranding.svg";
import uploadIcon from "../../../assets/images/cloud-upload.svg";
import { useDropzone } from "react-dropzone";

const FirstStep = ({ setActiveStep, setCompletedSteps }) => {
  const { t } = useTranslation();

  const [selectedFileUrl, setSelectedFileUrl] = useState("");
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [uploadErrorMessage, setUploadErrorMessage] = useState("");
  const [recipientErrorMessage, setRecipientErrorMessage] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [fileToggleStates, setFileToggleStates] = useState([]);
  const [isTemplateApplied, setIsTemplateApplied] = useState([]);
  const [registerSignID, setRegisterSignID] = useState(null);
  const [description, setDescription] = useState("");
  const [referenceNumber, setReferenceNumber] = useState("");
  const [fetchedData, setFetchedData] = useState({ primaryDocs: [] });
  const [masterDocIDs, setMasterDocIDs] = useState([]);
  const [docName, setDocName] = useState("");
  const [documentPages, setDocumentPages] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState(null);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [deletingMasterDocID, setDeletingMasterDocID] = useState(null);
  const [deletingIndex, setDeletingIndex] = useState(null);
  const [recipientFormsLength, setRecipientFormsLength] = useState(0);
  const [isSerial, setIsSerial] = useState(true);
  const navigate = useNavigate();
  const [templatesModalShow, setTemplatesModalShow] = useState(false);
  const [selectedMasterDocID, setSelectedMasterDocID] = useState();
  const [isOnlySigner, setIsOnlySigner] = useState(false);
  const [appliedTemplates, setAppliedTemplates] = useState({});
  const { user, userAccess, loading } = useUser("");
  const [totalPages, setTotalPages] = useState([]);
  const [templatesAppliedModal, setTemplatesAppliedModal] = useState(false);
  const storedRegisterSignID = localStorage.getItem("registerSignID");
  const token = sessionStorage.getItem("token");
  const [isFileUploaded, setIsFileUploaded] = useState(false);



  const handleDropboxFileSelected = (fileUrl) => {
    setSelectedFileUrl(fileUrl);
  };

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`/v1.0/api/GetRegisterSignData?RegisterSignID=${storedRegisterSignID}`, {
        headers: {
          Authorization: "Bearer " + token,
        },
      });

      if (response.data.successStatus) {
        setIsLoading(false);

        setFetchedData(response.data.response);
        setIsOnlySigner(response.data.response.isOnlySigner);

        const recipientFormsLength = response.data.response.signatories.length;
        setRecipientFormsLength(recipientFormsLength);

        // Ensure primaryDocs is an array and not undefined
        if (Array.isArray(response.data.response.primaryDocs)) {
          const uploadedDocs = response.data.response.primaryDocs.map((doc) => doc.docName);
          setUploadedFiles(uploadedDocs);

          const initialFileToggleStates = uploadedDocs.map(() => false);
          setFileToggleStates(initialFileToggleStates);

          setRegisterSignID(response.data.response.registerSignID);
          setReferenceNumber(response.data.response.referenceNumber);
          setDescription(response.data.response.description);
          setIsSerial(response.data.response.isSerial);

          setFormData(response.data.response);

          const masterDocIDs = response.data.response.primaryDocs.map((doc) => doc.masterDocID);
          setMasterDocIDs(masterDocIDs);

          setTotalPages(response.data.response.primaryDocs.map((doc) => doc.masterDocPagesCount));

          response.data.response.primaryDocs.forEach((doc) => {
            const { masterDocID, templateID } = doc;

            setAppliedTemplates((prevTemplates) => ({
              ...prevTemplates,
              [masterDocID]: templateID,
            }));
          });

          const newToggleStates = response.data.response.primaryDocs.map((doc) => doc.isPasswordProtected);
          setFileToggleStates(newToggleStates);

          const newTemplateApplied = response.data.response.primaryDocs.map((doc) => doc.isTemplateApplied);
          setIsTemplateApplied(newTemplateApplied);
        }
      }
    } catch (error) {
      if (error?.response?.data?.statusCode === "2" || error?.response?.data?.statusCode === "4") {
        sessionStorage.removeItem("token");
        localStorage.clear();
        setIsLoading(false);
        navigate("/");
        toast.info("session expired");
      }
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (storedRegisterSignID !== null) {
      fetchData();
    }
  }, [storedRegisterSignID, userAccess]);

  const handleFileUpload = async (files) => {
    setIsLoading(true);
    const newFiles = Array.from(files);

    const allowedFileTypes = ["application/pdf", "application/msword", "application/vnd.openxmlformats-officedocument.wordprocessingml.document"];

    const validFiles = newFiles.filter((file) => allowedFileTypes.includes(file.type));

    if (validFiles.length === 0) {
      toast.error("Only pdf and docs are allowed");
      setIsLoading(false);
    } else {
      setUploadErrorMessage("");
      const base64Files = [];

      for (const file of validFiles) {
        const reader = new FileReader();

        reader.onload = async (e) => {
          const base64Data = e.target.result.split(",")[1];
          base64Files.push({
            name: file.name,
            base64: base64Data,
            MasterDocID: null,
          });

          if (base64Files.length === validFiles.length) {
            const uploadedFileNames = uploadedFiles.map((fileName) => fileName);

            const duplicateFiles = base64Files.filter((uploadedFile) => uploadedFileNames.includes(uploadedFile.name));

            if (duplicateFiles.length > 0) {
              setUploadErrorMessage(t("uploadDocFlow.filewithSameNameExits"));
              toast.error(t("uploadDocFlow.filewithSameNameExits"));
              setIsLoading(false);
            } else {
              const body = {
                IsBulk: true,
                ReferenceNumber: referenceNumber,
                Description: description,
                CreatedBy: user.email,
                IsSerial: true,
                isOnlySigner: false,
                registerSignID: registerSignID,
                PrimaryDocs: base64Files.map((uploadedFile) => {
                  const fileExtension = uploadedFile.name.split(".").pop();

                  return {
                    DocName: uploadedFile.name,
                    DocExtension: fileExtension,
                    DocFile: uploadedFile.base64,
                    IsPasswordProtected: false,
                  };
                }),
              };

              try {
                const response = await axios.post("/v1.0/api/RegisterDocs", body, {
                  headers: {
                    Authorization: "Bearer " + token,
                  },
                });

                if (response.data.statusCode === "1") {
                  setRegisterSignID(response.data.response.registerSignID);
                  localStorage.setItem("registerSignID", response.data.response.registerSignID);

                  const MasterDocId = response.data.response.masterDocs[0].masterDocIDs;
                  setMasterDocIDs((prevIDs) => [...prevIDs, MasterDocId]);

                  const pages = response.data.response.totalPages;
                  setTotalPages((prevPages) => [...prevPages, pages]);

                  setUploadedFiles([...uploadedFiles, ...base64Files.map((uploadedFile) => uploadedFile.name)]);

                  setFileToggleStates((prevStates) => [...prevStates, ...base64Files.map(() => false)]);

                  setIsTemplateApplied((prevStates) => [...prevStates, ...base64Files.map(() => false)]);

                  setUploadErrorMessage("");
                  setSelectedFile(null);
                  toast.success(t("uploadDocFlow.fileuploadSuccessfully"));
                  // handleGetTemplate(MasterDocId, pages, 0, false);

                  setIsFileUploaded(true);
                  setIsLoading(false);
                }
              } catch (error) {
                setUploadErrorMessage(t("uploadDocFlow.errorUploadFile"));
                toast.error(t("uploadDocFlow.errorUploadFile"), {
                  position: "top-right",
                });
                setIsLoading(false);
              }
            }
          }
        };

        reader.readAsDataURL(file);

        reader.onerror = (error) => {
          setIsLoading(false);
        };
      }
    }
  };

  useEffect(() => {
    if (uploadedFiles.length > 0) {
      setIsFileUploaded(true);
    } else {
      setIsFileUploaded(false);
    }
  }, [uploadedFiles]);

  const handleFileview = async (masterDocID, fileName) => {
    setIsLoading(true);
    try {
      const response = await axios.get(`/v1.0/api/GetMasterDocPages?MasterDocID=${masterDocID}`, {
        headers: {
          Authorization: "Bearer " + token,
        },
      });

      if (response.data.successStatus) {
        const pages = response.data.response;
        const pagesMap = {};
        pages.forEach((page) => {
          pagesMap[page.pagesName] = page;
        });
        setDocumentPages(pagesMap);
        setDocName(fileName);
        setShowModal(true);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      if (error?.response?.data?.statusCode == 2 || error?.response?.data?.statusCode == 4) {
        sessionStorage.removeItem("token");
        localStorage.clear();
        setIsLoading(false);
        navigate("/");
        toast.info(t("uploadDocFlow.sessionExpire"), {
          position: "top-right",
        });
      } else {
        toast.error(t("uploadDocFlow.filecantView"));
      }
    }
    setIsLoading(false);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleShowConfirmModal = (masterDocID, index) => {
    setDeletingMasterDocID(masterDocID);
    setDeletingIndex(index);
    setShowConfirmModal(true);
  };

  const handleCloseConfirmModal = () => {
    setShowConfirmModal(false);
  };

  const handleFileDelete = async (masterDocID, index) => {
    setIsLoading(true);
    try {
      const response = await axios.put(
        "/v1.0/api/DeleteMasterDoc",
        {
          MasterDocID: masterDocID,
        },
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );

      if (response.data.successStatus) {
        const updatedUploadedFiles = [...uploadedFiles];
        updatedUploadedFiles.splice(index, 1);
        setUploadedFiles(updatedUploadedFiles);

        const updatedMasterDocIDs = [...masterDocIDs];
        updatedMasterDocIDs.splice(index, 1);
        setMasterDocIDs(updatedMasterDocIDs);

        setIsLoading(false);
      }

      toast.success(t("uploadDocFlow.fileDeleted"));
    } catch (error) {
      if (error?.response?.data?.statusCode == 2 || error?.response?.data?.statusCode == 4) {
        sessionStorage.removeItem("token");
        localStorage.clear();
        setIsLoading(false);
        navigate("/");
        toast.info(t("uploadDocFlow.sessionExpire"), {
          position: "top-right",
        });
      } else {
        toast.error(t("uploadDocFlow.errorDeletingFiles"));
      }
      setIsLoading(false);
    } finally {
      handleCloseConfirmModal();
      setIsLoading(false);
    }
  };



  const ReferenceDescription = async () => {
    const updatedRegDetails = {
      RegisterSignID: registerSignID,
      ReferenceNumber: referenceNumber,
      Description: description,
      IsSerial: isSerial,
      isOnlySigner: isOnlySigner,
    };

    try {
      const response = await axios.put("/v1.0/api/UpdateRegisterDetails", updatedRegDetails, {
        headers: {
          Authorization: "Bearer " + token,
        },
      });
    } catch (error) {
      if (error?.response?.data?.statusCode == 2 || error?.response?.data?.statusCode == 4) {
        sessionStorage.removeItem("token");
        localStorage.clear();
        setIsLoading(false);
        navigate("/");
        toast.info(t("uploadDocFlow.sessionExpire"), {
          position: "top-right",
        });
      }
    }
  };

  const handleContinue = async () => {
    ReferenceDescription();
    setActiveStep(2);
    setCompletedSteps([1]);
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: ".pdf, .doc, .docx",
    onDrop: handleFileUpload,
    multiple: true,
  });

  return (
    <>
      <Container className='upload-container'>
        <div style={{ marginTop: "1rem" }}>
          <Row
            className='justify-content-center m-0'
            style={{
              border: "3px dashed #0C9AD7",
              padding: "40px",
              borderRadius: "10px",
              background: "#ffffff",
              gap: "5px",
            }}
            {...getRootProps()}
          >
            <div className='drag-file'>
              <img src={uploadIcon} alt='Upload icon' />
              <label style={{ fontSize: "18px", fontWeight: "400" }}>{t("uploadDocFlow.easilyUpload")}</label>
            </div>
            <Col md={3}>
              <div className='uplad_box'>
                <Form.Group controlId='file-input-add' className='file-upload_temp'>
                  <div
                    {...getRootProps({
                      className: "upload_trigger_bx",
                      onClick: (e) => {
                        e.stopPropagation();
                      },
                    })}
                  >
                    <img alt='iconLoading' src={localIcon} />
                    <Form.Label>{t("uploadDocFlow.local")}</Form.Label>
                    <Form.Control
                      {...getInputProps()}
                      className='temp_file-input-additional'
                      type='file'
                      name='file'
                      accept='.pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx'
                      multiple
                      style={{ display: "none" }}
                    />
                    <div className='upload_doc_part'></div>
                  </div>
                </Form.Group>
              </div>
            </Col>

            {user.isDropbox &&
              (userAccess.dropbox ? (
                <Col md={3}>
                  <Dropbox
                    onFileSelected={handleDropboxFileSelected}
                    uploadedFiles={uploadedFiles}
                    setUploadedFiles={setUploadedFiles}
                    masterDocIDs={masterDocIDs}
                    setMasterDocIDs={setMasterDocIDs}
                    fileToggleStates={fileToggleStates}
                    setFileToggleStates={setFileToggleStates}
                    registerSignID={registerSignID}
                    setRegisterSignID={setRegisterSignID}
                    email={user.email}
                  />
                </Col>
              ) : null)}

            {user.isGoogleDrive &&
              (userAccess.googDrive ? (
                <Col md={3}>
                  <UploadFromGoogleDrive
                    uploadedFiles={uploadedFiles}
                    setUploadedFiles={setUploadedFiles}
                    masterDocIDs={masterDocIDs}
                    setMasterDocIDs={setMasterDocIDs}
                    fileToggleStates={fileToggleStates}
                    setFileToggleStates={setFileToggleStates}
                    registerSignID={registerSignID}
                    setRegisterSignID={setRegisterSignID}
                    email={user.email}
                  />
                </Col>
              ) : null)}
          </Row>

          {uploadErrorMessage && (
            <p className='error-message'>
              <BiErrorCircle className='mb-1 mx-2' />
              {uploadErrorMessage}
            </p>
          )}

          {loading ||
            (isLoading && (
              <div className='loader-overlay'>
                <Loader />
              </div>
            ))}

          <div className='upload_file_box mt-3' id='upload_file_bx '>
            {uploadedFiles.map((fileName, index, file) => (
              <div key={index} className='uploaded-file d-flex align-items-center justify-content-between px-3 border-bottom'>
                <p className='m-0 d-flex align-items-center gap-2'>
                  <img src={pdfFileIcon} alt='iconLoading' />
                  {fileName} {isTemplateApplied[index] && <FcTemplate size={18} className='template-hover' />}
                </p>

                <div className='d-flex align-items-center'>
                  <Dropdown>
                    <Dropdown.Toggle as={CustomToggle} />
                    <Dropdown.Menu>
                      <Dropdown.Item className='d-flex gap-2 align-items-center' onClick={() => handleFileview(masterDocIDs[index], fileName)}>
                        <img src={ViewFileIcon} alt='iconLoading' />
                        {t("uploadDocFlow.view")}
                      </Dropdown.Item>

                      <Dropdown.Item className='d-flex gap-2 align-items-center' onClick={() => handleShowConfirmModal(masterDocIDs[index], index)}>
                        <img src={DeleteIcon} alt='iconLoading' />
                        {t("uploadDocFlow.delete")}
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>

                  <Modal show={templatesAppliedModal} onHide={() => setTemplatesAppliedModal(false)} dialogClassName='custom-modal-width'>
                    <Modal.Header closeButton>
                      <Modal.Title>{t("uploadDocFlow.saveTemplate")}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className='px-3 py-2'>{t("uploadDocFlow.matchingTemplates")}</Modal.Body>
                    <Modal.Footer>
                      <Button
                        variant='none'
                        className='applyBtn'
                        onClick={() => {
                          setTemplatesAppliedModal(false);

                          setTemplatesModalShow(true);
                        }}
                      >
                        {t("uploadDocFlow.yesProceed")}
                      </Button>
                    </Modal.Footer>
                  </Modal>
                </div>
              </div>
            ))}
          </div>

          <Modal show={showConfirmModal} onHide={handleCloseConfirmModal} dialogClassName='custom-modal-width'>
            <Modal.Header>
              <Modal.Title>{t("uploadDocFlow.confirmDelete")}</Modal.Title>
            </Modal.Header>
            <Modal.Body className='px-3 py-2'>{t("uploadDocFlow.deleteFile")}</Modal.Body>
            <Modal.Footer>
              <Button variant='none' onClick={handleCloseConfirmModal} className='modalCancelButton'>
                {t("uploadDocFlow.cancel")}
              </Button>
              <Button variant='none' className='applyBtn' onClick={() => handleFileDelete(deletingMasterDocID, deletingIndex)}>
                {t("uploadDocFlow.delete")}
              </Button>
            </Modal.Footer>
          </Modal>

          <Modal show={showModal} onHide={handleCloseModal}>
            <Modal.Header className='btn'>
              <Modal.Title>{docName}</Modal.Title>
            </Modal.Header>
            <Modal.Body className='d-flex'>
              <Col>
                {isLoading ? (
                  <div className='loader d-flex justify-content-center  align-items-center'>Loading...</div>
                ) : (
                  Object.values(documentPages).map((page, index) => (
                    <div key={index} style={{ position: "relative" }}>
                      <img src={page.pagesPath} alt={page.pagesName} style={{ width: "100%", height: "auto" }} />
                      <div className='page-name-footer'>{page.pagesName}</div>
                    </div>
                  ))
                )}
              </Col>
            </Modal.Body>
          </Modal>
        </div>

        {fetchedData && (
          <div className='p-0 my-3'>
            <Accordion defaultActiveKey='0'>
              <Accordion.Item eventKey='0'>
                <Accordion.Header>
                  <div className='d-flex align-items-center gap-3'>
                    <img src={DocInfoIcon} alt='IconLoading' />
                    <label style={{ fontSize: "20px", fontWeight: "500" }}>{t("uploadDocFlow.docInfo")}</label>
                  </div>
                </Accordion.Header>
                <Accordion.Body className='p-3'>
                  <Row className='px-0'>
                    <Col md={6}>
                      <ReferenceNumber fetchedData={fetchedData} setFetchedData={setFetchedData} setReferenceNumber={setReferenceNumber} />
                    </Col>
                    <Col md={6}>
                      <Description fetchedData={fetchedData} setFetchedData={setFetchedData} setDescription={setDescription} />
                    </Col>
                  </Row>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        )}

        {recipientErrorMessage && (
          <p className='error-message'>
            <BiErrorCircle className='mb-1 ms-5 me' />
            {recipientErrorMessage}
          </p>
        )}

        <div className='step_button_holder mt-3'>
          <Button className='mb-3 Continue-btn' id='hide_part_one' onClick={handleContinue} disabled={!isFileUploaded}>
            {t("uploadDocFlow.Continue")}
          </Button>
        </div>

        <div style={{ display: "none" }}>
          <Col md={4}>
            <ListOfDocuments
              uploadedFiles={uploadedFiles}
              setUploadedFiles={setUploadedFiles}
              masterDocIDs={masterDocIDs}
              setMasterDocIDs={setMasterDocIDs}
              fileToggleStates={fileToggleStates}
              setFileToggleStates={setFileToggleStates}
              registerSignID={registerSignID}
              setRegisterSignID={setRegisterSignID}
              email={user.email}
            />
          </Col>
        </div>

        <div style={{ display: "none" }}>
          <Col md={4}>
            <ManualDosList
              uploadedFiles={uploadedFiles}
              setUploadedFiles={setUploadedFiles}
              masterDocIDs={masterDocIDs}
              setMasterDocIDs={setMasterDocIDs}
              fileToggleStates={fileToggleStates}
              setFileToggleStates={setFileToggleStates}
              registerSignID={registerSignID}
              setRegisterSignID={setRegisterSignID}
              email={user.email}
            />
          </Col>
        </div>
      </Container>
    </>
  );
};

export default FirstStep;
