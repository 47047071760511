import React, { useState } from "react";
import useDrivePicker from "react-google-drive-picker";
import axios from "../../../Api/api";
import { Form } from "react-bootstrap";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import Loader from "../../Loader/Loader";
import googleDriveIcon from "../../../assets/images/googleDriveCobranding.svg";

function UploadFromGoogleDrive({ uploadedFiles, setUploadedFiles, masterDocIDs, setMasterDocIDs, fileToggleStates, setFileToggleStates, registerSignID, setRegisterSignID, email }) {
  const { t } = useTranslation();
  const [openPicker] = useDrivePicker();
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [base64Data, setBase64Data] = useState(null);
  const [selectedFileName, setSelectedFileName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [uploadErrorMessage, setUploadErrorMessage] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [isSerial, setIsSerial] = useState(true);
  const [description, setDescription] = useState("");
  const [referenceNumber, setReferenceNumber] = useState("");
  const client_Id = process.env.REACT_APP_GOOGLE_CLIENT_ID;
  const developer_Key = process.env.REACT_APP_GOOGLE_DEVELOPER_KEY;
  const token = sessionStorage.getItem("token");

  const handleOpenPicker = () => {
    alert("The file you want to access must be public...");

    openPicker({
      clientId: client_Id,
      developerKey: developer_Key,
      viewId: "DOCS",
      showUploadView: false,
      showUploadFolders: false,
      supportDrives: true,
      multiselect: false,
      callbackFunction: (data) => {
        if (data.action === "picked") {
          setSelectedFiles(data.docs);
          setSelectedFileName(data.docs[0].name);
          convertToBase64(data.docs[0].id, data.docs[0].name);
        } else if (data.action === "cancel") {
          toast.info(t("User clicked cancel/close button"));
        }
      },
    });
  };
  const convertToBase64 = async (fileId, fileName) => {
    setIsLoading(true);
    try {
      const apiUrl = `https://www.googleapis.com/drive/v3/files/${fileId}?alt=media&key=${developer_Key}`;
      const response = await axios.get(apiUrl, { responseType: "arraybuffer" });
      if (response.status === 200) {
        const arrayBuffer = response.data;
        var new_blob = new Blob([arrayBuffer], { type: "application/pdf" });
        const reader = new FileReader();
        reader.readAsDataURL(new_blob);
        reader.onloadend = () => {
          const base64data = reader.result.split(",")[1];
          setBase64Data(base64data);
          handleFileUpload(base64data, fileName);
        };
      } else {
        console.error(`Failed to fetch the PDF. Status code: ${response.status}`);
        toast.error(t("uploadDocFlow.errorUploadFile"));
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Error:", error.message);
      toast.error(t("uploadDocFlow.errorUploadFile"));
      setIsLoading(false);
    }
  };

  const handleFileUpload = async (base64data, fileName) => {
    const base64Files = [];
    base64Files.push({
      name: fileName,
      base64: base64data,
      MasterDocID: null,
    });

    const body = {
      ReferenceNumber: referenceNumber,
      Description: description,
      CreatedBy: email,
      IsSerial: isSerial,
      isOnlySigner: false,
      registerSignID: registerSignID,
      PrimaryDocs: base64Files.map((uploadedFile) => {
        const fileExtension = fileName.split(".").pop();

        return {
          DocName: uploadedFile.name,
          DocExtension: fileExtension,
          DocFile: uploadedFile.base64,
          IsPasswordProtected: false,
        };
      }),
    };

    try {
      const response = await axios.post("/v1.0/api/RegisterDocs", body, {
        headers: {
          Authorization: "Bearer " + token,
        },
      });

      if (response.data.successStatus) {
        setRegisterSignID(response.data.response.registerSignID);
        localStorage.setItem("registerSignID", response.data.response.registerSignID);

        setMasterDocIDs((prevIDs) => [...prevIDs, response.data.response.masterDocs[0].masterDocIDs]);

        setUploadedFiles([...uploadedFiles, ...base64Files.map((uploadedFile) => uploadedFile.name)]);

        setFileToggleStates((prevStates) => [...prevStates, ...base64Files.map(() => false)]);

        setUploadErrorMessage("");
        setSelectedFile(null);
        toast.success(t("uploadDocFlow.fileuploadSuccessfully"));

        setIsLoading(false);
      } else {
        setUploadErrorMessage(t("uploadDocFlow.apireqFailed"));
        toast.error(t("uploadDocFlow.errorUploadFile"));
        setIsLoading(false);
      }
    } catch (error) {
      setUploadErrorMessage(t("uploadDocFlow.errorUploadFile"));
      toast.error(t("uploadDocFlow.errorUploadFile"));

      setIsLoading(false);
    }
    setIsLoading(false);
  };

  return (
    <div>
      {isLoading && (
        <div className='loader-overlay'>
          <Loader />
        </div>
      )}

      <div className='uplad_box' onClick={handleOpenPicker}>
        <div className='upload_trigger_bx'>
          <img alt='iconLoading' src={googleDriveIcon} />
          <div className='upload_doc_part'>
            <Form.Group controlId='file-input-add' className='file-upload_temp'>
              <Form.Label>{t("uploadDocFlow.googleDrive")}</Form.Label>
              <Form.Control className='temp_file-input-additional' name='file' accept='.pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx' onClick={handleOpenPicker} multiple />
            </Form.Group>
          </div>
        </div>
      </div>
    </div>
  );
}
export default UploadFromGoogleDrive;
