import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "../Api/api";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const UserActivityStatus = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isLoggedOut, setIsLoggedOut] = useState(false);

  const handleLogout = async () => {
    try {
      const token = sessionStorage.getItem("token");
      if (!token) return;

      const response = await axios.put("/Account/Logout", null, {
        headers: {
          Authorization: "Bearer " + token,
        },
      });

      if (response.data.statusCode === "1") {
        sessionStorage.clear();
        localStorage.clear();
        setIsLoggedOut(true);
        navigate("/");
        toast.info(t("uploadDocFlow.sessionExpire"));
      }
    } catch (error) {
      console.log(error, t("menuButtons.errLogoutOut"));
    }
  };

  const updateActivityTime = () => {
    const currentTime = new Date().getTime();
    sessionStorage.setItem("lastActivityTime", currentTime.toString());
  };

  const checkInactivity = () => {
    const lastActivityTime = sessionStorage.getItem("lastActivityTime");
    const currentTime = new Date().getTime();
    const inactivityPeriod = 30 * 60 * 1000;

    if (lastActivityTime && currentTime - parseInt(lastActivityTime) > inactivityPeriod) {
      handleLogout();
    }
  };

  useEffect(() => {
    const activityEvents = ["mousemove", "keydown"];
    activityEvents.forEach((event) => {
      window.addEventListener(event, updateActivityTime);
    });

    updateActivityTime();
    const intervalId = setInterval(checkInactivity, 1000);

    return () => {
      activityEvents.forEach((event) => {
        window.removeEventListener(event, updateActivityTime);
      });
      clearInterval(intervalId);
    };
  }, [isLoggedOut, navigate]);

  return null;
};

export default UserActivityStatus;
