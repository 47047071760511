import React, { useState, useEffect, useRef } from "react";
import JoditEditor from "jodit-react";
import { Button, Col, Form, Row, Modal } from "react-bootstrap";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "../../Api/api";
import { useTranslation } from "react-i18next";
import rectangleBox from "../../assets/images/rectangle.png";
import Loader from "../Loader/Loader";
import { useUser } from "../../Context/UserContext";

import "./style.css";
import UserLisence from "../UserLisence/UserLisence";

const CreateEmailTemplate = () => {
  const { t } = useTranslation();
  const [emailSubject, setEmailSubject] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [selectedTemplateText, setSelectedTemplateText] = useState("");
  const [emailBody, setEmailBody] = useState("");
  const [templateType, setTemplateType] = useState("");
  const editorRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  const { user, userAccess } = useUser();
  const token = sessionStorage.getItem("token");
  const emailTemplateID = location.state?.template?.emailTemplateID;

  useEffect(() => {
    if (location.state?.template) {
      const { subject, templateType, body } = location.state.template;
      setEmailSubject(subject);
      setTemplateType(templateType);
      setEmailBody(body || "");
    } else {
      setEmailSubject("");
      setTemplateType("");
      setEmailBody("");
    }
  }, [location.state]);

  const handleCancel = () => {
    setEmailSubject("");
    setTemplateType("");
    setEmailBody("");
    navigate("/email-templates");
  };

  const templatePlaceholders = {
    Declined: ["{name}", "{declinedBy}", "{declinedDate}", "{declineReason}", "{documents}"],
    Completed: ["{name}", "{documents}", "{referenceNumber}"],
    PasswordReset: ["{name}"],
    CreateTeam: ["{name}", "{password}"],
    SigningDocument: ["{name}", "{documents}", "{sentBy}", "{sentOn}", "{referenceNumber}"],
  };

  const templateContentMapping = {
    Declined: `
    <table style="padding: 20px; width: 100%; border-collapse: collapse;">
      <tr>
        <td>
          <p style="font-size: 16px; line-height: 1.5;">${t("emailTemplates.dear")} <span class="immutable-placeholder">name</span>,</p>
          <p>${t("emailTemplates.declinedMessage", {
            declinedBy: "<span class='immutable-placeholder'>declinedBy</span>",
            declinedDate: "<span class='immutable-placeholder'>declinedDate</span>",
            declineReason: "<span class='immutable-placeholder'>declineReason</span>",
          })}</p>
          <p><strong>${t("emailTemplates.documentDetails")}:</strong></p>
          <ul>
            <li>${t("emailTemplates.documentName")}: <span class="immutable-placeholder">documents</span></li>
          </ul>
        </td>
      </tr>
    </table>
  `,
    Completed: `
    <table style="padding: 20px; width: 100%; border-collapse: collapse;">
      <tr>
        <td>
          <p style="font-size: 16px; line-height: 1.5;">${t("emailTemplates.dear")} <span class="immutable-placeholder">name</span>,</p>
          <p>${t("emailTemplates.signedMessage")}</p>
          <p><strong>${t("emailTemplates.documentDetails")}:</strong></p>
          <ul>
            <li>${t("emailTemplates.documentName")}: <span class="immutable-placeholder">documents</span></li>
            <li>${t("emailTemplates.referenceNumber")}: <span class="immutable-placeholder">referenceNumber</span></li>
          </ul>
        </td>
      </tr>
    </table>
  `,
    PasswordReset: `
    <table style="padding: 20px; width: 100%; border-collapse: collapse;">
      <tr>
        <td>
          <p style="font-size: 16px; line-height: 1.5;">${t("emailTemplates.dear")},</p>
          <p>${t("emailTemplates.resetPasswordMessage")}</p>
        </td>
      </tr>
    </table>
  `,
    CreateTeam: `
    <table style="padding: 20px; width: 100%; border-collapse: collapse;">
      <tr>
        <td>
          <p style="font-size: 16px; line-height: 1.5;">${t("emailTemplates.dear")} <span class="immutable-placeholder">name</span>,</p>
          <p>${t("emailTemplates.newAccountMessage", {
            password: "<span class='immutable-placeholder'>password</span>",
          })}</p>
          <p>${t("emailTemplates.updatePasswordMessage")}</p>
        </td>
      </tr>
    </table>
  `,
    SigningDocument: `
    <table style="padding: 20px; width: 100%; border-collapse: collapse;">
      <tr>
        <td>
          <p style="font-size: 16px; line-height: 1.5;">${t("emailTemplates.dear")} <span class="immutable-placeholder">name</span>,</p>
          <p>${t("emailTemplates.invitedToSign")}</p>
          <p><strong>${t("emailTemplates.documentDetails")}:</strong></p>
          <ul>
            <li>${t("emailTemplates.documentName")}: <span class="immutable-placeholder">documents</span></li>
            <li>${t("emailTemplates.sentBy")}: <span class="immutable-placeholder">sentBy</span></li>
            <li>${t("emailTemplates.sentOn")}: <span class="immutable-placeholder">sentOn</span></li>
            <li>${t("emailTemplates.referenceNumber")}: <span class="immutable-placeholder">referenceNumber</span></li>
          </ul>
        </td>
      </tr>
    </table>
  `,
  };

  const handleTemplateTypeChange = (e) => {
    const selectedType = e.target.value;
    setTemplateType(selectedType);

    // Get the template content based on the selected type
    const templateContent = templateContentMapping[selectedType] || "";
    setSelectedTemplateText(templateContent);

    // Generate the modal message dynamically
    const placeholders = templatePlaceholders[selectedType] || [];
    const placeholdersText = placeholders.join(", ");
    setModalMessage(
      t("emailTemplates.defaultTemplateMessage", {
        placeholders: placeholdersText,
      })
    );

    // Show the modal
    setShowModal(true);
  };

  const handleModalGotIt = () => {
    setShowModal(false);
    setEmailBody(selectedTemplateText);
  };

  const ModalClose = () => {
    setShowModal(false);
  };

  const restorePlaceholders = (emailBody) => {
    return emailBody.replace(/<span class="immutable-placeholder"[^>]*>(.*?)<\/span>/g, (match, placeholder) => {
      return `{${placeholder.trim()}}`;
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const processedBody = restorePlaceholders(emailBody);

    const requestBody = {
      EmailTemplateID: emailTemplateID || null,
      AccountID: user.AccountID,
      TemplateType: templateType,
      Subject: emailSubject,
      Body: processedBody,
    };
    setIsLoading(true);
    try {
      const response = await axios.post("/v1.0/api/UpsertAccountEmailTemplate", requestBody, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.data.statusCode === "1") {
        toast.success(emailTemplateID ? t("emailTemplates.templateUpdated") : t("emailTemplates.templateCreated"));
        navigate("/email-templates");
      }
    } catch (error) {
      toast.error(t("emailTemplates.emailError"));
      console.error("Error", error);
    } finally {
      setIsLoading(false);
    }
  };

  const isContentNotEmpty = (content) => {
    // Remove HTML tags and trim whitespace
    const plainText = content.replace(/<\/?[^>]+(>|$)/g, "").trim();
    return plainText.length > 0;
  };

  const isEmailTemplateValid = emailSubject.trim() !== "" && templateType.trim() !== "" && isContentNotEmpty(emailBody);

  return (
    <>
      {userAccess.remainingDays <= 0 ? (
        <UserLisence />
      ) : (
        <>
          {" "}
          {user.AccountType === "B" && user.RoleID === 1 && userAccess.remainingDays >= 0 ? (
            <>
              <div className='container mt-3'>
                {isLoading && (
                  <div className='loader-overlay'>
                    <Loader />
                  </div>
                )}
                <Row className='my-3 gap-4 justify-content-center'>
                  <Col md={3}>
                    <div style={{ position: "relative", display: "inline-block" }}>
                      <img src={rectangleBox} alt='Rectangle' />
                      <div className='MACACIIMAGE'>
                        <div>
                          <label>{t("emailTemplates.letCreateEmail")}</label>
                          <br />
                          <label>{t("macAi.seeHistory")}</label>
                          <Link className='mx-1' to='/email-templates'>
                            <label className='AidocumentBtn'>{t("macAi.gotoList")}</label>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>

                <Form onSubmit={handleSubmit}>
                  <div className='userProfile-form'>
                    <Row>
                      <Col md={6}>
                        <Form.Group className='mb-3'>
                          <Form.Label>
                            {t("emailTemplates.templateType")}
                            <span className='required mx-1'>*</span>
                          </Form.Label>
                          <Form.Select value={templateType} disabled={!!emailTemplateID} onChange={handleTemplateTypeChange} required>
                            <option value='' disabled>
                              {t("emailTemplates.SelectType")}
                            </option>
                            <option value='Declined'>{t("emailTemplates.Declined")}</option>
                            <option value='Completed'>{t("emailTemplates.Completed")}</option>
                            <option value='PasswordReset'>{t("emailTemplates.PasswordReset")}</option>
                            <option value='CreateTeam'>{t("emailTemplates.Createteam")}</option>
                            <option value='SigningDocument'>{t("emailTemplates.SigningDocument")}</option>
                          </Form.Select>
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group className='mb-3'>
                          <Form.Label>
                            {t("emailTemplates.emailSubject")} <span className='required mx-1'>*</span>
                          </Form.Label>
                          <Form.Control type='text' value={emailSubject} onChange={(e) => setEmailSubject(e.target.value)} placeholder='Enter email subject' required />
                        </Form.Group>
                      </Col>
                    </Row>
                  </div>

                  <div className='userProfile-form mt-3'>
                    <Form.Group className='mb-3'>
                      <Form.Label>
                        {t("emailTemplates.emailBody")}
                        <span className='required mx-1'>*</span>
                      </Form.Label>
                      <JoditEditor
                        value={emailBody}
                        config={{
                          readonly: false,
                          toolbarSticky: false,
                          buttons: ["bold", "italic", "underline", "|", "ul", "ol", "|", "align", "|", "undo", "redo", "|", "link", "|", "font", "fontsize"],
                        }}
                        onBlur={(newContent) => setEmailBody(newContent)}
                      />
                    </Form.Group>
                  </div>

                  {isEmailTemplateValid && (
                    <div className='d-flex gap-3 my-3'>
                      <Button variant='none' className='ImageDoneBtn' type='submit'>
                        {emailTemplateID ? t("emailTemplates.updateTemplate") : t("emailTemplates.createTemplate")}
                      </Button>
                      <Button variant='none' className='ImageCancelBtn' onClick={handleCancel}>
                        {t("userProfile.cancel")}
                      </Button>
                    </div>
                  )}
                </Form>

                <Modal show={showModal} onHide={() => setShowModal(false)} backdrop='static' keyboard={false} dialogClassName='custom-modal-width'>
                  <Modal.Header>
                    <Modal.Title>{t("emailTemplates.defaultTemplateTitle")}</Modal.Title>
                  </Modal.Header>
                  <Modal.Body className='p-3'>
                    <p>{modalMessage}</p>
                    <div
                      style={{
                        padding: "10px",
                        borderRadius: "5px",
                        border: "1px solid #ddd",
                      }}
                      dangerouslySetInnerHTML={{ __html: selectedTemplateText }}
                    ></div>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button className='modalCancelButton' onClick={ModalClose}>
                      {t("dashboardCardStatuses.cancel")}
                    </Button>
                    <Button className='applyBtn' onClick={handleModalGotIt}>
                      {t("emailTemplates.gotIt")}
                    </Button>
                  </Modal.Footer>
                </Modal>
              </div>
            </>
          ) : null}
        </>
      )}
    </>
  );
};

export default CreateEmailTemplate;
