import React from "react";
import { useTranslation } from "react-i18next";
import Carousel from "react-bootstrap/Carousel";
import "./styleCursuel.css";
import arabicPep from "../../assets/images/shuttle.png";
import lockImg from "../../assets/images/Group 11747.png";
import KeyImg from "../../assets/images/Group 11871.png";

const ForgotCarousel = () => {
  const { t } = useTranslation();
  return (
    <Carousel data-bs-theme='dark' controls={false}>
      <Carousel.Item>
        <div className='carousel-content'>
          <img src={KeyImg} alt='imageLoading' style={{ height: "50px", width: "50px", marginLeft: "-50px", marginRight: "-50px" }} />
          <div>
            <label className='fw-bolder  mb-2 carsol-desc'>{t("carousel.passRecovery")}</label>
            <br />
            <label className='carousel-label mb-2'>{t("carousel.passRecovery")}</label>
            <label className='carousel-paragraph'>{t("carousel.experinceconvenience")}</label>
          </div>

          <div style={{ display: "flex", justifyContent: "end", gap: "4rem", marginTop: "1rem" }}>
            {/* <div style={{ display: "flex", flexDirection: "column", gap: "10rem" }}>
            </div> */}
            <img src={arabicPep} alt='imageLoading' className='carousel-image' />
            <img src={lockImg} alt='imageLoading' className='lockImgStyle' />
          </div>
        </div>
      </Carousel.Item>
    </Carousel>
  );
};

export default ForgotCarousel;
