import React from "react";
import { Nav } from "react-bootstrap";
import { AiOutlineDelete } from "react-icons/ai";
import { FaRegFileAlt } from "react-icons/fa";
import { IoAddCircleOutline } from "react-icons/io5";
import { TbFileOff } from "react-icons/tb";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const TemplatesStatuses = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const handleCreateTemplate = () => {
    localStorage.removeItem("templateID");
    navigate("/createTemplates");
  };
  return (
    <>
      <div className='createTemplate'>
        <button onClick={handleCreateTemplate} className='templateButton'>
          {t("templateUploadFlow.createTemplate")}
        </button>
      </div>

      {/* <Nav className=' ms-auto d-flex justify-content-center align-items-center  mb-4 '>

                <>
                    <Nav.Link as={Link} to='/createTemplates' className='card pending-card active' onClick={handleCreateTemplate}>
                        <h5> Create New</h5>
                        <span className='card-icon'>
                            <IoAddCircleOutline size={22} />
                        </span>
                    </Nav.Link>
                    <Nav.Link as={Link} to='/templatesPage' className='card drafts-card '>
                        <h5> Active </h5>
                        <span className='card-icon'>
                            <FaRegFileAlt size={22} />
                        </span>
                    </Nav.Link>
                    <Nav.Link as={Link} to='/inactiveTemplates' className='card drafts-card'>
                        <h5> Inactive</h5>
                        <span className='card-icon'>
                            <AiOutlineDelete size={22} />66
                        </span>
                    </Nav.Link>
                    <Nav.Link as={Link} to='/deactivatedTemplates' className='card completed-card '>
                        <h5>Inactive</h5>
                        <span className='card-icon'>
                            <TbFileOff size={22} />
                        </span>
                    </Nav.Link>

                </>

            </Nav> */}
    </>
  );
};

export default TemplatesStatuses;
