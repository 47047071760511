import React, { useState } from "react";
import { Chips } from "primereact/chips";
import { Button, Row, Col } from "react-bootstrap";
import axios from "../../../Api/api";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import "./style.css";
import CCLoader from "../../Loader/CCLoader";

const CCEmail = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false); // Fix here

  const [items, setItems] = useState([]);
  const [toValue, setToValue] = useState([]);

  const isValid = (email) => {
    if (isInList(email)) {
      toast.info(t("uploadDocFlow.hasAdded"));
      return false;
    }

    if (!isEmail(email)) {
      toast.error(`${email} ${t("uploadDocFlow.isnotValid")}`);
      return false;
    }

    return true;
  };

  const isInList = (email) => {
    return items.includes(email);
  };

  const isEmail = (email) => {
    return /[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/.test(email);
  };

  const handleButtonClick = async () => {
    const token = sessionStorage.getItem("token");
    const storedRegisterSignID = localStorage.getItem("registerSignID");

    try {
      setIsLoading(true);
      const emailsString = items.join(",");
      const toString = toValue.join(",");
      const response = await axios.put(
        "/v1.0/api/CopyTo",
        {
          RegisterSignID: storedRegisterSignID,
          emailTo: toString,
          CopyTo: emailsString,
        },
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
      if (response.data.statusCode === "1") {
        toast.success(t("uploadDocFlow.SuccessUpdated"));
        setIsLoading(false);
      }
    } catch (error) {
      toast.error(t("uploadDocFlow.errFailed"));
    } finally {
      setIsLoading(false);
    }
  };

  const handleChipsChange = (e) => {
    const newEmail = e.value[e.value.length - 1];

    if (newEmail && isValid(newEmail)) {
      setItems(e.value);
    }
  };

  const handleRemove = (email) => {
    const updatedItems = items.filter((item) => item !== email);
    setItems(updatedItems);
  };

  return (
    <>
      {isLoading && (
        <div className='loader-overlay'>
          <CCLoader />
        </div>
      )}
      <Row>
        <Col>
          <div>
            <div>
              <label className='mb-2'>{t("uploadDocFlow.emailAdd")}</label>

              <Chips value={items} onChange={handleChipsChange} className='form-control' placeholder={t("uploadDocFlow.enterEmail")} removable onRemove={(e) => handleRemove(e.value)} />
            </div>
          </div>

          {items.length > 0 && (
            <Button variant='none' className='ImageCancelBtn mt-3' style={{ fontWeight: "400" }} onClick={handleButtonClick}>
              {t("uploadDocFlow.AddtoCC")}
            </Button>
          )}
        </Col>
      </Row>
    </>
  );
};

export default CCEmail;
