import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Modal, Row, Form } from "react-bootstrap";
import axios from "../../Api/api";
import ReactSignatureCanvas from "react-signature-canvas";
import { RxCross2 } from "react-icons/rx";
import html2canvas from "html2canvas";
import SignatureColor from "./SignatureColor";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

const SignatureModal = (props) => {
  const {
    email,
    fullName,
    setFullName,
    registerSignID,
    controlLocationID,
    signatoriesID,
    selectedMasterDocID,
    showModal,
    setShowModal,
    controlName,
    controlID,
    initials,
    setInitials,
    auth,
    handleUploadedSignature,
    signatoryName,
    handleEnterText,
    textEditor,
    setIsBulkSign,
  } = props;

  const [selectedTab, setSelectedTab] = useState("SELECT_SIGN");
  const [previewImage, setPreviewImage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [selectedStyle, setSelectedStyle] = useState("default");
  const [showStyleSidebar, setShowStyleSidebar] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [previewSignature, setPreviewSignature] = useState(null);
  const [uploadSign, setUploadSign] = useState();
  const [extension, setExtension] = useState();
  const [textInput, setTextInput] = useState("");
  const [selectedColor, setSelectedColor] = useState("black");
  const [isSignAllChecked, setIsSignAllChecked] = useState(false);
  const [savedSignature, setSavedSignature] = useState();
  const [isBold, setIsBold] = useState(false);
  const [minWidth, setMinWidth] = useState(0.5);
  const [savedStamp, setSavedStamp] = useState();
  const [drawnSignature, setDrawnSignature] = useState(null);
  const previewBoxRef = useRef(null);
  const { t } = useTranslation();

  useEffect(() => {
    setTextInput(textEditor);
  }, [textEditor]);

  useEffect(() => {
    setIsSignAllChecked(false);
  }, [showModal]);

  useEffect(() => {
    const savedSignature = localStorage.getItem("savedSignature");
    if (savedSignature && signRef.current) {
      signRef.current.fromDataURL(savedSignature);
    }
  }, [showModal]);

  const styleOptions = ["style1", "style2", "style3", "style4", "style5", "style6", "style7"];

  useEffect(() => {
    if (controlName === "Stamp") {
      setSelectedTab("UPLOAD");
      getSignatureStampVerification();
    }

    if (controlName === "Image") {
      setSelectedTab("UPLOAD");
    }

    if (selectedTab === "UPLOAD") {
      setPreviewImage(null);
    }
  }, [controlName]);

  const getSignatureStampVerification = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get("/v1.0/api/GetSignatureStampVerification", {
        headers: {
          SignAuth: auth,
        },
      });

      if (response.data.statusCode === "1") {
        const { mySignatureBase64String, companyStampBase64String } = response.data.response;

        setSavedSignature(mySignatureBase64String);
        setSavedStamp(companyStampBase64String);
      }
    } catch (error) {
      // toast.error("Failed to load saved data");
    }
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const handleTabChange = (tab) => {
    // Save the current signature before switching tabs
    if (selectedTab === "DRAW" && signRef.current) {
      const drawnSignatureData = signRef.current.getTrimmedCanvas().toDataURL("image/png");
      setDrawnSignature(drawnSignatureData);
    }
    setSelectedTab(tab);
  };

  useEffect(() => {
    if (selectedTab === "DRAW" && drawnSignature && signRef.current) {
      signRef.current.fromDataURL(drawnSignature);
    }
  }, [selectedTab, drawnSignature]);

  const fileInputRef = useRef(null);

  const handleFileSelect = () => {
    fileInputRef.current.click();
  };

  const handleFileUpload = (event) => {
    const selectedFile = event.target.files[0];
    setErrorMessage("");
    if (selectedFile) {
      if (isImageFile(selectedFile)) {
        const fileExtension = selectedFile.name.split(".").pop();
        const reader = new FileReader();

        reader.onload = (e) => {
          const base64Image = e.target.result;
          const maxSizeInBytes = 200 * 1024; // 200 KB

          if (base64Image.length > maxSizeInBytes) {
            setPreviewImage(null);
            setErrorMessage(t("signatureModal.imageSizeLimit"));
            return;
          }

          setPreviewImage(base64Image);
          const uploadData = extractBase64FromDataURI(base64Image);
          setUploadSign(uploadData);
          setExtension(fileExtension);
        };

        reader.readAsDataURL(selectedFile);
      } else {
        setPreviewImage(null);
        setErrorMessage(t("signatureModal.invalidFileFormat"));
      }
    }
  };

  const isImageFile = (file) => {
    const acceptedFormats = ["image/jpeg", "image/png", "image/gif", "image/jpg"];
    return acceptedFormats.includes(file.type);
  };

  // draw signature
  const signRef = useRef(null);

  const handleClear = () => {
    signRef.current.clear();
  };

  const handleDrawSignature = async () => {
    const signatureImage = await signRef.current.getTrimmedCanvas().toDataURL("image/png");
    const drawdata = extractBase64FromDataURI(signatureImage);

    localStorage.setItem("savedSignature", signatureImage);
    return drawdata;
  };

  const handleMinWidthChange = (e) => {
    setMinWidth(parseFloat(e.target.value));
  };

  const handleBoldToggle = () => {
    setIsBold(!isBold);
  };

  const renderSignature = () => {
    const boldClass = isBold ? "fw-bold" : "";
    return (
      <div className={`signStyle d-flex align-items-center ${boldClass}`} style={{ color: selectedColor, width: "fit-content", backgroundColor: "transparent" }}>
        {controlName === "Signature" && (
          <div className='signature-item'>
            <h1 className={`${selectedStyle} ${boldClass}`}>{fullName}</h1>
          </div>
        )}
        {controlName === "Initial" && (
          <div className='signature-item'>
            <h1 className={`${selectedStyle} ${boldClass}`}>{initials}</h1>
          </div>
        )}
      </div>
    );
  };

  const generateStylePreview = (style) => {
    const styleClassName = style === "default" ? "" : style;
    return (
      <div
        className={`styles-box d-flex align-items-center justify-content-evenly ${styleClassName}`}
        style={{ color: selectedColor }}
        onClick={() => {
          setSelectedStyle(style);
          setShowStyleSidebar(false);
        }}
      >
        {controlName === "Signature" && <h2 className={styleClassName}>{fullName}</h2>}
        {controlName === "Initial" && <h2 className={styleClassName}>{initials}</h2>}
      </div>
    );
  };

  const extractBase64FromDataURI = (dataURI) => {
    const splitURI = dataURI.split(",");

    if (splitURI.length === 2) {
      const base64Data = splitURI[1];
      return base64Data;
    } else {
      // console.error("Invalid data URI format");
      return null;
    }
  };

  const saveAsBase64 = () => {
    const previewBox = previewBoxRef.current;
    //console.log("preview", previewBox);
    return html2canvas(previewBox, {
      backgroundColor: null,
    })
      .then((canvas) => {
        const base64Image = canvas.toDataURL("image/png");
        const base64Data = extractBase64FromDataURI(base64Image);
        return base64Data;
      })
      .catch((error) => {
        // console.error(error);
      });
  };

  const handleSignButton = async (isBulkSign) => {
    setIsLoading(true);
    let signature;
    let signatureExtension;

    if (selectedTab === "SELECT_SIGN") {
      const selectSignBase64 = await saveAsBase64();
      signature = selectSignBase64;
      signatureExtension = ".png";
    } else if (selectedTab === "DRAW") {
      const selectDrawBase64 = await handleDrawSignature();
      signature = selectDrawBase64;
      signatureExtension = ".png";
    } else if (selectedTab === "UPLOAD") {
      signature = uploadSign;
      signatureExtension = extension;
    } else if (selectedTab === "SAVED") {
      if (controlName === "Stamp") {
        signature = savedStamp;
        signatureExtension = ".png";
      } else if (controlName === "Signature") {
        signature = savedSignature;
        signatureExtension = ".png";
      }
    }

    if (!signature) {
      toast.info(t("signatureModal.imageUpload"));
      setIsLoading(false);
      return;
    }

    const uploadedImage = signature;
    // const imageExtension = signatureExtension;
    handleUploadedSignature(uploadedImage, controlLocationID, signatoriesID, controlName, controlID, signatureExtension, selectedMasterDocID, isBulkSign);
    // console.log('controlName', controlName)

    try {
      const response = axios.post(
        "/v1.0/api/CreateSignActivityVerification",
        {
          Status: `${controlName} uploaded`,
          Message: isBulkSign ? `${signatoryName} uploaded ${controlName} for all relevant sections ` : `${signatoryName} uploaded ${controlName} `,
        },
        {
          headers: {
            signAuth: auth,
          },
        }
      );

      //console.log(response);
    } catch (error) {}

    setShowModal(false);
  };

  // const handleSignAll = async () => {
  //   await setIsBulkSign(true);
  //   handleSignButton();
  // };

  const renderPreviewSignature = () => {
    //console.log(previewSignature);
    if (previewSignature) {
      return (
        <div className='preview-box mb-2 d-flex align-items-center p-2'>
          <img src={previewSignature} alt='Preview' style={{ maxWidth: "100%", maxHeight: "100%" }} />
        </div>
      );
    }
    return null;
  };

  const handleCheckboxChange = (event) => {
    setIsSignAllChecked(event.target.checked);
  };

  const handleSign = () => {
    if (isSignAllChecked) {
      handleSignButton(true);
    } else {
      handleSignButton(false);
    }
  };

  return (
    <Modal show={showModal} onHide={closeModal} dialogClassName='custom-modal-width'>
      <Modal.Header closeButton>
        <Modal.Title>
          {controlName === "Stamp" && t("signatureModal.stamp_here")}
          {controlName === "Signature" && t("signatureModal.sign_here")}
          {controlName === "Initial" && t("signatureModal.initials_here")}
          {controlName === "Image" && t("signatureModal.upload_image")}
        </Modal.Title>
      </Modal.Header>

      {showStyleSidebar && (
        <div className='style-sidebar'>
          <Button onClick={() => setShowStyleSidebar(false)} className='applyBtn w-100'>
            <RxCross2 />
          </Button>

          {styleOptions.map((style) => (
            <div key={style}>{generateStylePreview(style)}</div>
          ))}
        </div>
      )}

      <Modal.Body className='signature-modal-body'>
        <p className='mb-0'>{t("signatureModal.confirmNameSignature")}</p>
        <Row className='py-2'>
          <Col md={7}>
            {controlName === "Signature" && (
              <>
                <label htmlFor='name'>
                  {t("createTeam.fullName")}
                  <span style={{ color: "red" }}>*</span>
                </label>
                <br />
                <input type='text' id='name' value={fullName} onChange={(e) => setFullName(e.target.value)} />
              </>
            )}
            {controlName === "Initial" && (
              <>
                <label htmlFor='initials'>
                  <b> {t("signatureModal.initials")}</b>
                  <span style={{ color: "red" }}>*</span>
                </label>
                <br />
                <input type='text' id='initials' value={initials} onChange={(e) => setInitials(e.target.value)} />
              </>
            )}
          </Col>
        </Row>
        <div className='signature_tabs pt-2'>
          <div className='nav-links'>
            {controlName === "Stamp" || controlName === "Image" ? null : (
              <>
                <button onClick={() => handleTabChange("SELECT_SIGN")} className={selectedTab === "SELECT_SIGN" ? "active-tab" : ""}>
                  {t("signatureModal.selectStyle")}
                </button>
                <button onClick={() => handleTabChange("DRAW")} className={selectedTab === "DRAW" ? "active-tab" : ""}>
                  {t("signatureModal.draw")}
                </button>
              </>
            )}

            <button onClick={() => handleTabChange("UPLOAD")} className={selectedTab === "UPLOAD" ? "active-tab" : ""}>
              {t("signatureModal.upload")}
            </button>
            {controlName === "Image" ? null : (
              <button
                onClick={() => {
                  handleTabChange("SAVED");
                  getSignatureStampVerification();
                }}
                className={selectedTab === "SAVED" ? "active-tab" : ""}
              >
                {t("signatureModal.saved")}
              </button>
            )}
          </div>
          <hr className='m-0' />

          {selectedTab === "SELECT_SIGN" && (
            <div>
              <div className='d-flex justify-content-between my-3'>
                {/* <b>Preview</b> */}
                <SignatureColor selectedColor={selectedColor} setSelectedColor={setSelectedColor} />
                <div>
                  <button style={{ fontFamily: "Ubuntu" }} onClick={handleBoldToggle}>
                    {t("signatureModal.bold")}
                  </button>
                  <button onClick={() => setShowStyleSidebar(true)}> {t("signatureModal.changeSign")}</button>
                </div>
              </div>
              <div className='preview-box d-flex align-items-center'>
                <div className='signStyle' ref={previewBoxRef}>
                  {renderSignature()}
                </div>
              </div>
            </div>
          )}

          {selectedTab === "DRAW" && (
            <div>
              <div className='d-flex justify-content-between my-3'>
                {/* <b>Draw signature</b> */}

                <SignatureColor selectedColor={selectedColor} setSelectedColor={setSelectedColor} />
                <div className='d-flex align-items-start gap-3 justify-content-end'>
                  <div className='d-flex align-items-start gap-1 justify-content-end'>
                    <Form.Label>{t("signatureModal.Pointer")}</Form.Label>
                    <Form.Group controlId='minWidthSlider' className='mx-1' style={{ width: "50%" }}>
                      <input type='range' min='0.1' max='5' step='0.1' value={minWidth} onChange={handleMinWidthChange} />
                    </Form.Group>
                  </div>
                  <button onClick={handleClear}>{t("signatureModal.clear")}</button>
                </div>
              </div>

              <div className='preview-box'>
                <ReactSignatureCanvas
                  penColor={selectedColor}
                  minWidth={minWidth}
                  ref={signRef}
                  canvasProps={{
                    width: 500,
                    height: 150,
                    className: "sigCanvas",
                    background: "transparent",
                  }}
                />
              </div>
            </div>
          )}

          {selectedTab === "UPLOAD" && (
            <div>
              <div className='d-flex justify-content-between my-3'>{/* <b>Preview</b> */}</div>
              <div className='preview-box mb-2 d-flex align-items-center p-2'>
                {errorMessage ? <div className='error-message'>{errorMessage}</div> : previewImage && <img src={previewImage} alt='Uploaded' style={{ maxWidth: "100%", maxHeight: "100%" }} />}
              </div>
              <div>
                <Button onClick={handleFileSelect} className='applyBtn' style={{ background: "#0c9ad7" }}>
                  {controlName === "Stamp" && t("signatureModal.upload_stamp")}
                  {controlName === "Signature" && t("signatureModal.upload_signature")}
                  {controlName === "Initial" && t("signatureModal.upload_initials")}
                  {controlName === "Image" && t("signatureModal.upload_image")}
                </Button>
                <input type='file' accept='.jpg, .jpeg, .png, .gif' style={{ display: "none" }} ref={fileInputRef} onChange={handleFileUpload} />
              </div>
              <small>{t("signatureModal.picQuality")}</small>
            </div>
          )}

          {selectedTab === "SAVED" && (
            <div>
              <div className='d-flex justify-content-between my-3'>{/* <b>Saved Items</b> */}</div>
              <div className='preview-box mb-2 d-flex align-items-center p-2'>
                {errorMessage ? (
                  <div className='error-message'>{errorMessage}</div>
                ) : controlName === "Stamp" ? (
                  savedStamp ? (
                    <>
                      <img src={`data:image/png;base64,${savedStamp}`} alt='Saved Stamp' style={{ maxWidth: "100%", maxHeight: "100%" }} />
                    </>
                  ) : (
                    <div>{t("signatureModal.noStamp")}</div>
                  )
                ) : controlName === "Signature" ? (
                  savedSignature ? (
                    <>
                      <img src={`data:image/png;base64,${savedSignature}`} alt='Saved Signature' style={{ maxWidth: "100%", maxHeight: "100%" }} />
                    </>
                  ) : (
                    <div>{t("signatureModal.noSignature")}</div>
                  )
                ) : null}
              </div>
            </div>
          )}

          <div className='adopt-text my-2'>
            <small>{t("signatureModal.signatureDesc")}</small>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className='d-flex w-100 justify-content-start align-items-center bulk-sign'>
          <input type='checkbox' checked={isSignAllChecked} onChange={handleCheckboxChange} className='form-check-input mx-2' />
          <small style={{ fontStyle: "italic", color: "red" }}>
            {t("signatureModal.WnatPlace")}
            {controlName === "Stamp" && " Stamp "}
            {controlName === "Signature" && " Signature "}
            {controlName === "Initial" && " Initials "}
            {controlName === "Image" && " Image "}
            {t("signatureModal.relevantSections")}
          </small>
        </div>
        <div className='d-flex justify-content-between gap-2 w-100'>
          <Button onClick={closeModal} className='modalCancelButton'>
            {t("signatureModal.cancel")}
          </Button>

          <Button onClick={handleSign} className='applyBtn w-100'>
            {controlName === "Stamp" && t("signatureModal.stamp_now")}
            {controlName === "Signature" && t("signatureModal.sign_now")}
            {controlName === "Initial" && t("signatureModal.initials_now")}
            {controlName === "Image" && t("signatureModal.upload_image")}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default SignatureModal;
