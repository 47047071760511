import React, { useEffect, useState } from "react";
import axios from "../../Api/api";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { Button, Form, Dropdown, Offcanvas, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useParams, useNavigate } from "react-router-dom";
import { IoChevronBackOutline } from "react-icons/io5";
import { TbFolders } from "react-icons/tb";
import noDocImg from "../../assets/images/Folder_empty.svg";
import downlaodIcon from "../../assets/images/document-download.png";
import shareIcon from "../../assets/images/export.png";
import showIcon from "../../assets/images/Show.png";
import auditIcon from "../../assets/images/searchicon.png";
import pdfFile from "../../assets/images/Delete.svg";
import pdfIcon from "../../assets/images/pdfFile.svg";
import updateIcon from "../../assets/images/Edit.svg";
import deleteIcon from "../../assets/images/Delete.svg";
import documentIcon from "../../assets/images/FolderAdd.svg";
import viewIcon from "../../assets/images/Show.png";
import FolderDocs from "../../assets/images/folder fill.svg";
import emptyFolder from "../../assets/images/Folderblack.svg";
import "./style.css";
import Loader from "../Loader/Loader";
import ShareModal from "../CommonModal/ShareModal";
import ViewModal from "../ViewModal/ViewModal";
import SignOrder from "../SignOrder";
import { Pagination } from "@mui/material";
import DownloadModal from "../DownloadModal";
import AuditTrailModal from "../../Pages/AuditTrail/AuditTrailModal";
import CustomToggle from "../CustomDots/CustomToggle";
import AddCompletedDocuments from "./AddCompletedDocuments";
import AddCompletedToSubFolder from "./AddCompletedToSubFolder";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from "@mui/material";

const ViewDocuments = () => {
  const { t } = useTranslation();
  const { folderId, folderName } = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [signedDocKey, setSignedDocKey] = useState();
  const [registerSignID, setRegisterSignID] = useState();
  const [showModal, setShowModal] = useState(false);
  const [showShareModal, setShowShareModal] = useState(false);
  const [pdfUrl, setPdfUrl] = useState("");
  const { reset } = useForm();
  const [page, setPage] = useState(0);
  const [dataRow, setDataRow] = useState();
  const [rowsPerPage, setRowsPerPage] = useState(30);
  const [masterDocID, setMasterDocID] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [subFolderData, setSubFolderData] = useState([]);
  const [showATModal, setShowATModal] = useState(false);
  const [showDownloadModal, setShowDownloadModal] = useState(false);
  const [auditTrailURL, setAuditTrailURL] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteMasterDocId, setDeleteMasterDocId] = useState("");
  const [selectedFolderId, setSelectedFolderId] = useState("");
  const [selectedSubFolderId, setSelectedSubFolderId] = useState("");
  const [showAddDocument, setShowAddDocument] = useState(false);
  const [showAddDocumentSub, setShowAddDocumentSub] = useState(false);
  const [formData, setFormData] = useState({ FolderID: "", FolderName: "", FolderType: "Files", Description: "" });
  const [updateFormData, setUpdateFormData] = useState({ SubFolderID: "", FolderName: "", FolderType: "Files", Description: "" });
  const [showUpdateOffcanvas, setShowUpdateOffcanvas] = useState(false);
  const [showDeleteConfirmatrionFolderModal, setShowDeleteConfirmatrionFolderModal] = useState(false);
  const [showDeleteConfirmatrionFolderID, setShowDeleteConfirmatrionFolderID] = useState(false);
  const [showFolder, setShowFolder] = useState(false);

  const navigateToViewFiles = (subFolderName, subFolderID) => {
    const folderPath = `/sub-folders/${subFolderName}/${subFolderID}`;
    navigate(folderPath);
  };

  const handleViewDocuments = (subFolderName, subFolderID) => {
    setSelectedFolderId(subFolderName);
    navigate(`/sub-folders/${subFolderName}/${subFolderID}`);
  };

  const handleUpdateClick = (row) => {
    const { subFolderID, subFolderName, subFolderDescription } = row;
    setUpdateFormData({ SubFolderID: subFolderID, FolderName: subFolderName, Description: subFolderDescription, FolderType: "Files" });
    setShowUpdateOffcanvas(true);
  };

  const handleDeleteFolderModal = (subFolderID) => {
    setShowDeleteConfirmatrionFolderID(subFolderID);
    setShowDeleteConfirmatrionFolderModal(true);
  };

  const handleUpdateFormSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      const response = await axios.put("/v1.0/api/UpdateSubFolder", updateFormData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.data.statusCode === "1") {
        toast.success(t("Folder.folderUpdateSuccess"));
        fetchDocuments();
        setShowUpdateOffcanvas(false);
      }
    } catch (error) {
      console.error("Error updating folder:", error);
      toast.error(t("Folder.errUpdateFolder"));
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdateInputChange = (e) => {
    const { name, value } = e.target;
    setUpdateFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleCloseUpdate = () => {
    setShowUpdateOffcanvas(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const SubFolderData = {
        ...formData,
        FolderID: folderId,
        FolderType: "Files",
      };
      setIsLoading(true);
      const response = await axios.post("/v1.0/api/CreateSubFolder", SubFolderData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.data.statusCode === "1") {
        toast.success(t("Folder.createFolderSuccess"));
        setFormData({ FolderName: "", Description: "" });
        setShowFolder(false);
        fetchDocuments();
      }
    } catch (error) {
      console.error("Error creating folder:", error);
      toast.error(t("Folder.errCreateFolder"));
    } finally {
      setIsLoading(false);
    }
  };

  const handleCloseOffcanvas = () => {
    setShowFolder(false);
  };

  const handleCancel = () => {
    setShowFolder(false);
  };

  const HandleDeleteFolderOnly = async () => {
    const data = {
      SubFolderID: showDeleteConfirmatrionFolderID,
    };
    try {
      setIsLoading(true);
      const response = await axios.delete("/v1.0/api/DeleteSubFolder", {
        data,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.data.statusCode === "1") {
        toast.success(t("Folder.deleteSuccess"));
        setShowDeleteConfirmatrionFolderModal(false);
        fetchDocuments();
      }
    } catch (error) {
      console.error("Error deleting folder:", error);
      toast.error(t("Folder.errDeleteFolder"));
    } finally {
      setIsLoading(false);
    }
  };

  const [shareFormData, setShareFormData] = useState({
    Email: "",
    Subject: "",
    Body: "",
    MasterDocID: "",
    RegisterSignID: "",
  });

  const handleAddDocumentClick = () => {
    setSelectedFolderId(folderId);
    setShowAddDocument(true);
  };

  const handleAddDocumentClose = () => {
    fetchDocuments();
    setShowAddDocument(false);
  };

  const handleAddDocSubFolder = (subFolderID) => {
    setSelectedSubFolderId(subFolderID);
    setShowAddDocumentSub(true);
  };

  const handleAddDocSubClose = () => {
    fetchDocuments();
    setShowAddDocumentSub(false);
  };

  const goBack = () => navigate(-1);

  const token = sessionStorage.getItem("token");
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleShareModalShow = async (row) => {
    const { masterDocID, registerSignID } = row;
    setMasterDocID(masterDocID);
    setRegisterSignID(registerSignID);
    try {
      const shareData = { ...shareFormData, MasterDocID: masterDocID, RegisterSignID: registerSignID };
      setShareFormData(shareData);
      setShowShareModal(true);
    } catch (error) {
      toast.error(t("dashboardCardStatuses.shareFiletoastErr"));
    }
  };

  // Function to handle submitting share data
  const handleShareDocument = async () => {
    setIsLoading(true);
    try {
      const response = await axios.post("/v1.0/api/CreateDocShared", shareFormData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.data.statusCode === "1") {
        toast.success(t("dashboardCardStatuses.shareFileSucessToast"));
      } else if (response.data.statusCode === "3") {
        toast.info(t("dashboardCardStatuses.shareFileInfo"));
      }
      setShowShareModal(false);
      setIsLoading(false);
      reset();
    } catch (error) {
      if (error.response && error.response.status === "4") {
        toast.error(t("dashboardCardStatuses.unathorizedErrSharedFile"));
      } else {
        toast.error(t("dashboardCardStatuses.shareFileToastErr"));
      }
      setIsLoading(false);
    }
  };

  const fetchDocuments = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`/v1.0/api/GetFolderDocsByFolderID?FolderID=${folderId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.data.successStatus) {
        const filesData = response.data.response[0].files || [];
        const subFolder = response.data.response[0].subFolders || [];
        setSubFolderData(subFolder);
        setData([...subFolder, ...filesData]);
        setSearchResults([...subFolder, ...filesData]);
        if (filesData.length > 0) {
          const firstItem = filesData[0];
          setSignedDocKey(firstItem.signedDocKey);
        }
        setIsLoading(false);
      }
    } catch (error) {
      console.log("Error fetching documents:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchDocuments();
  }, [folderId]);

  // Handle download
  const handleFileDownload = async (row) => {
    setDataRow(row);
    setShowDownloadModal(true);
  };

  const handleView = async (row) => {
    setIsLoading(true);
    try {
      const { signedDocKey } = row;
      const response = await axios.get("/v1.0/api/DownloadDoc", {
        headers: {
          SignAuth: signedDocKey,
        },
      });
      const pdfUrl = response.data.response.document;
      setPdfUrl(pdfUrl);
      setShowModal(true);
    } catch (error) {
      console.error(error);
      toast.error(t("dashboardCardStatuses.fileNotDisplay"));
    } finally {
      setIsLoading(false);
    }
  };

  const handleViewAuditTrail = (signedDocKey) => {
    const newPdfUrl = `/AuditTrail/id/${signedDocKey}`;
    setAuditTrailURL(newPdfUrl);
    setShowATModal(true);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    let hours = date.getHours();
    const minutes = date.getMinutes();

    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12;

    const formattedMinutes = (minutes < 10 ? "0" : "") + minutes;

    return `${day}/${month}/${year} ${hours}:${formattedMinutes} ${ampm}`;
  };

  const handleCellClick = (event) => {
    const cell = event.target;
    cell.classList.toggle("scrollable");
  };

  const handleDeleteModal = (masterDocID) => {
    setDeleteMasterDocId(masterDocID);
    setShowDeleteModal(true);
  };

  const handleDeleteFile = async () => {
    setIsLoading(true);
    try {
      const response = await axios.delete(`/v1.0/api/RemoveDocumentFromFolder?FolderID=${folderId}&MasterDocID=${deleteMasterDocId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.data.statusCode === "1") {
        toast.success(t("uploadDocFlow.fileDeleted"));
        setShowDeleteModal(false);
        fetchDocuments();
      } else {
        toast.error(t("Folder.errdeleteFile"));
      }
      setIsLoading(false);
    } catch (error) {
      console.log("Error", error);
    } finally {
      setIsLoading(false);
      setShowDeleteModal(false);
    }
  };

  const columns = [
    { label: t("tablesLabel.folderFiles"), key: "docName" },
    { label: t("tablesLabel.date"), key: "dateModified" },
    { label: t("coBranding.description"), key: "subFolderDescription" },
    { label: t("tablesLabel.createdby"), key: "createdByName" },
    { label: "", key: "actions" },
  ];

  return (
    <div className='container my-3'>
      {isLoading && (
        <div className='loader-overlay'>
          <Loader />
        </div>
      )}

      <div className='py-3 p-3 container pendingtable'>
        <div className='d-flex align-items-center justify-content-between'>
          <div className='d-flex align-items-center gap-2'>
            <IoChevronBackOutline className={document.documentElement.dir === "rtl" ? "rtl-icon" : ""} onClick={goBack} style={{ cursor: "pointer" }} />
            {data.length === 0 ? (
              <>
                <img src={emptyFolder} alt='folderIcon' style={{ height: "30px", width: "30px" }} />
              </>
            ) : (
              <>
                <img src={FolderDocs} alt='folderIcon' style={{ height: "30px", width: "30px" }} />
              </>
            )}
            <span>
              <label className='mx-2' style={{ fontSize: "20px", fontWeight: "600" }}>
                {folderName}
              </label>
              {t("Folder.folder")}
            </span>
          </div>
          <Dropdown>
            <Dropdown.Toggle as={CustomToggle} />
            <Dropdown.Menu style={{ zIndex: "100000" }}>
              <Dropdown.Item className='d-flex align-items-center gap-2' onClick={() => setShowFolder(true)}>
                <TbFolders size={22} />
                {t("Folder.addSubFolder")}
              </Dropdown.Item>
              <Dropdown.Item className='d-flex align-items-center gap-2' onClick={() => handleAddDocumentClick()}>
                <img src={documentIcon} alt='fileIcon' />
                {t("Folder.add")}
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <hr />

        <TableContainer component={Paper} style={{ height: "65vh" }}>
          <Table stickyHeader style={{ border: "none" }}>
            <TableHead className='customTableHead'>
              <TableRow>
                {columns.map((column) => (
                  <TableCell style={{ backgroundColor: "#e7ebee" }} className='TableHeaderCell' key={column.key}>
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={columns.length} align='center' style={{ border: "none" }}>
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                      <img alt='iconLoading' src={noDocImg} style={{ height: "150px", width: "200px" }} />
                      {t("dashboardCardStatuses.notDocsFound")}
                    </div>
                  </TableCell>
                </TableRow>
              ) : (
                searchResults.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
                  <TableRow key={index}>
                    <TableCell
                      style={{ cursor: row.subFolderID ? "pointer" : "default" }}
                      onClick={row.subFolderID ? () => navigateToViewFiles(row.subFolderName, row.subFolderID) : undefined}
                      className='truncate-cell'
                    >
                      {row.subFolderID ? (
                        <div className='d-flex align-items-center gap-2'>
                          {row.numberOfFiles === "0" ? (
                            <>
                              <img src={emptyFolder} alt='folderIcon' style={{ height: "25px", width: "25px" }} />
                            </>
                          ) : (
                            <>
                              <img src={FolderDocs} alt='folderIcon' style={{ height: "25px", width: "25px" }} />
                            </>
                          )}
                          {row.subFolderName}
                        </div>
                      ) : (
                        <div className='d-flex align-items-center gap-2'>
                          <img src={pdfIcon} alt='IconLoading' />
                          {row.docName}
                        </div>
                      )}
                    </TableCell>
                    <TableCell>{row.subFolderID ? formatDate(row.createdOn) : formatDate(row.completedDate)}</TableCell>
                    <TableCell>{row.subFolderDescription}</TableCell>
                    <TableCell>{row.subFolderID ? row.subFolderCreatedByName : row.createdByName}</TableCell>
                    <TableCell className='py-0'>
                      <Dropdown className='align-items-center'>
                        <Dropdown.Toggle as={CustomToggle} />

                        <Dropdown.Menu>
                          {row.subFolderID ? (
                            <>
                              <Dropdown.Item className='d-flex gap-2 align-items-center' onClick={() => handleUpdateClick(row)}>
                                <img src={updateIcon} alt='editImage' />
                                {t("Folder.update")}
                              </Dropdown.Item>
                              <Dropdown.Item className='d-flex gap-2 align-items-center' onClick={() => handleViewDocuments(row.subFolderName, row.subFolderID)}>
                                <img src={viewIcon} alt='viewImage' />
                                {t("Folder.view")}
                              </Dropdown.Item>
                              <Dropdown.Item className='d-flex gap-2 align-items-center' onClick={() => handleAddDocSubFolder(row.subFolderID)}>
                                <img src={documentIcon} alt='addImage' />
                                {t("Folder.add")}
                              </Dropdown.Item>
                              <Dropdown.Item className='d-flex gap-2 align-items-center' onClick={() => handleDeleteFolderModal(row.subFolderID)}>
                                <img src={deleteIcon} alt='deleteImage' />
                                {t("Folder.delete")}
                              </Dropdown.Item>
                            </>
                          ) : (
                            <>
                              <Dropdown.Item className='d-flex gap-2 align-items-center' onClick={() => handleFileDownload(row)}>
                                <img src={downlaodIcon} alt='iconLoading' />
                                {t("dashboardCardStatuses.download")}
                              </Dropdown.Item>
                              <Dropdown.Item className='d-flex gap-2 align-items-center' onClick={() => handleShareModalShow(row)}>
                                <img src={shareIcon} alt='iconLoading' />
                                {t("dashboardCardStatuses.share")}
                              </Dropdown.Item>
                              <Dropdown.Item className='d-flex gap-2 align-items-center' onClick={() => handleView(row)}>
                                <img src={showIcon} alt='iconLoading' />
                                {t("dashboardCardStatuses.view")}
                              </Dropdown.Item>
                              <Dropdown.Item>
                                <SignOrder masterDocID={row.masterDocID} />
                              </Dropdown.Item>
                              <Dropdown.Item className='d-flex gap-2 align-items-center' onClick={() => handleViewAuditTrail(row.signedDocKey)}>
                                <img src={auditIcon} alt='iconLoading' />
                                {t("dashboardCardStatuses.viewAuditTrail")}
                              </Dropdown.Item>

                              <Dropdown.Item className='d-flex gap-2 align-items-center' onClick={() => handleDeleteModal(row.masterDocID)}>
                                <img src={pdfFile} alt='iconLoading' />
                                {t("Folder.deletefile")}
                              </Dropdown.Item>
                            </>
                          )}
                        </Dropdown.Menu>
                      </Dropdown>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <div style={{ display: "flex", justifyContent: "space-between", marginTop: "1rem" }}>
          <div className='showHideNoDocs'>
            {page * rowsPerPage + 1} - {Math.min((page + 1) * rowsPerPage, data.length)} {t("tablesLabel.documentsof")} {data.length} {t("tablesLabel.document")}
          </div>
          <Pagination
            count={Math.ceil(data.length / rowsPerPage)}
            variant='outlined'
            shape='rounded'
            page={page + 1}
            onChange={(event, newPage) => handleChangePage(event, newPage - 1)}
            sx={{ "& .Mui-selected": { background: "#0C9AD7 !important", color: "white" } }}
          />
        </div>
      </div>

      {/* Share and View modals */}
      <ShareModal
        show={showShareModal}
        handleClose={() => setShowShareModal(false)}
        formData={shareFormData}
        handleShare={handleShareDocument}
        handleChange={(e) => setShareFormData({ ...shareFormData, [e.target.name]: e.target.value })}
        masterDocID={masterDocID}
        registerSignID={registerSignID}
      />

      <ViewModal
        show={showModal}
        handleClose={() => {
          setShowModal(false);
          setPdfUrl("");
        }}
        pdfUrl={pdfUrl}
      />

      <DownloadModal
        show={showDownloadModal}
        dataRow={dataRow}
        handleClose={() => {
          setShowDownloadModal(false);
        }}
      />

      <AuditTrailModal
        show={showATModal}
        auditTrailURL={auditTrailURL}
        handleClose={() => {
          setShowATModal(false);
          setAuditTrailURL("");
        }}
      />

      <Modal show={showDeleteModal} backdrop='static' keyboard={false} onHide={() => showDeleteModal(false)} dialogClassName='custom-modal-width'>
        <Modal.Header>
          <Modal.Title>{t("Folder.deleteConfirmation")}</Modal.Title>
        </Modal.Header>
        <Modal.Body className='p-3'>{t("Folder.sureDeleteFile")}</Modal.Body>
        <Modal.Footer className='d-flex justify-content-end gap-5'>
          <Button variant='none' className='modalCancelButton' onClick={() => setShowDeleteModal(false)}>
            {t("Folder.Cancel")}
          </Button>
          <Button className='applyBtn' variant='none' onClick={handleDeleteFile}>
            {t("Folder.Confirm")}
          </Button>
        </Modal.Footer>
      </Modal>

      <AddCompletedDocuments show={showAddDocument} handleClose={handleAddDocumentClose} folderId={selectedFolderId} />
      <AddCompletedToSubFolder show={showAddDocumentSub} handleClose={handleAddDocSubClose} subFolderID={selectedSubFolderId} />

      <Offcanvas show={showUpdateOffcanvas} onHide={handleCloseUpdate} placement={document.documentElement.dir === "rtl" ? "start" : "end"} scroll={true}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>{t("Folder.updateFolder")}</Offcanvas.Title>
        </Offcanvas.Header>
        <hr />
        <Offcanvas.Body>
          <Form onSubmit={handleUpdateFormSubmit}>
            <Form.Group className='mb-3' controlId='updateFolderName'>
              <Form.Label> {t("Folder.folderName")}</Form.Label>
              <Form.Control type='text' name='FolderName' value={updateFormData.FolderName} onChange={handleUpdateInputChange} placeholder={t("Folder.enterfodlerName")} />
            </Form.Group>
            <Form.Group className='mb-3' controlId='updateDescription'>
              <Form.Label>{t("Folder.Description")}</Form.Label>
              <Form.Control as='textarea' name='Description' value={updateFormData.Description} onChange={handleUpdateInputChange} rows={3} placeholder={t("Folder.enterDescription")} />
            </Form.Group>
            <div className='newComment d-flex align-items-center justify-content-between w-100 p-3 py-4'>
              <Button variant='none' className='resetBtn' onClick={handleCloseUpdate}>
                {t("Folder.Cancel")}
              </Button>
              <Button type='submit' variant='none' className='applyBtn'>
                {t("Folder.Create")}
              </Button>
            </div>
          </Form>
        </Offcanvas.Body>
      </Offcanvas>

      <Offcanvas show={showFolder} onHide={handleCloseOffcanvas} placement={document.documentElement.dir === "rtl" ? "start" : "end"} scroll={true}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>{t("Folder.CreateFolder")}</Offcanvas.Title>
        </Offcanvas.Header>
        <hr />
        <Offcanvas.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group className='mb-3' controlId='folderName'>
              <Form.Label>{t("Folder.folderName")}</Form.Label>
              <Form.Control type='text' name='FolderName' value={formData.FolderName} onChange={handleInputChange} placeholder={t("Folder.enterfodlerName")} />
            </Form.Group>
            <Form.Group className='mb-3' controlId='description'>
              <Form.Label>{t("Folder.Description")}</Form.Label>
              <Form.Control as='textarea' name='Description' value={formData.Description} onChange={handleInputChange} rows={3} placeholder={t("Folder.enterDescription")} />
            </Form.Group>
            <div className='newComment d-flex align-items-center justify-content-between w-100 p-3 py-4'>
              <Button variant='none' className='resetBtn' onClick={handleCancel}>
                {t("Folder.Cancel")}
              </Button>
              <Button type='submit' variant='none' disabled={!formData.FolderName.trim()} className='applyBtn'>
                {t("Folder.Create")}
              </Button>
            </div>
          </Form>
        </Offcanvas.Body>
      </Offcanvas>

      <Modal show={showDeleteConfirmatrionFolderModal} onHide={() => setShowDeleteConfirmatrionFolderModal(false)} dialogClassName='custom-modal-width'>
        <Modal.Header closeButton>
          <Modal.Title> {t("Folder.deleteConfirmation")}</Modal.Title>
        </Modal.Header>
        <Modal.Body className='px-3 py-2'>{t("Folder.sureDeleteFolder")}</Modal.Body>
        <Modal.Footer className='mt-4 d-flex justify-content-end gap-5'>
          <Button variant='none' className='modalCancelButton' onClick={() => setShowDeleteConfirmatrionFolderModal(false)}>
            {t("Folder.Cancel")}
          </Button>
          <Button className='applyBtn' variant='none' onClick={HandleDeleteFolderOnly}>
            {t("Folder.Confirm")}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ViewDocuments;
