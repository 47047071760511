import React, { useState, useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./index.css";
import Home from "./Pages/Home/Home";
import Login from "./Pages/Login/Login";
import Layout from "./Components/Layout/Layout";
import SignaturePage from "./Pages/SignaturePage/SignaturePage";
import SignedMessage from "./Pages/SignedMessage/SignedMessage";
import { UserProvider } from "./Context/UserContext";
import Signup from "./Pages/Signup/SignUp";
import DraftStatus from "./Components/DashboardStatuses/Draftstatus/Draft";
import PendingStatus from "./Components/DashboardStatuses/PendingStatus/PendingwithMe";
import PendingWithOthers from "./Components/DashboardStatuses/PendingStatus/PendingWithothers";
import Complete from "./Components/DashboardStatuses/CompleteStatus/Complete";
import SignedPage from "./Pages/SignedPage/SignedPage";
import ForgotPassword from "./Pages/ForgotPassword/ForgotPassword";
import InactiveStatuses from "./Components/DashboardStatuses/InactiveStatues/DraftInActive.js";
import DownloadSignedPage from "./Pages/DownloadSignedPage";
import Declined from "./Components/DashboardStatuses/Decline/Declined.js";
import DeclinedMessage from "./Pages/DeclinedMessage/DeclinedMessage";
import AuditTrail from "./Pages/AuditTrail/AuditTrail";
import Templates from "./Pages/Templates/Templates";
import { I18nextProvider } from "react-i18next";
import i18n from "./Components/Language/i18n.js";
import TemplatesPage from "./Pages/Templates/TemplatesPage";
import InactiveTemplates from "./Pages/Templates/InactiveTemplates.js";
import DeactivatedTemplates from "./Pages/Templates/InactiveTemplates.js";
import DownloadPdfPage from "./Pages/DownloadPdfPage.js";
import PendingDocs from "./Components/DashboardStatuses/PendingStatus/PendingDocs.js";
import ShareDocs from "./Components/DashboardStatuses/SharedStatus/ShareDocs.js";
import CompleteDocs from "./Components/DashboardStatuses/CompleteStatus/CompleteDocs.js";
import DraftDocs from "./Components/DashboardStatuses/Draftstatus/DraftDocs.js";
import DeclinedDocs from "./Components/DashboardStatuses/Decline/DeclinedDocs.js";
import InactiveDocs from "./Components/DashboardStatuses/InactiveStatues/InactiveDocs.js";
import RecentDocuments from "./Components/DashboardStatuses/Dashboard/RecentDocuments.js";
import PageNotFound from "./Pages/PageNotFound/PageNotFound.js";
import UserProfile from "./Pages/Profile/UserProfile.js";
import CreateTeam from "./Components/CreateTeam/CreateTeam";
import ResetPassword from "./Pages/ForgotPassword/Resetpassword.js";
import CreateWebhook from "./Integrations/webhookIntegration/CreateWebhook.js";
import LinkExpire from "./Pages/LinkExpire/LinkExpire.js";
import AIGeneratedPDF from "./Integrations/OpenAI/AIGeneratedPDF.js";
import OpenAIComponent from "./Integrations/OpenAI/OpenAIComponent.js";
import Secondstep from "./Pages/DraggablePage/Step2/Secondstep.js";
import MobileTemplatesSecondStep from "./Pages/MobileTemplates/Step2/Secondstep.js";
import ListOfDocuments from "./Integrations/OpenAI/ListOfDocuments.js";
import Folder from "./Components/Folder/Folder.js";
import RecallDocuments from "./Components/DashboardStatuses/RecallDocuments/RecallDocuments.js";
import RecallDocs from "./Components/DashboardStatuses/RecallDocuments/RecallDocs.js";
import DocumentRecalled from "./Pages/DocumentRecalled.js";
import ViewDocuments from "./Components/Folder/ViewDocuments.js";
import CustomToaster from "./Components/CustomToast/CustomToast.js";
import ManuallyGenerateComponent from "./Integrations/ManuallyGenerate/ManuallyGenerateComponent.js";
import ManualDosList from "./Integrations/ManuallyGenerate/ManualDosList.js";
import ViewSubFolderDocuments from "./Components/Folder/SubFolder/ViewSubFolderDocuments.js";
import BulkDocuments from "./Components/DashboardStatuses/BulkStatus/BulkDocuments.js";
import BulkSignatoriesList from "./Components/DashboardStatuses/BulkStatus/BulkSignatoriesList.js";
import AuditTrailSignaturePage from "./Pages/AuditTrail/AuditTrailSignaturePage.js";
import UserActivityStatus from "./Status Activity/UserActivityStatus.js";
import SelfSignatureFlow from "./Components/SelfSignature/SelfUpload/SelfSignatureFlow.js";
import UploadDoc from "./Components/Upload Flow/UploadDoc/UploadDoc.js";
import UploadBulk from "./Components/BulkSend/UploadBulk/UploadBulk.js";
import EmailTemplates from "./Components/EmailTemplates/EmailTemplates.js";
import CreateEmailTemplate from "./Components/EmailTemplates/CreateEmailTemplate.js";

function App() {
  const [isToken, setIsToken] = useState(false);

  useEffect(() => {
    if (sessionStorage.getItem("token")) {
      setIsToken(true);
    }
  }, [isToken]);

  const handleLogin = (token) => {
    sessionStorage.setItem("token", token);
    setIsToken(true);
  };

  return (
    <BrowserRouter>
      <I18nextProvider i18n={i18n}>
        <UserProvider>
          <CustomToaster />
          {isToken && <UserActivityStatus />}
          <Routes>
            <Route path='/' element={<Login onLogin={handleLogin} />} />
            <Route path='/signup' element={<Signup />} />
            <Route path='/forgotpassword' element={<ForgotPassword />} />

            {isToken ? (
              <Route path='/' element={<Layout />}>
                <Route path='/home' element={<Home />} />
                <Route path='/uploadDoc' element={<UploadDoc />} />
                <Route path='/upload-bulk' element={<UploadBulk />} />
                <Route path='/self-sign' element={<SelfSignatureFlow />} />
                <Route path='/PendingDocs' element={<PendingDocs />} />
                <Route path='/recentdocuments' element={<RecentDocuments />} />
                <Route path='/ShareDocs' element={<ShareDocs />} />
                <Route path='/PendingWithMe' element={<PendingStatus />} />
                <Route path='/PendingWithOthers' element={<PendingWithOthers />} />
                <Route path='/Drafts' element={<DraftStatus />} />
                <Route path='/DraftDocs' element={<DraftDocs />} />
                <Route path='/CompleteStatus' element={<Complete />} />
                <Route path='/CompleteDocs' element={<CompleteDocs />} />
                <Route path='/InActive' element={<InactiveStatuses />} />
                <Route path='/InactiveDocs' element={<InactiveDocs />} />
                <Route path='/account-settings' element={<CreateTeam />} />
                <Route path='/Declined' element={<Declined />} />
                <Route path='/DeclineDocs' element={<DeclinedDocs />} />
                <Route path='/RecallDocuments' element={<RecallDocuments />} />
                <Route path='/RecallDocs' element={<RecallDocs />} />
                <Route path='/templatesPage' element={<TemplatesPage />} />
                <Route path='/createTemplates' element={<Templates />} />
                <Route path='/editTemplates' element={<Templates />} />
                <Route path='/inactiveTemplates' element={<InactiveTemplates />} />
                <Route path='/deactivatedTemplates' element={<DeactivatedTemplates />} />
                <Route path='/User-Profile' element={<UserProfile />} />
                <Route path='/auth-integration' element={<CreateWebhook />} />
                <Route path='/mac-ai' element={<OpenAIComponent />} />
                <Route path='/manually-generate-docs' element={<ManuallyGenerateComponent />} />
                <Route path='/mac-ai-docs' element={<ListOfDocuments />} />
                <Route path='/manual-generated-documents' element={<ManualDosList />} />
                <Route path='/all-folders' element={<Folder />} />
                <Route path='/folders/:folderName/:folderId' element={<ViewDocuments />} />
                <Route path='/sub-folders/:folderName/:subFolderID' element={<ViewSubFolderDocuments />} />
                <Route path='/bulk-signatories/:docName/:registerSignID/:masterDocID' element={<BulkSignatoriesList />} />
                <Route path='/bulk-documents' element={<BulkDocuments />} />
                <Route path='/email-templates' element={<EmailTemplates />} />
                <Route path='/create-email-template' element={<CreateEmailTemplate />} />
              </Route>
            ) : (
              <Route path='/' element={<Login />} />
            )}
            <Route path='/SignNow/id/:id/*' element={<SignaturePage />} />
            <Route path='/MobileAppSignPage/id/:id/*' element={<Secondstep />} />
            <Route path='/MobileAppTemplateSignPage/id/:id/*' element={<MobileTemplatesSecondStep />} />
            <Route path='/signedMessage' element={<SignedMessage message='The document is signed successfully! ' />} />
            <Route path='/alreadySigned' element={<SignedMessage message='This document is already signed' />} />
            <Route path='/viewMessage' element={<SignedMessage message='This document is viewed successfully' />} />
            <Route path='/declinedMessage' element={<DeclinedMessage message='This document is declined successfully' />} />
            <Route path='/alreadyDeclined' element={<DeclinedMessage message='This document is already Declined' />} />
            <Route path='/LinkExpired' element={<LinkExpire message='Document is not accessible because link is expired' />} />
            <Route path='/docRecalled' element={<DocumentRecalled message='This document has been recalled' />} />
            <Route path='/Signed/id/:id/*' element={<SignedPage />} />
            <Route path='/DownloadSignedPage/id/:id/*' element={<DownloadSignedPage />} />
            <Route path='/DownloadPdfPage/id/:id/*' element={<DownloadPdfPage />} />
            <Route path='/DownloadAuditTrail/id/:id/*' element={<AuditTrailSignaturePage url='/v1.0/api/GetSignActivityVerification' />} />
            <Route path='/AuditTrail/id/:id/*' element={<AuditTrail url='/v1.0/api/GetSignActivityCompleted' />} />
            <Route path='/ChangingPassword/id/*' element={<ResetPassword />} />
            <Route path='/AIGeneratedPDF/id/:id/*' element={<AIGeneratedPDF />} />
            {/* <Route path='/google-Login' element={<GoogleLogin />} /> */}

            {/*route for 404 page */}
            <Route path='*' element={<PageNotFound />} />
          </Routes>
        </UserProvider>
      </I18nextProvider>
    </BrowserRouter>
  );
}

export default App;
