import React, { useEffect } from "react";
import "./SignedMessage.css";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import thankyouIcon from "../../assets/images/thankyouIcon.svg";

const SignedMessage = ({ message }) => {
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      pageNavigation();
    }, 5000);
  }, []);

  const pageNavigation = () => {
    if (sessionStorage.getItem("token")) {
      navigate("/home");
    } else {
      navigate("/");
    }
  };

  return (
    <div className='signed-message-container'>
      <div className='message-box d-flex flex-column justify-content-center align-items-center'>
        <img src={thankyouIcon} alt='iconLoading' size={50} className='success-icon' />
        <h4 style={{ color: "#24B032", marginTop: "5px" }}>Thank You !</h4>
        <div className='mt-3'>
          <p className='m-0'>{message}</p>
          <p>Shortly you will find a confirmation in your email.</p>
        </div>

        <Button variant='none' onClick={pageNavigation} style={{ backgroundColor: "#24B032", outline: "none", color: "white" }}>
          Back to Home
        </Button>
      </div>
    </div>
  );
};

export default SignedMessage;
