import React, { useState } from "react";
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import { loginRequest } from "./authConfig"; // Ensure authConfig is properly set up
import oneDriveIcon from "../../assets/images/oneDriveIntegrate.svg";
import { Form } from "react-bootstrap";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import axios from "../../Api/api"; // Import your axios instance
import Loader from "../Loader/Loader";

// Function to convert blob to Base64
const blobToBase64 = (blob) => {
  const reader = new FileReader();
  return new Promise((resolve, reject) => {
    reader.onloadend = () => {
      const base64String = reader.result.split(",")[1]; 
      resolve(base64String);
    };
    reader.onerror = reject; 
    reader.readAsDataURL(blob); 
  });
};

export default function OneDriveIntegration({ uploadedFiles, setUploadedFiles, masterDocIDs, setMasterDocIDs, fileToggleStates, setFileToggleStates, registerSignID, setRegisterSignID, email }) {
  const { instance } = useMsal();
  const { t } = useTranslation("");
  const [isLoading, setIsLoading] = useState(false);
  const token = sessionStorage.getItem("token");

  const handleLogin = () => {
    instance
      .loginPopup(loginRequest)
      .then((loginResponse) => {
        // console.log("Login successful:", loginResponse);
        launchOneDrivePicker();
      })
      .catch((e) => {
        console.error("Login failed:", e);
      });
  };

  const handleLogout = () => {
    instance.logoutPopup({
      postLogoutRedirectUri: "/",
    });
  };

  // Function to fetch file content from download URL and convert to Base64
  const fetchFileAsBase64 = async (downloadUrl, fileName) => {
    try {
      const response = await fetch(downloadUrl);
      if (!response.ok) {
        throw new Error(`Failed to fetch file: ${response.statusText}`);
      }
      const blob = await response.blob();

      const base64String = await blobToBase64(blob);
      //   console.log(`Base64 of ${fileName}: ${base64String}`);

      return base64String;
    } catch (error) {
      console.error("Error fetching and converting file to Base64:", error);
      return null;
    }
  };

  // Function to launch the OneDrive picker
  const launchOneDrivePicker = () => {
    if (window.OneDrive) {
      const odOptions = {
        clientId: "a41b6223-e4ff-4af1-ae77-6080181d18bd",
        // clientId: "a88bf968-352c-4613-900f-75d4c0292b80",
        action: "share",
        multiSelect: true,
        openInNewWindow: true,
        success: async function (files) {
          //   console.log("Files selected:", files);
          if (files && files.value) {
            const base64Files = [];
            for (const file of files.value) {
              const downloadUrl = file["@microsoft.graph.downloadUrl"];
              //   console.log(`Download URL for ${file.name}: ${downloadUrl}`);

              const base64File = await fetchFileAsBase64(downloadUrl, file.name);
              if (base64File) {
                // console.log(`Successfully converted ${file.name} to Base64.`);
                base64Files.push({
                  name: file.name,
                  base64: base64File,
                  MasterDocID: null,
                });
              }
            }
            handleFileUpload(base64Files);
          }
          handleLogout();
        },
        cancel: function () {
          console.log("Picker was cancelled");
        },
        error: function (e) {
          console.error(e);
        },
      };

      try {
        window.OneDrive.open(odOptions);
      } catch (error) {
        // console.error("Error launching OneDrive Picker:", error);
      }
    } else {
      // console.error("OneDrive Picker SDK is not loaded yet.");
    }
  };


  //fix the bug of time Caught error
window.addEventListener("message", function (e) {
  // console.log("Message event data:", e.data);
  if (typeof e.data === "string") {
    if (e.data.indexOf("[OneDrive-FromPicker]") !== -1) {
      // console.log("OneDrive picker message received.");
    }
  } else {
    // console.warn("Unexpected message data type:", typeof e.data);
  }
});

  // Function to upload the selected files to your API
  const handleFileUpload = async (base64Files) => {
    const duplicateFiles = base64Files.filter((file) => uploadedFiles.includes(file.name));
    if (duplicateFiles.length > 0) {
      toast.error(t("uploadDocFlow.alreadyExits"));
      return;
    }
    setIsLoading(true);

    const body = {
      ReferenceNumber: "",
      Description: "",
      CreatedBy: email,
      IsSerial: false,
      IsBulk: false,
      isOnlySigner: false,
      registerSignID: registerSignID,
      PrimaryDocs: base64Files.map((uploadedFile) => {
        const fileExtension = uploadedFile.name.split(".").pop();
        return {
          DocName: uploadedFile.name,
          DocExtension: fileExtension,
          DocFile: uploadedFile.base64,
          IsPasswordProtected: false,
          IsBulk: false,
        };
      }),
    };

    try {
      const response = await axios.post("/v1.0/api/RegisterDocs", body, {
        headers: {
          Authorization: "Bearer " + token,
        },
      });

      if (response.data.successStatus) {
        // Update UI after successful upload
        setRegisterSignID(response.data.response.registerSignID);
        localStorage.setItem("registerSignID", response.data.response.registerSignID);

        setMasterDocIDs((prevIDs) => [...prevIDs, response.data.response.masterDocs[0].masterDocIDs]);

        setUploadedFiles([...uploadedFiles, ...base64Files.map((uploadedFile) => uploadedFile.name)]);

        setFileToggleStates((prevStates) => [...prevStates, ...base64Files.map(() => false)]);

        toast.success(t("uploadDocFlow.fileuploadSuccessfully"));
        setIsLoading(false);
      } else {
        toast.error(t("uploadDocFlow.apireqFailed"));
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Error uploading file to API:", error);
      toast.error(t("uploadDocFlow.errorUploadFile"));
      setIsLoading(false);
    }
  };

  return (
    <div>
      {isLoading && (
        <div className='loader-overlay'>
          <Loader />
        </div>
      )}
      <div className='uplad_box' onClick={handleLogin}>
        <div className='upload_trigger_bx'>
          <img alt='oneDriveIcon' src={oneDriveIcon} />
          <div className='upload_doc_part'>
            <Form.Group controlId='file-input-add' className='file-upload_temp'>
              <Form.Label>{t("uploadDocFlow.OneDriveDocuments")}</Form.Label>
              <Form.Control className='temp_file-input-additional' name='file' accept='.pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx' multiple />
            </Form.Group>
          </div>
        </div>
      </div>
    </div>
  );
}
