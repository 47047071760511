import React, { useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import Papa from "papaparse";
import uploadCsv from "../../../assets/images/Upload CSV.svg";
import { LuDownload } from "react-icons/lu";
import { AiOutlineDelete } from "react-icons/ai";
import PaperPlus from "../../../assets/images/PaperPlus2.svg";
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Stack, Pagination } from "@mui/material";
import "./style.css";
import axios from "../../../Api/api";
import Loader from "../../Loader/Loader";
import { toast } from "react-toastify";

function PlaceBulkSignatories({ setActiveStep, setCompletedSteps }) {
  const [isLoading, setIsLoading] = useState(false);
  const [fileName, setFileName] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [isFileValid, setIsFileValid] = useState(false);
  const { t } = useTranslation("");

  useEffect(() => {
    const storedData = sessionStorage.getItem("parsedData");
    const storedFileName = sessionStorage.getItem("fileName");

    if (storedData && storedFileName) {
      setData(JSON.parse(storedData));
      setFileName(storedFileName);
    }
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const onDrop = (acceptedFiles) => {
    const csvFile = acceptedFiles.find((file) => file.type === "text/csv");
    if (csvFile) {
      setFileName(csvFile.name);
      parseCSV(csvFile);
      fileToBase64(csvFile);
    } else {
      alert("Please upload only CSV files.");
    }
  };

  const validateEmail = (email) => {
    const validateEmailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return validateEmailRegex.test(String(email).toLowerCase());
  };

  const parseCSV = async (file) => {
    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: async (result) => {
        const parsedData = result.data.map((row) => ({
          name: row.name || row.Name,
          email: row.email || row.Email,
          emailStatus: validateEmail(row.email || row.Email) ? "Valid" : "Invalid",
        }));
        setData(parsedData);
        setIsFileValid(parsedData.every((row) => row.emailStatus === "Valid"));
        sessionStorage.setItem("parsedData", JSON.stringify(parsedData));
        sessionStorage.setItem("fileName", file.name);
      },
    });
  };

  const fileToBase64 = (file) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      const base64Data = reader.result.split(",")[1];

      //pass the base64 as param to api
      handleCreateBulkSignatories(base64Data);
    };
    reader.readAsDataURL(file);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: ".csv, text/csv",
  });

  const handleDelete = () => {
    setShowModal(true);
  };

  const confirmDelete = async () => {
    try {
      setIsLoading(true);
      const token = sessionStorage.getItem("token");
      const registerSignID = localStorage.getItem("registerSignID");

      await axios.delete(`/v1.0/api/DeleteSignatoriesFromCSV?RegisterSignID=${registerSignID}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      toast.success(t("uploadDocFlow.signatoriesDeleted"));
      setFileName(null);
      setData([]);
      setShowModal(false);
      setIsFileValid(false);
      sessionStorage.removeItem("parsedData");
      sessionStorage.removeItem("fileName");
      sessionStorage.removeItem("base64CSV");
    } catch (error) {
      console.error("There was an error deleting the signatories!", error);
      toast.error(t("uploadDocFlow.failedDelete"));
    } finally {
      setIsLoading(false);
    }
  };

  const cancelDelete = () => {
    setShowModal(false);
  };

  const handleBack = () => {
    sessionStorage.removeItem("parsedData");
    sessionStorage.removeItem("fileName");
    sessionStorage.removeItem("base64CSV");
    setActiveStep(1);
    setCompletedSteps([0]);
  };

  const handleContinue = () => {
    setActiveStep(3);
    setCompletedSteps([1, 2]);
  };

  const handleCreateBulkSignatories = async (base64Data) => {
    try {
      setIsLoading(true);
      const token = sessionStorage.getItem("token");
      const registerSignID = localStorage.getItem("registerSignID");

      await axios.post(
        "/v1.0/api/CreateBulkSignatoryFromCSV",
        {
          RegisterSignID: registerSignID,
          Base64CSV: base64Data,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      toast.success(t("uploadDocFlow.BulkCreated"));
    } catch (error) {
      console.error("There was an error making the request!", error);
      sessionStorage.removeItem("parsedData");
      sessionStorage.removeItem("fileName");
      sessionStorage.removeItem("base64CSV");

      toast.error(t("uploadDocFlow.FailedBulk"));
    } finally {
      setIsLoading(false);
    }
  };

  const columns = [
    { label: t("tablesLabel.name"), key: "name" },
    { label: t("tablesLabel.email"), key: "email" },
    { label: t("tablesLabel.status"), key: "emailStatus" },
  ];

  const downloadSampleCSV = () => {
    const sampleData = [
      ["Name", "Email"],
      ["John Doe", "john.doe@example.com"],
      ["Jane Smith", "jane.smith@example.com"],
    ];

    const csvContent = sampleData.map((e) => e.join(",")).join("\n");
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", "sample_signers.csv");
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  useEffect(() => {
    setIsFileValid(data.length > 0 && data.every((row) => row.emailStatus === "Valid"));
  }, [data]);

  return (
    <div className='container mt-3'>
      {isLoading && (
        <div className='loader-overlay'>
          <Loader />
        </div>
      )}
      <div className='upload-csv' onClick={downloadSampleCSV}>
        <LuDownload size={22} />
        <label className='m-0'>{t("uploadDocFlow.DownloadCSV")}</label>
      </div>

      {!fileName && (
        <div {...getRootProps()} className='csv-drag-container'>
          <input {...getInputProps()} />
          <img src={uploadCsv} alt='Csv icon' />
          <label>
            {t("uploadDocFlow.DragCsv")}
            <label className='drag-here'>{t("uploadDocFlow.here")}</label>
          </label>
        </div>
      )}

      {fileName && (
        <div className='file-info'>
          <label className='file-name'>
            <img src={PaperPlus} alt='File Icon' />
            {fileName}
          </label>
          <AiOutlineDelete size={22} className='delete-icon' onClick={handleDelete} />
        </div>
      )}

      {fileName && (
        <Grid className='my-3'>
          <div className='py-3 p-3 container pendingtable '>
            <TableContainer component={Paper}>
              <Table style={{ border: "none" }}>
                <TableHead className='customTableHead'>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell key={column.label} style={{ backgroundColor: "#eee" }} className='TableHeaderCell'>
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
                    <TableRow key={index}>
                      <TableCell className='TableCell'>{row.name}</TableCell>
                      <TableCell className='TableCell'>{row.email}</TableCell>
                      <TableCell className='TableCell'>
                        <label
                          style={{
                            padding: "2px 12px",
                            borderRadius: "5px",
                            backgroundColor: row.emailStatus === "Valid" ? "#00cc4533" : "#FCD9D9",
                            color: "black",
                          }}
                        >
                          {row.emailStatus}
                        </label>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <div style={{ display: "flex", justifyContent: "space-between", marginTop: "1rem" }}>
              <div className='showHideNoDocs'>
                {page * rowsPerPage + 1} - {Math.min((page + 1) * rowsPerPage, data.length)} {t("tablesLabel.signatoriesof")} {data.length} {t("tablesLabel.signatories")}
              </div>
              <Stack spacing={2}>
                <Pagination
                  count={Math.ceil(data.length / rowsPerPage)}
                  variant='outlined'
                  shape='rounded'
                  page={page + 1}
                  onChange={(event, newPage) => handleChangePage(event, newPage - 1)}
                  sx={{ "& .Mui-selected": { background: "#0C9AD7 !important", color: "white" } }}
                />
              </Stack>
            </div>
          </div>
        </Grid>
      )}

      <Modal show={showModal} backdrop='static' keyboard={false} onHide={() => setShowModal(false)} dialogClassName='custom-modal-width'>
        <Modal.Header>
          <Modal.Title>{t("uploadDocFlow.confirmDelete")}</Modal.Title>
        </Modal.Header>
        <Modal.Body className='px-3 py-2'>
          <p> {t("uploadDocFlow.sureDelete")}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='none' className='modalCancelButton' onClick={cancelDelete}>
            {t("uploadDocFlow.cancel")}
          </Button>
          <Button variant='none' className='applyBtn' onClick={confirmDelete}>
            {t("uploadDocFlow.delete")}
          </Button>
        </Modal.Footer>
      </Modal>

      <div className='fixed-button-container'>
        <Button className='backBtn' id='hide_part_one' onClick={handleBack}>
          {t("uploadDocFlow.back")}
        </Button>

        <Button className='Continue-btn' onClick={handleContinue} id='hide_part_one' disabled={!isFileValid}>
          {t("uploadDocFlow.Continue")}
        </Button>
      </div>
    </div>
  );
}

export default PlaceBulkSignatories;
