import React, { useEffect, useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Button, Col, Form, Row, Dropdown, Modal } from "react-bootstrap";
import axios from "../../../../Api/api";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import dragIcon from "../../../../assets/images/dragIcon.png";
import { IoIosAddCircleOutline } from "react-icons/io";
import Loader from "../../../../Components/Loader/Loader";
import deleteSign from "../../../../assets/images/deleteSign.svg";
import { useUser } from "../../../../Context/UserContext";
import { FaEye } from "react-icons/fa";
import { BsVectorPen } from "react-icons/bs";
import "./AddRecipient.css";

const AddRecipient = ({ setActiveStep, setCompletedSteps }) => {
  const [recipientForms, setRecipientForms] = useState([
    {
      name: "",
      notificationType: "Email",
      email: "",
      countryCode: "",
      phone: "",
      company: "",
      signOrder: 1,
      signatureType: "",
      signColor: "#ffe987",
      signPage: "FirstPage",
      signPosition: "TopLeft",
      isExpiring: true,
      ismobile: false,
      expiryDate: "2025-12-31",
      templateSignatoryID: null,
    },
  ]);
  const [signatureTypes, setSignatureTypes] = useState([]);
  const [notificationTypes, setNotificationTypes] = useState([]);
  const [signatoryColor, setSignatoryColor] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [deletingSignatoryID, setDeletingSignatoryID] = useState(null);
  const [deletingIndex, setDeletingIndex] = useState(null);
  const [errors, setErrors] = useState([]);
  const { t } = useTranslation();
  const { user } = useUser();
  const navigate = useNavigate();
  const [teamMemberEmails, setTeamMemberEmails] = useState([]);
  const [countryCodes, setCountryCodes] = useState([]);

  const [isContinueDisabled, setIsContinueDisabled] = useState(false);
  const [teamMemberNames, setTeamMemberNames] = useState([]);

  const token = sessionStorage.getItem("token");
  const storedTemplateID = localStorage.getItem("templateID");

  // Fetching country codes for the dropdown
  useEffect(() => {
    axios
      .get("/v1.0/api/GetLookups?LookupType=countryCode", {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        if (response.data.successStatus) {
          setCountryCodes(response.data.response);
        }
      })
      .catch(handleAuthError);
  }, []);

  useEffect(() => {
    axios
      .get("/v1.0/api/GetLookups?LookupType=signatureType", {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        if (response.data.successStatus) {
          const signatureTypeOptions = response.data.response.map((item) => item.name);
          setSignatureTypes(signatureTypeOptions);
          setRecipientForms((prevRecipientForms) =>
            prevRecipientForms.map((form) => ({
              ...form,
              signatureType: signatureTypeOptions[0],
            }))
          );
        }
      })
      .catch(handleAuthError);
  }, []);

  useEffect(() => {
    axios
      .get("/v1.0/api/GetLookups?LookupType=NotificationType", {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        if (response.data.successStatus) {
          const notificationTypeOptions = response.data.response.map((item) => item.name);
          setNotificationTypes(notificationTypeOptions);
        }
      })
      .catch(handleAuthError);
  }, []);

  useEffect(() => {
    axios
      .get("/v1.0/api/GetLookups?LookupType=SignColor", {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        if (response.data.successStatus) {
          const formColors = response.data.response.map((color) => color.name);
          setSignatoryColor(formColors);
          setRecipientForms((prevRecipientForms) =>
            prevRecipientForms.map((form, index) => ({
              ...form,
              signColor: formColors[index % formColors.length],
            }))
          );
        }
      })
      .catch(handleAuthError);
  }, []);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`/v1.0/api/GetTemplateData?TemplateID=${storedTemplateID}`, {
        headers: {
          Authorization: "Bearer " + token,
        },
      });

      if (response.data.successStatus) {
        setIsLoading(false);
        const { templateSignatory } = response.data.response;

        if (Array.isArray(templateSignatory) && templateSignatory.length > 0) {
          const mappedForms = templateSignatory.map((signatory) => ({
            name: signatory.name || "",
            notificationType: signatory.notificationType || "Email",
            email: signatory.email || "",
            countryCode: signatory.countryCode || "",
            phone: signatory.mobile || "",
            company: signatory.company || "",
            signOrder: signatory.signOrder || 1,
            signatureType: signatory.signatureType || "",
            signColor: signatory.signatureColor || "#ffe987",
            signPage: signatory.signPage || "FirstPage",
            signPosition: signatory.signPosition || "TopLeft",
            isExpiring: signatory.isExpiring || true,
            ismobile: signatory.ismobile || false,
            expiryDate: signatory.expiryDateFormatted || "2025-12-31",
            templateSignatoryID: signatory.templateSignatoryID || null,
          }));

          setRecipientForms(mappedForms);
        }
      }
    } catch (error) {
      setIsLoading(false);
      handleAuthError(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  //Get Team APi
  useEffect(() => {
    const fetchTeamMembers = async () => {
      try {
        if (!user.AccountID) {
          return;
        }
        setIsLoading(true);
        const response = await axios.get(`/v1.0/api/GetTeam?AccountID=${user.AccountID}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const emails = response.data.response.map((member) => member.emailID);
        const names = response.data.response.map((member) => member.fullName);
        setTeamMemberEmails(emails);
        setTeamMemberNames(names);
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchTeamMembers();
  }, [user.AccountID, token]);

  const handleAuthError = (error) => {
    if (error?.response?.data?.statusCode == 2 || error?.response?.data?.statusCode == 4) {
      sessionStorage.removeItem("token");
      localStorage.clear();
      navigate("/");
      toast.info(t("uploadDocFlow.sessionExpire"), {
        position: "top-right",
      });
    }
  };

  const validateForm = () => {
    let hasErrors = false;
    const emailSet = new Set();
    const mobileSet = new Set();
    const newErrors = recipientForms.map((form) => {
      const formErrors = {};

      if (!form.name) {
        formErrors.name = t("uploadDocFlow.requiredName");
        hasErrors = true;
      }

      if (form.notificationType === "Email") {
        if (!form.email) {
          formErrors.email = t("uploadDocFlow.emailRequired");
          hasErrors = true;
        } else if (emailSet.has(form.email)) {
          formErrors.email = t("uploadDocFlow.alreadyEmailUsed");
          hasErrors = true;
        } else {
          emailSet.add(form.email);
        }
      }

      if (form.notificationType === "SMS" || form.notificationType === "WhatsApp") {
        if (!form.phone || !form.countryCode) {
          formErrors.phone = t("logScreens.mobileRequired");
          hasErrors = true;
        } else {
          const fullPhoneNumber = `${form.countryCode}${form.phone}`;
          if (mobileSet.has(fullPhoneNumber)) {
            formErrors.phone = t("uploadDocFlow.mobileDuplicate");
            hasErrors = true;
          } else {
            mobileSet.add(fullPhoneNumber);
          }
        }
      }

      return formErrors;
    });

    setErrors(newErrors);
    return !hasErrors;
  };

  const handleFormChange = (index, field, value) => {
    const newRecipientForms = [...recipientForms];
    const newErrors = [...errors];

    newRecipientForms[index][field] = value;

    // Logic to handle the setting of email and mobile fields based on NotificationType
    if (field === "notificationType") {
      if (value === "Email") {
        newRecipientForms[index].phone = "";
        newRecipientForms[index].countryCode = "";
      } else if (value === "SMS" || value === "WhatsApp") {
        newRecipientForms[index].email = "";
      }
    }

    // Clear the error for the specific field being updated
    if (newErrors[index]) {
      delete newErrors[index][field];
      if (Object.keys(newErrors[index]).length === 0) {
        newErrors[index] = null;
      }
    }

    setRecipientForms(newRecipientForms);
    setErrors(newErrors);
  };

 const handleAddRecipient = () => {
   setRecipientForms((prevForms) => [
     ...prevForms,
     {
       name: "",
       notificationType: "Email",
       email: "",
       countryCode: "",
       phone: "",
       company: "",
       signOrder: prevForms.length + 1,
       signatureType: signatureTypes.length > 0 ? signatureTypes[0] : "",
       signColor: signatoryColor[prevForms.length % signatoryColor.length],
     },
   ]);
 };


const handleDeleteRecipient = async (signatoryID, index) => {
  if (signatoryID) {
    setIsLoading(true);
    try {
      const response = await axios.put(
        "/v1.0/api/DeleteTemplateSignatory",
        {
          TemplateSignatoryID: signatoryID,
        },
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );

      if (response.data.successStatus) {
        toast.success(t("uploadDocFlow.signatoryDeleted"));
      } else {
        toast.error(t("uploadDocFlow.ErrDeletSignatory"));
      }
    } catch (error) {
      toast.error(t("uploadDocFlow.ErrDeletSignatory"));
    } finally {
      setIsLoading(false);
    }
  }

  // Remove the form and recalculate sign orders
  setRecipientForms((prevForms) => {
    const updatedForms = prevForms.filter((_, idx) => idx !== index);

    // Recalculate sign orders
    return updatedForms.map((form, idx) => ({
      ...form,
      signOrder: idx + 1,
    }));
  });

  setShowConfirmModal(false); // Close confirmation modal
};

  const handleShowConfirmModal = (templateSignatoryID, index) => {
    setDeletingSignatoryID(templateSignatoryID);
    setDeletingIndex(index);
    setShowConfirmModal(true);
  };

  const handleCloseConfirmModal = () => {
    setShowConfirmModal(false);
  };

const handleDragEnd = (result) => {
  if (!result.destination) return;

  const reorderedForms = Array.from(recipientForms);
  const [removed] = reorderedForms.splice(result.source.index, 1);
  reorderedForms.splice(result.destination.index, 0, removed);

  // Update signOrder based on the new order
  const updatedForms = reorderedForms.map((form, index) => ({
    ...form,
    signOrder: index + 1,
  }));

  setRecipientForms(updatedForms);
};


  const handleAddYourself = () => {
    const { CountryCode, Mobile } = splitMobileNumber(user.mobileNumber);

    setRecipientForms([
      ...recipientForms,
      {
        name: user.fullName,
        email: user.email,
        countryCode: CountryCode,
        phone: Mobile,
        company: "",
        notificationType: "Email",
        signatureType: signatureTypes.length > 0 ? signatureTypes[0] : "",
        signColor: signatoryColor[recipientForms.length % signatoryColor.length],
        signOrder: recipientForms.length + 1,
      },
    ]);
  };

  const splitMobileNumber = (mobileNumber) => {
    const [CountryCode, Mobile] = mobileNumber.split("-");
    return {
      CountryCode,
      Mobile,
    };
  };

  const handleBack = async () => {
    setActiveStep(1);
    setCompletedSteps([0]);
  };

const handleContinue = async () => {
  if (!validateForm()) {
    return;
  }

  setIsContinueDisabled(true);
  setIsLoading(true);

  try {
    const requestBody = recipientForms.map((form) => ({
      TemplateID: storedTemplateID,
      Name: form.name,
      NotificationType: form.notificationType,
      Email: form.notificationType === "Email" ? form.email : "",
      CountryCode: form.notificationType === "SMS" || form.notificationType === "WhatsApp" ? form.countryCode : "",
      Mobile: form.notificationType === "SMS" || form.notificationType === "WhatsApp" ? form.phone : "",
      Company: form.company,
      SignOrder: form.signOrder, // Ensure this is correct
      SignatureType: form.signatureType,
      SignatureColor: form.signColor,
      SignPage: form.signPage,
      SignPosition: form.signPosition,
      Ismobile: form.ismobile,
      ExpiryDate: form.expiryDate,
      IsExpiring: form.isExpiring,
    }));

    const response = await axios.post("/v1.0/api/CreateBulkTemplateSignatories", requestBody, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });

    if (response.data.successStatus) {
      setActiveStep(3);
      setCompletedSteps([1, 2]);
    } else {
      toast.error(t("uploadDocFlow.errorCreatingBulkSignatories"));
    }
  } catch (error) {
    toast.error(t("uploadDocFlow.errorCreatingBulkSignatories"));
  } finally {
    setIsLoading(false);
    setIsContinueDisabled(false);
  }
};


  return (
    <div className='signatories-forms'>
      {isLoading && (
        <div className='loader-overlay'>
          <Loader />
        </div>
      )}
      <div className='signatory-form-container'>
        <Form>
          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId='forms'>
              {(provided) => (
                <Form ref={provided.innerRef} {...provided.droppableProps}>
                  {recipientForms &&
                    recipientForms.map((form, index) => (
                      <Draggable key={index} draggableId={index.toString()} index={index}>
                        {(provided) => (
                          <div ref={provided.innerRef} {...provided.draggableProps}>
                            <Row className='d-flex justify-content-start align-items-start'>
                              <Col className='mt-3'>
                                <div className='signatory-form' style={{ border: `1px solid ${form.signColor}` }}>
                                  <Row>
                                    <Col md={5} className='signatory-form-col action-buttons d-flex justify-content-end align-items-center flex-wrap'></Col>

                                    <div className='signatory-form-col action-buttons d-flex align-items-center justify-content-between flex-wrap'>
                                      <Col md={4} className='d-flex gap-3 align-items-center signatory-form-col' {...provided.dragHandleProps}>
                                        <img src={dragIcon} alt='drag' style={{ cursor: "grab" }} />
                                        <span style={{ fontWeight: "500" }}>
                                          {t("uploadDocFlow.signatory")} {index + 1}
                                        </span>
                                      </Col>

                                      <div className='d-flex align-items-center gap-2'>
                                        <Form.Group controlId={`formSignatureMethod${index}`}>
                                          <Dropdown onSelect={(e) => handleFormChange(index, "signatureType", e)}>
                                            <Dropdown.Toggle className=' d-flex align-items-center gap-2' variant='' id={`signatureType${index}`}>
                                              {form.signatureType === "Needs To Sign" && <BsVectorPen />}
                                              {form.signatureType === "View Only" && <FaEye />}
                                              {form.signatureType}
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu style={{ left: "auto", right: 0, minWidth: "unset", padding: "0px" }}>
                                              {signatureTypes.map((signature, sigIndex) => (
                                                <Dropdown.Item key={sigIndex} eventKey={signature} className='d-flex align-items-center gap-2'>
                                                  {signature === "Needs To Sign" && <BsVectorPen />}
                                                  {signature === "View Only" && <FaEye />}
                                                  {signature}
                                                </Dropdown.Item>
                                              ))}
                                            </Dropdown.Menu>
                                          </Dropdown>
                                        </Form.Group>
                                        <Button variant='none' className='p-0' onClick={() => handleShowConfirmModal(form.templateSignatoryID, index)}>
                                          <img src={deleteSign} alt='icon-loading' />
                                        </Button>
                                      </div>
                                    </div>

                                    <Col md={5}>
                                      <Form.Group controlId={`formSignatoryName${index}`}>
                                        <Form.Label>
                                          {t("uploadDocFlow.name")} <span className='required'>*</span>
                                        </Form.Label>
                                        <Form.Control
                                          list='teamNames'
                                          type='text'
                                          value={form.name}
                                          onChange={(e) => handleFormChange(index, "name", e.target.value)}
                                          placeholder={t("uploadDocFlow.enterName")}
                                        />
                                        {errors[index]?.name && <div className='error-message'>{errors[index].name}</div>}
                                      </Form.Group>
                                    </Col>

                                    <Col md={2}>
                                      <Form.Group controlId={`formNotificationMethod${index}`}>
                                        <Form.Label>{t("uploadDocFlow.notificationType")}</Form.Label>
                                        <Dropdown onSelect={(e) => handleFormChange(index, "notificationType", e)}>
                                          <Dropdown.Toggle variant='' id={`notificationType${index}`}>
                                            {form.notificationType}
                                          </Dropdown.Toggle>
                                          <Dropdown.Menu>
                                            {notificationTypes.map((notification, notIndex) => (
                                              <Dropdown.Item key={notIndex} eventKey={notification}>
                                                {notification}
                                              </Dropdown.Item>
                                            ))}
                                          </Dropdown.Menu>
                                        </Dropdown>
                                      </Form.Group>
                                    </Col>

                                    {form.notificationType === "Email" && (
                                      <Col md={5}>
                                        <Form.Group controlId={`formEmailAddress${index}`}>
                                          <Form.Label>
                                            {t("uploadDocFlow.emailAddress")} <span className='required'>*</span>
                                          </Form.Label>
                                          <Form.Control
                                            list='teamEmails'
                                            type='email'
                                            value={form.email}
                                            onChange={(e) => handleFormChange(index, "email", e.target.value)}
                                            placeholder={t("uploadDocFlow.enterEmail")}
                                          />
                                          {errors[index]?.email && <div className='error-message'>{errors[index].email}</div>}
                                        </Form.Group>
                                      </Col>
                                    )}

                                    {(form.notificationType === "SMS" || form.notificationType === "WhatsApp") && (
                                      <Col md={5}>
                                        <Form.Group controlId={`formPhone${index}`}>
                                          <Form.Label>
                                            {t("logScreens.mobileNumber")}
                                            <span className='required'>*</span>
                                          </Form.Label>
                                          <div className='input-group'>
                                            <Form.Select
                                              as='select'
                                              value={form.countryCode}
                                              onChange={(e) => handleFormChange(index, "countryCode", e.target.value)}
                                              className={`form-control`}
                                              style={{ width: "15%" }}
                                            >
                                              <option value='' disabled>
                                                {t("logScreens.Codes")}
                                              </option>
                                              {countryCodes.map((country) => (
                                                <option key={country.lookupID} value={country.lookupID}>
                                                  {country.lookupID}
                                                </option>
                                              ))}
                                            </Form.Select>
                                            <Form.Control
                                              type='text'
                                              value={form.phone}
                                              onChange={(e) => handleFormChange(index, "phone", e.target.value)}
                                              placeholder={t("logScreens.mobileNumber")}
                                              className={`form-control`}
                                              style={{ width: "calc(85% - 40px)" }}
                                            />
                                          </div>
                                          {errors[index]?.phone && <div className='error-message'>{errors[index].phone}</div>}
                                        </Form.Group>
                                      </Col>
                                    )}
                                  </Row>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        )}
                      </Draggable>
                    ))}
                  {provided.placeholder}
                </Form>
              )}
            </Droppable>
          </DragDropContext>
        </Form>
      </div>

      <Row>
        <Col md={11}>
          <div className='add-signatory-btns d-flex justify-content-start mb-5 pb-5 gap-3'>
            <Button variant='' className='ImageCancelBtn p-2' onClick={handleAddRecipient}>
              <IoIosAddCircleOutline size={22} />
              <span> {t("uploadDocFlow.addRecipient")}</span>
            </Button>

            <Button variant='' className='ImageCancelBtn p-2' onClick={handleAddYourself}>
              <IoIosAddCircleOutline size={22} />
              <span> {t("uploadDocFlow.addYourself")}</span>
            </Button>
          </div>
        </Col>
      </Row>

      <div className='fixed-button-container'>
        <Button className='backBtn' id='hide_part_one' onClick={handleBack}>
          {t("uploadDocFlow.back")}
        </Button>

        <Button className='Continue-btn' id='hide_part_one' onClick={handleContinue} disabled={isContinueDisabled}>
          {t("uploadDocFlow.Continue")}
        </Button>
      </div>

      {/* delete signatory Confirmation Modal */}
      <Modal show={showConfirmModal} onHide={handleCloseConfirmModal} dialogClassName='custom-modal-width'>
        <Modal.Header closeButton>
          <Modal.Title>{t("uploadDocFlow.confirmDelete")}</Modal.Title>
        </Modal.Header>
        <Modal.Body className='px-3 py-2'>{t("uploadDocFlow.sureToDelete")}</Modal.Body>
        <Modal.Footer>
          <Button variant='' onClick={handleCloseConfirmModal} className='modalCancelButton'>
            {t("uploadDocFlow.cancel")}
          </Button>
          <Button className='applyBtn' variant='none' onClick={() => handleDeleteRecipient(deletingSignatoryID, deletingIndex)}>
            {t("uploadDocFlow.delete")}
          </Button>
        </Modal.Footer>
      </Modal>

      <datalist id='teamEmails'>
        {teamMemberEmails.map((email, idx) => (
          <option key={idx} value={email} />
        ))}
      </datalist>
      <datalist id='teamNames'>
        {teamMemberNames.map((name, idx) => (
          <option key={idx} value={name} />
        ))}
      </datalist>
    </div>
  );
};

export default AddRecipient;
