import React, { useState } from "react";
import LogoImg from "../../../assets/images/stepper-logo.svg";
import { Nav } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import "./Stepper.css"; 
import stepper_completed from "../../../assets/images/stepper_completed.svg";

const Stepper = ({ activeStep, t, completedSteps }) => {
  const location = useLocation();

  const isCompleted = (step) => completedSteps && completedSteps.includes(step);
  const isActive = (step) => activeStep === step;

  const templatesStepper = location.pathname.includes("/createTemplates") || location.pathname.includes("/editTemplates");

  return (
    <Nav className='step_number_holder d-none d-md-block'>
      <Nav.Item className='stepper-logo'>
        <Nav.Link as={Link} to='/home'>
          <img src={LogoImg} alt='imageLoading' style={{ height: "30px", width: "100px" }} />
        </Nav.Link>
      </Nav.Item>

      <Nav.Item>
        <Nav.Link className={`upload_step my-3 ${isCompleted(1) ? "completed" : isActive(1) ? "active" : "inactive"}`}>
          <span className='numb'>{isCompleted(1) ? <img src={stepper_completed} alt='completed' /> : 1}</span>
          <em>{t("Stepper.uploadDocument")}</em>
        </Nav.Link>
      </Nav.Item>
      <div className='d-flex justify-content-center align-items-center' style={{ width: "36%" }}>
        <div className={`stepper-line ${isCompleted(1) ? "stepper-line-completed" : isActive(1) ? "stepper-line-active" : ""}`}></div>
      </div>

      <Nav.Item>
        <Nav.Link className={`upload_step my-3 ${isCompleted(2) ? "completed" : isActive(2) ? "active" : "inactive"}`}>
          <span className='numb'>{isCompleted(2) ? <img src={stepper_completed} alt='completed' /> : 2}</span>
          <em>{t("Stepper.AddSigners")}</em>
        </Nav.Link>
      </Nav.Item>
      <div className='d-flex justify-content-center align-items-center' style={{ width: "36%" }}>
        <div className={`stepper-line ${isCompleted(2) ? "stepper-line-completed" : isActive(2) ? "stepper-line-active" : ""}`}></div>
      </div>

      <Nav.Item>
        <Nav.Link className={`signatories_step my-3 ${isCompleted(3) ? "completed" : isActive(3) ? "active" : "inactive"}`}>
          <span className='numb'>{isCompleted(3) ? <img src={stepper_completed} alt='completed' /> : 3}</span>
          <em>{t("Stepper.placeSignatories")}</em>
        </Nav.Link>
      </Nav.Item>

      {!templatesStepper ? (
        <>
          <div className='d-flex justify-content-center align-items-center' style={{ width: "36%" }}>
            <div className={`stepper-line ${isCompleted(3) ? "stepper-line-completed" : isActive(3) ? "stepper-line-active" : ""}`}></div>
          </div>

          <Nav.Item>
            <Nav.Link className={`sign_step my-3 ${isCompleted(4) ? "completed" : isActive(4) ? "active" : "inactive"}`}>
              <span className='numb'>{isCompleted(4) ? <img src={stepper_completed} alt='completed' /> : 4}</span>
              <em>{t("Stepper.sendAndSign")}</em>
            </Nav.Link>
          </Nav.Item>
        </>
      ) : null}
    </Nav>
  );
};

export default Stepper;
