import React, { useState, useEffect } from "react";
import axios from "../../Api/api";
import { toast } from "react-toastify";
import { Button, Form, Navbar, Offcanvas } from "react-bootstrap";
import "./style.css";
import { useTranslation } from "react-i18next";
import { IoIosAddCircleOutline } from "react-icons/io";
import { useUser } from "../../Context/UserContext";
import Loader from "../Loader/Loader";

function CreateTeamForm({ fetchTeam }) {
  const { t } = useTranslation();
  const [roles, setRoles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showCreateTeam, setShowCreateTeam] = useState(false);
  const { user } = useUser();

  const [formData, setFormData] = useState({
    AccountID: user.AccountID,
    AccountType: user.AccountType,
    FullName: "",
    UserName: "",
    RoleID: "",
    MobileNumber: "",
  });

  const token = sessionStorage.getItem("token");

  const fetchRoles = async () => {
    try {
      const response = await axios.get("v1.0/api/GetUserRoles", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.data.successStatus) {
        setRoles(response.data.response);
      } else {
        console.error(t("createTeam.errRoles"));
      }
    } catch (error) {
      console.error(t("createTeam.errRoles"));
    }
  };

  useEffect(() => {
    fetchRoles();
  }, [token, user]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "EmailID") {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
        UserName: value,
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const CreateAppUser = async () => {
    try {
      if (!formData.FullName || !formData.UserName || !formData.RoleID) {
        toast.error("Please fill in all required fields.");
        return;
      }
      setIsLoading(true);
      const response = await axios.post("/v1.0/api/CreateAppUser", formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.data.statusCode === "1") {
        toast.success(t("createTeam.userAddSuccess"));
        fetchTeam();
        handleCancel();
      } else if (response.data.statusCode === "10") {
        toast.info("Account Exists");
      } else if (response.data.statusCode === "12" || "3") {
        toast.info(t("createTeam.userLimit"));
        handleCancel();
      }
    } catch (error) {
      console.log(error);
      toast.error(t("createTeam.errUser"));
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancel = () => {
    setFormData({
      AccountID: user.AccountID,
      AccountType: user.AccountType,
      RoleID: "",
      MobileNumber: "",
      FullName: "",
      UserName: "",
      EmailID: "",
    });
    setShowCreateTeam(false);
  };

  const handleCloseCreateTeam = () => {
    setShowCreateTeam(false);
  };

  return (
    <div className='container'>
      {isLoading && (
        <div className='loader-overlay'>
          <Loader />
        </div>
      )}

      <div className='createTeamBtn my-2' onClick={() => setShowCreateTeam(true)}>
        <IoIosAddCircleOutline size={22} style={{ cursor: "pointer" }} />
        <label>{t("createTeam.AddNewTeam")}</label>
      </div>

      <Offcanvas
        show={showCreateTeam}
        onHide={handleCloseCreateTeam}
        scroll={true}
        id={`offcanvasNavbar-expand-${false}`}
        aria-labelledby={`offcanvasNavbarLabel-expand-${false}`}
        placement={document.documentElement.dir === "rtl" ? "start" : "end"}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${false}`} className='fw-bold'>
            {t("createTeam.AddNewTeam")}
          </Offcanvas.Title>
        </Offcanvas.Header>
        <hr style={{ margin: " auto 0", borderColor: "black" }} />
        <Offcanvas.Body>
          <Form>
            <Form.Group className='mt-2' controlId='FullName'>
              <Form.Label>{t("createTeam.fullName")}</Form.Label>
              <Form.Control type='text' placeholder={t("createTeam.enterfullName")} name='FullName' value={formData.FullName} onChange={handleInputChange} />
            </Form.Group>

            <Form.Group className='mt-2' controlId='UserName'>
              <Form.Label>{t("createTeam.email")}</Form.Label>
              <Form.Control
                type='email'
                placeholder={t("createTeam.enterEmail")}
                name='EmailID'
                value={formData.EmailID}
                onChange={handleInputChange}
                required
                pattern='[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$'
              />
            </Form.Group>

            <Form.Group className='mt-2' controlId='RoleID'>
              <Form.Label>{t("createTeam.role")}</Form.Label>
              <Form.Select as='select' name='RoleID' value={formData.RoleID} onChange={(e) => handleInputChange(e)}>
                <option value='' disabled>
                  {t("createTeam.choose")}
                </option>
                {roles.map((role) => (
                  <option key={role.id} value={role.id}>
                    {role.roleNameEn}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>

            <Form.Group className='mt-2' controlId='MobileNumber'>
              <Form.Label>{t("createTeam.mobileNumber")}</Form.Label>
              <Form.Control
                type='text'
                placeholder={t("createTeam.entermobileNumber")}
                name='MobileNumber'
                value={formData.MobileNumber}
                onChange={handleInputChange}
                pattern='[0-9]{11,15}'
                title='Mobile number should be between 11 and 15 digits'
              />
            </Form.Group>

            <div className='newComment d-flex align-items-center justify-content-between w-100 p-3 py-4'>
              <Button variant='none' className='modalCancelButton' onClick={handleCancel}>
                {t("createTeam.cancel")}
              </Button>
              <Button variant='none' onClick={CreateAppUser} className='applyBtn'>
                {t("createTeam.createTeam")}
              </Button>
            </div>
          </Form>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
}

export default CreateTeamForm;
